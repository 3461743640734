import React, { Component } from 'react';
import { withTranslate } from 'react-redux-multilingual';

// Custom Components
import HeaderOne from './common/headers/header-one';
import HeaderTwo from './common/headers/header-two';
import HeaderThree from './common/headers/header-three';
import HeaderLogin from './common/headers/header-login';
import HeaderMarket from './common/headers/header-market';
import HeaderWelcome from './common/headers/header-welcome';

import VerPageNotFound from './pages/ver-404';
/*import FooterOne from "./common/footers/footer-one";
import FooterTwo from "./common/footers/footer-two";
import FooterThree from "./common/footers/footer-three";*/

// ThemeSettings
import ThemeSettings from './common/theme-settings';
import PageLoader from './layouts/loader/main';

class App extends Component {
  rutaProducts = '/products/';
  rutaCategProducts = '/categProducts/';
  rutaAccesories = '/accesories/';
  indiceInicioProducts = 0;
  indiceInicioCategProducts = 0;
  indiceInicioAccesories = 0;
  textoFinProducts = '';
  textoFinCategProducts = '';
  textoFinAccesories = '';
  textoFinIndiceProducts = 0;
  textoFinIndiceCategProducts = 0;
  textoFinIndiceAccesories = 0;

  render() {
    this.indiceInicioProducts = this.props.location.pathname.indexOf(
      this.rutaProducts
    );
    this.textoFinProducts =
      this.indiceInicioProducts >= 0
        ? this.props.location.pathname.slice(
            this.rutaProducts.length,
            this.props.location.pathname.length
          )
        : '';
    this.textoFinIndiceProducts = this.textoFinProducts.indexOf('/');

    this.indiceInicioCategProducts = this.props.location.pathname.indexOf(
      this.rutaCategProducts
    );
    this.textoFinCategProducts =
      this.indiceInicioCategProducts >= 0
        ? this.props.location.pathname.slice(
            this.rutaCategProducts.length,
            this.props.location.pathname.length
          )
        : '';
    this.textoFinIndiceCategProducts = this.textoFinCategProducts.indexOf('/');

    this.indiceInicioAccesories = this.props.location.pathname.indexOf(
      this.rutaAccesories
    );
    this.textoFinAccesories =
      this.indiceInicioAccesories >= 0
        ? this.props.location.pathname.slice(
            this.rutaAccesories.length,
            this.props.location.pathname.length
          )
        : '';
    this.textoFinIndiceAccesories = this.textoFinAccesories.indexOf('/');

    /*console.log(this.props)
        console.log('this.props.location.pathname ',this.props.location.pathname )
        console.log('this.rutaProducts ',this.rutaProducts )
        console.log('this.indiceInicioProducts ',this.indiceInicioProducts )
        console.log('this.textoFinProducts ',this.textoFinProducts )
        console.log('this.textoFinIndiceProducts ',this.textoFinIndiceProducts )
        console.log('------------------------------------------ ' )
        console.log('this.rutaCategProducts ',this.rutaCategProducts )
        console.log('this.indiceInicioCategProducts ',this.indiceInicioCategProducts )
        console.log('this.textoFinCategProducts ',this.textoFinCategProducts )
        console.log('this.textoFinIndiceCategProducts ',this.textoFinIndiceCategProducts )*/

    return (
      <div>
        {<PageLoader />}
        {this.props.location.pathname == '/cart' ||
        this.props.location.pathname == '/referred-orders' ||
        this.props.location.pathname == '/referred-checkout' ||
        this.props.location.pathname == '/user-checkout' ||
        this.props.location.pathname == '/register-referred' ||
        this.props.location.pathname == '/register-address' ||
        this.props.location.pathname == '/profile' ||
        this.props.location.pathname == '/referreds' ||
        this.props.location.pathname == '/thanks' ||
        this.props.location.pathname == '/debt' ? (
          <HeaderWelcome
            logoName={'ver_logo.png'}
            showNav={true}
            changeBackgroundColor={false}
            homeView={true}
            changeClass={'header_cart'}
          />
        ) : /*this.props.location.pathname=='/products' ?
                    <HeaderWelcome logoName={'ver_logo2.png'} showNav={true} changeBackgroundColor={true} changeClass={'header_products'}/>
                    :*/
        this.props.location.pathname ==
          '/products/' +
            (this.textoFinIndiceProducts == -1 ? this.textoFinProducts : '') ? (
          <HeaderWelcome
            logoName={'ver_logo2.png'}
            showNav={true}
            changeBackgroundColor={true}
            changeClass={'header_products'}
          />
        ) : this.props.location.pathname ==
          '/categProducts/' +
            (this.textoFinIndiceCategProducts == -1
              ? this.textoFinCategProducts
              : '') ? (
          //this.props.location.pathname.includes('/categProducts') ?
          <HeaderWelcome
            logoName={'ver_logo.png'}
            showNav={true}
            transparentBackground={true}
            changeBackgroundColor={false}
            homeView={true}
            changeClass={'transparent_header'}
          />
        ) : this.props.location.pathname ==
          '/accesories/' +
            (this.textoFinIndiceAccesories == -1
              ? this.textoFinAccesories
              : '') ? (
          //this.props.location.pathname.includes('/accesories') ?
          <HeaderWelcome
            logoName={'ver_logo.png'}
            showNav={true}
            transparentBackground={true}
            homeView={true}
            changeClass={'transparent_header'}
          />
        ) : this.props.location.pathname !== '/login' &&
          this.props.location.pathname !== '/' &&
          this.props.location.pathname !== '/welcome' &&
          this.props.location.pathname !== '/unauthorized' ? (
          <HeaderWelcome logoName={'ver_logo.png'} showNav={true} />
        ) : (
          <HeaderLogin />
        )}
        {/*<HeaderOne logoName={'logo.png'}/>*/}
        {this.props.children}
        {this.props.location.pathname !== '/login' &&
        this.props.location.pathname !== '/' &&
        this.props.location.pathname !== '/welcome' &&
        this.props.location.pathname !== '/cart' &&
        this.props.location.pathname !== '/referred-orders' &&
        this.props.location.pathname !== '/referred-checkout' &&
        this.props.location.pathname !== '/user-checkout' &&
        this.props.location.pathname !== '/product/1' &&
        //&& this.props.location.pathname!=='/products'
        this.props.location.pathname !==
          '/products/' +
            (this.textoFinIndiceProducts == -1 ? this.textoFinProducts : '') &&
        //&& this.props.location.pathname!=='/categProducts'
        this.props.location.pathname !==
          '/categProducts/' +
            (this.textoFinIndiceCategProducts == -1
              ? this.textoFinCategProducts
              : '') &&
        //&& this.props.location.pathname!=='/accesories'
        this.props.location.pathname !==
          '/accesories/' +
            (this.textoFinIndiceAccesories == -1
              ? this.textoFinAccesories
              : '') &&
        this.props.location.pathname !== '/unauthorized' &&
        this.props.location.pathname !== '/register-referred' &&
        this.props.location.pathname !== '/register-address' &&
        this.props.location.pathname !== '/profile' &&
        this.props.location.pathname !== '/debt' &&
        this.props.location.pathname !== '/referreds' &&
        this.props.location.pathname !== '/thanks' &&
        this.props.location.pathname !== '/closed' ? (
          <VerPageNotFound />
        ) : (
          ''
        )}
        {/*<FooterOne logoName={'logo.png'}/>*/}
        {this.props.location.pathname !== '/login' &&
        this.props.location.pathname !== '/' &&
        this.props.location.pathname !== '/welcome' &&
        this.props.location.pathname !== '/cart' &&
        this.props.location.pathname !== '/referred-orders' &&
        this.props.location.pathname !== '/user-checkout' &&
        this.props.location.pathname !== '/referred-checkout' &&
        this.props.location.pathname !== '/product/1' &&
        //&& this.props.location.pathname!=='/products'
        this.props.location.pathname !==
          '/products/' +
            (this.textoFinIndiceProducts == -1 ? this.textoFinProducts : '') &&
        //&& this.props.location.pathname!=='/categProducts'
        this.props.location.pathname !==
          '/categProducts/' +
            (this.textoFinIndiceCategProducts == -1
              ? this.textoFinCategProducts
              : '') &&
        //&& this.props.location.pathname!=='/accesories'
        this.props.location.pathname !==
          '/accesories/' +
            (this.textoFinIndiceAccesories == -1
              ? this.textoFinAccesories
              : '') &&
        this.props.location.pathname !== '/register-referred' &&
        this.props.location.pathname !== '/register-address' &&
        this.props.location.pathname !== '/profile' &&
        this.props.location.pathname !== '/debt' &&
        this.props.location.pathname !== '/referreds' &&
        this.props.location.pathname !== '/thanks'
          ? /*<ThemeSettings />*/ ''
          : ''}
      </div>
    );
  }
}

export default withTranslate(App);
