import React, { Component } from 'react';
//import Breadcrumb from "../common/breadcrumb";
import HeaderWelcome from '../common/headers/header-welcome';
import { Link } from 'react-router-dom';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import {
  removeFromCart,
  incrementQty,
  decrementQty,
  getFromPurchaseRefered,
  getFromCartUser,
} from '../../actions';
import store from '../../store';
import Slider from 'react-slick';
import BannersList from '../layouts/common/list-banners';

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.handleHoverOut = this.handleHoverOut.bind(this);
    this.handleHover1 = this.handleHover1.bind(this);
    this.handleHover2 = this.handleHover2.bind(this);
    this.state = {
      isHovered1: false,
      isHovered2: false,
      isHovered: 0,
      user_name: '',
      data_categ_parents: [],
      amount_cash: 0,
      data_banners: [],
      alias: '',
    };
  }

  componentWillMount() {
    this.getDebt();
  }

  UNSAFE_componentWillMount() {
    var user = AuthService.getCurrentUser();
    if (user) {
      if (user.name) {
        this.setState({
          user_name: user.name,
          alias: user.alias,
        });
      }
      document.body.style = `background-color: #161616;font-family: 'Work Sans'`;
      this.checkOpen();
      this.getRefOrders();
    } else {
      this.props.history.push('/login');
    }
    
  }

  handleHover = (id) => {
    //console.log('ok')
    this.setState({
      isHovered: id,
    });
    //console.log(this.state.isHovered1);
  };

  handleHoverOut(e) {
    this.setState({
      isHovered: 0,
    });
  }

  handleHover1(e) {
    //console.log('ok')
    this.setState({
      isHovered1: !this.state.isHovered1,
    });
    //console.log(this.state.isHovered1);
  }

  handleHover2(e) {
    this.setState((prevState) => ({
      isHovered2: !prevState.isHovered2,
    }));
  }

  checkOpen() {
    var user = AuthService.getCurrentUser();
    ApiService.checkOpen(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('res.data',res.data);
          if (res.data.option_value == 'NO') {
            if (window.location.pathname != '/closed') {
              this.props.history.push('/closed');
            }
          } else {
            this.getCategParent();
            this.getCash();
            this.getBanners();
          }
        } else {
          this.props.history.push('/closed');
          window.location.reload();
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getRefOrders() {
    var user = AuthService.getCurrentUser();
    ApiService.getReferredOrders(user.token, user.token_order, user.id).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('data',res.data);

          store.dispatch(getFromPurchaseRefered(res.data));
          store.dispatch(getFromCartUser(res.carts_data));

          //this.setState({ order_results:res.data });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }


  getCategParent() {
    var user = AuthService.getCurrentUser();
    document.getElementsByClassName('page-loader')[0].style.display = 'flex';
    ApiService.getCategParents(user.token, 0).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('data',res.data);
          //this.setState({ data_categ_parents: this.showImage(res.data) });
          this.setState({ data_categ_parents: res.data });
          if (res.data.length > 0) {
            document.getElementsByClassName('page-loader')[0].style.display =
              'none';
          }
          //console.log(this.state.data_categ_parents);
          //this.setState({ order_results:res.data });
        } else {
          document.getElementsByClassName('page-loader')[0].style.display =
            'none';
        }
      },
      (error) => {
        document.getElementsByClassName('page-loader')[0].style.display =
          'none';
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getCash() {
    var user = AuthService.getCurrentUser();
    AuthService.getCash(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('getCash data',res.data);
          let amount_cash =
            res && res.data && res.data.amount_cash
              ? Math.round(res.data.amount_cash * 100) / 100
              : 0;
          this.setState({ amount_cash: amount_cash });
          //console.log(this.state.data_categ_parents);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getBanners() {
    var user = AuthService.getCurrentUser();
    ApiService.getBanners(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('getBanners data',res.data);
          this.setState({ data_banners: res.data });
          /*let amount_cash = res && res.data && res.data.amount_cash  ? Math.round(res.data.amount_cash*100)/100 : 0
                    this.setState({ amount_cash: amount_cash });*/
          //console.log(this.state.data_categ_parents);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  handleImageLoaded() {
    //console.log('loaded!')
    document.getElementsByClassName('page-loader')[0].style.display = 'none';
    //this.setState({ imageStatus: "loaded" });
  }

  async getDebt() {
    var user = AuthService.getCurrentUser();

    const debt = await ApiService.getSUmDebt(user.token);

    if (debt.totalDebt > 0) {
      this.setState({ styleDebt: 'block', totalDebt: debt.totalDebt });
    }

  }

  render() {
    document.body.classList.add('dark');
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };
    return (
      <div className="sup-content-welcome">
        {/*<Breadcrumb title={'Login'}/>*/}
        {/*Login section*/}
        <div className="container container-welcome">
          {/*<h3>Login</h3>*/}
          <div className="div-header-welcome">
            <HeaderWelcome
              logoName={'ver_logo.png'}
              showNav={true}
              homeView={true}
              userName={
                this.state.alias ? this.state.alias : this.state.user_name
              }
            />
          </div>
          <div className="theme-welcome">
            <div className="row-home title-theme-welcome">
              <div className="title-welcome">
                <div className="image-left-welcome">
                  <img src="/assets/images/EMOJI_TREBOL.png"></img>
                </div>
                <label style={{ textTransform: 'uppercase' }}>
                  HOLA{' '}
                  {this.state.alias ? this.state.alias : this.state.user_name}
                </label>
              </div>
            </div>
           

            {this.state.data_banners.length == 0 ? (
              ''
            ) : this.state.data_banners.length == 1 ? (
              <div className="col-lg-12 center-slider border-0 container-banners one-banner">
                <div>
                  <Slider {...settings} className="offer-slider slide-1">
                    {this.state.data_banners.map((banner, index) => (
                      <div key={index}>
                        <BannersList product={banner} key={index} />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            ) : (
              <div className="col-lg-12 center-slider border-0 container-banners">
                <div>
                  <Slider {...settings} className="offer-slider slide-1">
                    {this.state.data_banners.map((banner, index) => (
                      <div key={index}>
                        <BannersList product={banner} key={index} />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            )}

            <div
              className="row-home row-credit-welcome"
              style={{ display: 'flex', flexWrap: 'wrap' }}
            >
              <div>
                <div className="credit-welcome">
                  TU CRÉDITO ES:
                  <label style={{ marginLeft: '10px' }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`}
                      alt=""
                      className="img-fluid"
                      style={{ position: 'relative', bottom: '2px' }}
                    />{' '}
                    {this.state.amount_cash}
                  </label>
                </div>
              </div>
            </div>
            <div className="container-items-welcome row-items-welcome">
              {this.state.data_categ_parents.map((item, index) => {
                //console.log('item',item);
                var urlTemplate = '';
                if (item.template == 'TEMPLATE1') {
                  urlTemplate = 'products/' + item.id;
                } else if (item.template == 'TEMPLATE2') {
                  urlTemplate = 'categProducts/' + item.id;
                } else {
                  urlTemplate = 'accesories/' + item.id;
                }
                return (
                  <Link
                    key={index}
                    to={`${process.env.PUBLIC_URL}/${urlTemplate}`}
                    className={
                      this.state.data_categ_parents.length % 3 == 0 &&
                      (index + 1) % 3 == 0
                        ? 'card-categ third-element-parent'
                        : 'card-categ categ-parent'
                    }
                  >
                    <div
                      className={
                        this.state.isHovered == item.id
                          ? 'item-welcome-hover'
                          : 'item-welcome'
                      }
                      onMouseOver={() => this.handleHover(item.id)}
                      onMouseOut={this.handleHoverOut}
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_URL_FILES}images/categories/` +
                          item.background_url
                        }
                        onLoad={this.handleImageLoaded.bind(this)}
                      />
                      <label
                        className="item-name-welcome"
                        style={{ textTransform: 'uppercase' }}
                      >
                        {item.name}
                      </label>
                    </div>
                  </Link>
                );
              })}
            </div>
           
            <div className="row row-thanks-welcome">
              <label className="thanks-welcome">
                Gracias por seguir acompañándonos cada día.
              </label>
            </div>
          </div>
        </div>
        <div className="image-right-welcome img-rihgt-1">
          <img src="/assets/images/plants3.jpg"></img>
        </div>
        <div className="image-right-welcome img-rihgt-2">
          <img src="/assets/images/plants4.jpg"></img>
        </div>
        <div
          id="has-debt"
          className="search-overlay has-debt"
          style={{ display: this.state.styleDebt, zIndex: 100000 }}
        >
          <div>
            <span
              className="closebtn"
              onClick={() => {
                document.getElementById('has-debt').style.display = 'none';
              }}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content options-overlay-content">
              <div className="container">
                <div>
                  <label className="logout-text1">
                    {/* Tu carrito de compras está <br /> vacío */}
                    Estimado {this.props.userName} mantienes compras pendientes
                    de pago por S/ {this.state.totalDebt}.
                  </label>
                </div>
                <div className="row div-logout-button">
                  <Link
                    to={`${process.env.PUBLIC_URL}/debt`}
                    //onClick={() => this.closeEmpty()}
                    className="btn btn-solid btn-explore"
                  >
                    Pagar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Welcome;