import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import {Link, Redirect } from 'react-router-dom'
import PaypalExpressBtn from 'react-paypal-express-checkout';
import SimpleReactValidator from 'simple-react-validator';

import Breadcrumb from "../common/breadcrumb";
import {removeFromWishlist} from '../../actions'
import {getCartTotal} from "../../services";
import Select from 'react-select';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Swal from 'sweetalert2';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger ver-alert" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };

class RegisterAddress extends Component {

    constructor (props) {
        super (props)
        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeUbigeo = this.onChangeUbigeo.bind(this);
        this.onChangeUrl = this.onChangeUrl.bind(this);
        this.onChangeReference = this.onChangeReference.bind(this);
        this.state = {
            address: "",
            ubigeo: "",
            url_address: "",
            reference: "",
            errors: {},
            message: "",
        }
        this.validator = new SimpleReactValidator();
    }

    componentWillMount() {
        var user = AuthService.getCurrentUser();
        //console.log('User',user);
        /*if(AuthService.getCurrentUser().role!='EMBAJADOR'){
            this.props.history.push("/welcome")
        }*/
    }

    onChangeAddress(e) {
        this.setState({
            address: e.target.value
        });
      }

    onChangeUbigeo(e) {
        this.setState({
            ubigeo: e.target.value
        });
      }

    onChangeUrl(e) {
        this.setState({
            url_address: e.target.value
        });
      }

      onChangeReference(e) {
        this.setState({
            reference: e.target.value
        });
      }

    handleRegister(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
          });
      
          this.form.validateAll();
       
        if (this.checkBtn.context._errors.length === 0) {
        //this.form.validateAll();
            var user = AuthService.getCurrentUser();
            let data_user = {
                'address': this.state.address,
                'ubigeo': this.state.ubigeo,
                'url_google': this.state.url_address,
                //'reference': this.state.reference,
                'id_user': user.id,
            }

            ApiService.postAddressUser(user.token,data_user).then(
            (res) => {
                if(res){
                    if(res && res.status == 'success'){
                        /*Swal.fire({
                            icon: 'success',
                            title: 'Hecho!',
                            text: '"Dirección Registrada!',
                          }, function() {
                        });*/
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', Swal.stopTimer)
                              toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                          })
                          
                          Toast.fire({
                            icon: 'success',
                            title: '<span style="color:#F7F7F7">Dirección Registrada!<span>',
                          })
                        this.props.history.push("/welcome");
                        //this.props.history.push("/welcome");
                    }else{
                    }
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
            this.setState({
              loading: false
            });
          }
        
      }

    render (){
        const {cartItems, symbol, total} = this.props;
        document.body.classList.add('dark');
        return (

            <div className="cart-container" style={{backgroundColor: '#161616 !important'}}>
                <div className="breadcrumb-section title-cart">
                <div className="container">
                    <div className="row">
                            <div className="col-md-12">
                                <div className="page-title page-tittle2">
                                    {/*<h1>{title}</h1>*/}
                                    <label className="text-confirm-order">REGISTRAR DIRECCIÓN</label>
                                </div>
                            </div>
                    </div>
                </div>
                </div>

                <section className="section-b-space" style={{paddingTop: '15px'}}>
                    <div className="container padding-cls">
                        <div className="checkout-page">
                            <div className="checkout-form register-address-form">
                                    <div className="checkout row container-data-address" style={{margin: 'auto'}}>
                                        <div className="col-lg-12 col-sm-12 col-xs-12">
                                            <div>
                                            <Form
                                                onSubmit={this.handleRegister} 
                                                //onSubmit={this.props.action=='create'?this.handleRegister:this.handleUpdate}
                                                autoComplete="off"
                                                encType="multipart/form-data" 
                                                method="POST"
                                                ref={c => {this.form = c;
                                            }}>
                                                {/*<div className="referred-checkout-title">
                                                    <label>Datos del referido</label>
                                                </div>*/}
                                                <div className="row check-out row-check-date" style={{marginBottom: '1rem'}}>
                                                    <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                        <div className="field-label">DIRECCION</div>
                                                        <Input type="text" name="address" value={this.state.address} onChange={this.onChangeAddress} validations={[required]}/>
                                                        {/*{this.validator.message('name', this.state.address, 'required|alpha')}*/}
                                                    </div>
                                                </div>
                                                <div className="row check-out row-check-date">
                                                    {/*<div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">UBIGEO</div>
                                                        <input type="text" name="ubigeo" value={this.state.ubigeo} onChange={this.onChangeEmail} />
                                                        {this.validator.message('ubigeo', this.state.ubigeo, 'required|alpha')}
                                                    </div>*/}
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12" style={{marginBottom: '10px'}}>
                                                        <div className="field-label">REFERENCIA</div>
                                                        <Input type="text" name="reference" value={this.state.reference} onChange={this.onChangeReference} validations={[required]}/>
                                                        {/*{this.validator.message('reference', this.state.reference, 'required|alpha')}*/}
                                                    </div>
                                                </div>
                                                <div className="row check-out row-check-date" style={{marginBottom: '2rem'}}>
                                                    <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                        <div className="field-label">LINK DE LA DIRECCION EN GOOGLE MAPS</div>
                                                        <Input type="text" name="url_address" value={this.state.url_address} onChange={this.onChangeUrl} validations={[required]}/>
                                                        <a target='_blank' href="https://www.google.com/maps" style={{fontSize: '10px'}}><div className="field-label">LINK A GOOGLE MAPS</div></a>
                                                        {this.validator.message('url_address', this.state.url_address, 'required|alpha')}
                                                    </div>
                                                </div>
                                                <div className="row check-out row-check-date" style={{textAlign: 'center',marginTop: '1rem'}}>
                                                    <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                    <button className="btn-solid btn btn-create-referred btn-primary" disabled={this.state.loading}>
                                                    {this.state.loading && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                        GUARDAR</button>
                                                    </div>
                                                </div>
                                                {this.state.message && (
                                                    <div className="form-group">
                                                        <div className="alert alert-danger" role="alert">
                                                            {this.state.message}
                                                        </div>
                                                    </div>
                                                )}
                                                <CheckButton
                                                    style={{ display: "none" }}
                                                    ref={c => {
                                                        this.checkBtn = c;
                                                    }}
                                                />
                                            </Form>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    {removeFromWishlist}
)(RegisterAddress)