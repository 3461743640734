import axios from 'axios';

axios.interceptors.response.use(
  (response) => {
    //console.log('interceptando', response);

    return response;
  },
  (error) => {
    //console.log('error => ', error.response.status);
    if (error.response.status == 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
  }
);

const API_URL = process.env.REACT_APP_URL + 'api/';

class AuthService {
  login(cell_phonenumber, password, pin_code) {
    return axios
      .post(API_URL + 'signin', {
        cell_phonenumber,
        password,
        pin_code,
      })
      .then((response) => {
        //console.log(response);
        if (response.data.data.token) {
          //console.log(response.data.data.token)
          localStorage.removeItem('user');
          localStorage.setItem('user', JSON.stringify(response.data.data));
          //mostrar datos del carrito
          localStorage.setItem(
            'cart',
            JSON.stringify(response.data.carts_data)
          );
          //mostrar datos del referido si el usuario es embajador
          if (response.data.data && response.data.data.role == 'EMBAJADOR') {
            localStorage.setItem(
              'purchase_data_refered',
              JSON.stringify(response.data.purchase_data_refered)
            );
          }
        }
        return response.data;
      });
  }

  getCash(access_token) {
    return axios
      .post(
        API_URL + 'users/cash',
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': `application/json`,
            cache: false,
            contentType: false,
            processData: false,
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(username, email, password) {
    return axios.post(API_URL + 'signup', {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  getCurrentPurchaseReferedUser() {
    return JSON.parse(localStorage.getItem('purchase_data_refered'))
      ? JSON.parse(localStorage.getItem('purchase_data_refered'))
      : [];
  }

  getCurrentCartUser() {
    return JSON.parse(localStorage.getItem('cart'))
      ? JSON.parse(localStorage.getItem('cart'))
      : [];
  }
}

export default new AuthService();
