import React, {Component} from 'react';
import {Link, Redirect } from 'react-router-dom'

import "react-datepicker/dist/react-datepicker.css";

class verThanks extends Component {

    constructor(props){
        super(props)

        this.state = {
        }
    }

    render (){
        const { cash, cash_win} = this.props;
        return (
                
                <div id="verThanks"  className="cart-container" style={{backgroundColor: '#161616 !important'}}>
                    <div className="breadcrumb-section title-cart" style={{paddingBottom: '20px'}}>
                    <div className="container">
                        <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title page-tittle2">
                                        {/*<h1>{title}</h1>*/}
                                        <div style={{marginBottom: '2.5rem'}}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/thanks_img.png`} className="img-fluid" alt="" style={{display: 'block',margin: '0 auto'}}/>
                                        </div>
                                        <label className="text-confirm-order">!GRACIAS POR TU COMPRA!</label>
                                    </div>
                                </div>
                        </div>
                    </div>
                    </div>

                    <section className="section-b-space section-thanks-text">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div >
                                        <div className="col-sm-12 empty-cart-cls text-center thanks-container">
                                            {cash_win > 0?
                                            <div className="ver-thanks-accumulated">
                                                <div>
                                                <label>Has Acumulado</label>
                                                <label>{cash_win} <img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit_2.png`} alt="" className="img-fluid" style={{position: 'relative', bottom: '2px', marginLeft: '5px'}}/></label>
                                                </div>
                                            </div>
                                            :''
                                            }

                                            <div>
                                                Tu crédito actual es :
                                            </div>

                                            <div className="ver-thanks-credit">
                                                <label><img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{position: 'relative', bottom: '2px', marginRight: '5px'}}/> {cash}</label> 
                                            </div>

                                            <div>
                                                Nos comunicaremos contigo cuando el pedido esté en camino y se encuentre en tu domicilio
                                            </div>

                                            <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                <Link to={`${process.env.PUBLIC_URL}/welcome`} className="ver-btn-solid btn btn-finish-order btn-primary">
                                                    FINALIZAR</Link>
                                            </div>
                                            {/*<h4>Explore more shortlist some items.</h4>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>                   
        )
    }
}
export default verThanks