import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import DatePicker, {registerLocale} from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es",es);

const Calendar  = ({handleDateChange}) => {
    const [startDate, setStartDate] = useState(new Date());
    const min_date = new Date();
      const filterDays = (date) => {
        // Disable Weekends
        if (date.getDay() === 0 || date.getDay() === 7) {
            return false;
        } else {
            return true;
        }
    }
    return (
      <DatePicker selected={startDate} locale="es" dateFormat="dd/MM/yyyy" minDate={min_date} onChange={date => setStartDate(date)} onSelect={date => handleDateChange(date)} filterDate={filterDays}/>
    );
  };

export default Calendar;
