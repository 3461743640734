import React, { Component } from 'react';
import {Helmet} from 'react-helmet'
import '../../common/index.scss';
import Slider from 'react-slick';
import {connect} from "react-redux";
import {Link,Route} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import ThemeSettings from "../../common/theme-settings"

import {addToCart} from '../../../actions'
// Import custom components
import {
    svgFreeShipping,
    svgservice,
    svgoffer,
    svgpayment
} from "../../../services/script"
import TopCollection from "../common/collection"
import NewProduct from "../../common/new-product"
//import Instagram from "../common/instagram"
import HeaderOne from "../../common/headers/header-one"
//import FooterOne from "../../common/footers/footer-one"
import Categorias from "../common/categorias"
import CategoriasProduct from "../common/categorias_product"
import BlogSection from "../common/blogsection";
import {getBestSeller} from "../../../services";
import ProductItem from '../common/product-item';
import AccesoriesList from '../common/list-accesories';
import navbar from '../../common/headers/common/navbar';
import AuthService from "../../services/auth.service";
import ApiService from "../../services/api.service";
import { getFromPurchaseRefered,getFromCartUser} from '../../../actions'
import store from '../../../store';
import Carousel, { consts } from 'react-elastic-carousel'
import Swal from 'sweetalert2';
 // ....
//type === consts.PREV
//console.log('categ poructs AuthService.getCurrentUser()', AuthService.getCurrentUser());
//var ruta = AuthService.getCurrentUser() && AuthService.getCurrentUser().role ? (AuthService.getCurrentUser().role == 'REFERIDO' ? 'cart' : 'user-checkout') : 'cart';
let data_categ = [];
let id_categ_selected = 0;
class Accesories extends Component {
    constructor(props){
        super(props);
        this.handlerAddCart = this.handlerAddCart.bind(this);
        this.state = {
            open: false,
            results_categ: [],
            results_product: [],
            dataCategoria: {}
        }
    }

    componentWillMount() {
        //console.log(this.props.match.params.id);
        var user = AuthService.getCurrentUser();
        if(user)
        {
            id_categ_selected = 0;
            document.body.classList.add('dark');
            document.body.style = `background-color: #161616;font-family: 'Work Sans'`;
            //document.body.style = `background-image: url('/assets/images/fondoclear.png'); background-repeat: inherit;font-family: 'Work Sans'`;
            this.checkOpen();
        }else{
            this.props.history.push("/login");
        }
    }

    checkOpen(){
        var user = AuthService.getCurrentUser();
        ApiService.checkOpen(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('res.data',res.data);
					if(res.data.option_value == 'NO')
					{
						if(window.location.pathname != '/closed')
						{
							this.props.history.push("/closed")
						}
					}else{
                        this.getDataCateg();
                        //this.getDataProduct(this.props.match.params.id);
                        this.getRefOrders();
                    }
                }else{
					this.props.history.push("/closed")
            		window.location.reload();
				}
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    handlerAddCart(item,quantity, redirect) {
        //console.log(item)
        //console.log(quantity)


        if(item && quantity > 0){
            var user = AuthService.getCurrentUser();
            //console.log(user)
            //console.log(item)
            //console.log(quantity)
            let formdata = new FormData()
            let data_cart = {
                'token': user.token_order,
                'id_user': user.id,
                'id_product': item.id,
                'quantity': quantity,
                'price': item.price,
            }
            ApiService.postItemToCart(user.token,data_cart).then(
                (res) => {
                    if(res && res.status == 'success'){
                        
                        store.dispatch(addToCart(res.data, quantity))

                        //this.openAddCartCorrectly();

                        document.getElementById("addCart-overlay").style.display = "block";
                        setTimeout(this.openAddCartCorrectly(redirect, this.props), 1000);

                        //this.props.history.push("/"+(AuthService.getCurrentUser() && AuthService.getCurrentUser().role ? (AuthService.getCurrentUser().role == 'REFERIDO' ? 'cart' : 'user-checkout') : 'cart'));
                        //window.location.reload();
                        //this.setState({ results_categ:res.data });
                        //console.log(this.state.results_categ)
                    }else{
                        if(res.mensajeError)
                        {
                            Swal.fire({
                                showConfirmButton: false,
                                type: 'warning',
                                title: 'Aviso!',
                                html: res.mensajeError,
                            });
                        }else if(res.message){
                            Swal.fire({
                                showConfirmButton: false,
                                type: 'warning',
                                title: 'Aviso!',
                                html: res.message,
                            });
                        }
                    }
                    /*const items = this.showStatus(res.data).map(function(row) {
                        return { Imagen: row.background_url, id: row.id, Nombre : row.name, Color : row.color, Estado : row.estado, status : row.status }
                     })*/
                    /*const items = res.data.map(function(row) {
                        return { id: row.id, Nombre : row.name, Color : row.color, Estado : row.status }
                     })*/
                    //this.renderCategResults();
                },
                error => {
                  this.setState({ results_categ:[] });
                  const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
              );
        }
    }

    getDataCateg(){
        var user = AuthService.getCurrentUser();
        let categoria_id = this.props.match.params.id ? this.props.match.params.id : 0;
        //ApiService.getCategories(user.token).then(
        ApiService.getCategParents(user.token, categoria_id).then(
            (res) => {
                if(res && res.status == 'success'){
                    data_categ = res.data;
                    for (let i = 0; i < data_categ.length; i++) {
                        if(i==0)
                        {
                            data_categ[i]['selected'] = 1;
                        }else{
                            data_categ[i]['selected'] = 0;
                        }
                    }
                    /*let items = []
                    res.data.map((row,index) =>{
                        if(row.status == 'ACTIVATED' && row.template == 'TEMPLATE1'){
                            items.push(row);
                        }
                     })
                    this.setState({ results_categ:items });*/
                    this.setState({ results_categ:data_categ });
                    if(data_categ.length > 0)
                    {
                        this.getDataProduct(data_categ[0].id);
                    }
                    this.getProductByClick(null);
                    
                    //console.log(this.state.results_categ)
                }
                /*const items = this.showStatus(res.data).map(function(row) {
                    return { Imagen: row.background_url, id: row.id, Nombre : row.name, Color : row.color, Estado : row.estado, status : row.status }
                 })*/
                /*const items = res.data.map(function(row) {
                    return { id: row.id, Nombre : row.name, Color : row.color, Estado : row.status }
                 })*/
                //this.renderCategResults();
            },
            error => {
              this.setState({ results_categ:[] });
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    showImage(results){
        // Aplicamos un fix si no devuelve una imagen 
        let url_image = `${process.env.REACT_APP_URL_FILES}images/products/`;
        for (let i = 0; i < results.length; i++){
                results[i]['background_url'] = <img src={url_image + results[i]['url']} className="img-fluid"/>;
        }
        return results;
    }

    getProductByClick(categoria){
        //console.log(categoria, this.state.results_categ);
        if(categoria){
            //console.log('if1');
            for (let i = 0; i < data_categ.length; i++) {
                data_categ[i]['selected'] = 0;
            }
            let index = data_categ.findIndex(x => x.id === categoria.id);
            data_categ[index].selected = 1;
            this.state.dataCategoria = categoria;
        }else{
            //console.log('if2');
            this.state.dataCategoria = this.state.results_categ[0];
        }
        
        //console.log('this.state.dataCategoria', this.state.dataCategoria);
        if(this.state.dataCategoria)
        {
            this.getDataProduct(this.state.dataCategoria.id);
        }
    }

    getDataProduct(id_category){
        var user = AuthService.getCurrentUser();
        if(id_category != id_categ_selected)
        {
            document.getElementsByClassName("page-loader")[0].style.display = "flex";
        }
        ApiService.getProducts(user.token,id_category).then(
            (res) => {
                if(res && res.status == 'success'){
                    //const items = this.showImage(res.data);
                    /*let itemsProducts = []
                    this.showImage(res.data).map((row,index) =>{
                        if(row.status == 'ACTIVATED' && row.template == 'TEMPLATE1'){
                            itemsProducts.push(row);
                        }
                     })
                    this.setState({ results_product:itemsProducts });*/

                    //this.setState({ results_product: this.showImage(res.data) });
                    id_categ_selected = id_category;
                    this.setState({ results_product: res.data });
                    this.renderResults();
                    if(res.data.length == 0)
                    {
                        document.getElementsByClassName("page-loader")[0].style.display = "none";
                    }
                    //document.getElementsByClassName("page-loader")[0].style.display = "none";
                    //this.setState({ results_product:items });
                }else{
                    document.getElementsByClassName("page-loader")[0].style.display = "none";
                }
                /*const items = this.showStatus(res.data).map(function(row) {
                    return { Imagen: row.background_url, id: row.id, Nombre : row.name, Color : row.color, Estado : row.estado, status : row.status }
                 })*/
                /*const items = res.data.map(function(row) {
                    return { id: row.id, Nombre : row.name, Color : row.color, Estado : row.status }
                 })*/
                //this.renderResults();
            },
            error => {
              document.getElementsByClassName("page-loader")[0].style.display = "none";
              this.setState({ results_product:[] });
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color3.css` );
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onClickHandle(img) {
        this.setState({ image : img} );
    }

    minusQty = () => {
        if(this.state.quantity > 1) {
            this.setState({stock: 'InStock'})
            this.setState({quantity: this.state.quantity - 1})
        }
    }

    plusQty = () => {
        if(this.props.product.stock >= this.state.quantity) {
            this.setState({quantity: this.state.quantity+1})
        }else{
            this.setState({stock: 'Out of Stock !'})
        }
    }
    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }

    /*renderCategResults(){
        //console.log(this.state.results_categ);
		return this.state.results_categ === 0 
			? ''
			: <CategoriasProduct
                    myData={this.state.results_categ}
                />
    }*/
    
    getRefOrders(){
        var user = AuthService.getCurrentUser();
        ApiService.getReferredOrders(user.token,user.token_order,user.id).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('data',res.data);

                    store.dispatch(getFromPurchaseRefered(res.data))
                    store.dispatch(getFromCartUser(res.carts_data))

                    //this.setState({ order_results:res.data });
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    openAddCartCorrectly(redirect, propsRuta) {

        //console.log('openAddCartCorrectly', this.props)
        //console.log('abrir openAddCartCorrectly');
        //document.getElementById("addCart-overlay").style.display = "block";
        setTimeout(function() { 
            try {
                document.getElementById("addCart-overlay").style.display = "none"; 
            } catch (error) {
                
            }
            if(redirect){
                //console.log('redirect if', redirect);
                propsRuta.history.push( (AuthService.getCurrentUser() && AuthService.getCurrentUser().role ? (AuthService.getCurrentUser().role == 'REFERIDO' ? '/cart' : '/user-checkout') : '/cart') )
            }
        }, 1500);
    }
	
	closeAddCartCorrectly() {
		document.getElementById("addCart-overlay").style.display = "none";
		
	}

    scrollWin() {
        window.scrollTo(500, 0);
    }

    renderResults(){	
        const {items, symbol, addToCart, addToWishlist, addToCompare} = this.props;
		return (<>
                <section className="p-0 section_list_categ_products">
                    <Slider className="slide-1 home-slider">
                        {/*{console.log('aqui',this.state.results_product)}*/}
                    { this.state.results_product.map((product, index ) =>
                            <div key={index} /*className="col-xl-2 col-md-4 col-sm-6"*/>
                                <AccesoriesList product={product} symbol={symbol}
                                             categoria  = {this.state.dataCategoria} 
                                             handlerAddCart = {this.handlerAddCart}
                                             onAddToCompareClicked={() => addToCompare(product)}
                                             onAddToWishlistClicked={() => addToWishlist(product)}
                                             onAddToCartClicked={() => addToCart(product, 1)} key={index} 
                                             indice={index+1} cant_categ={this.state.results_product.length}/>
                            {/*<div className="categ-product-index">{index+1} / {this.state.results_product.length}</div>*/}
                            </div>
                            )
                        }
                        {/*<div>
                            <div className="home home-list-products">
                                <div className="container">
                                    <div>
                                        <div className="row_product">
                                            <div className="list-product">
                                                <img src="/assets/images/product_1.png" className="img-fluid"></img>
                                            </div>
                                            <div className="slider-contain side-watch">
                                                <div className="items-product">
                                                    <h1>GORILA GLUE</h1>
                                                    <div className="buttons-product">
                                                        <a href="#" className="btn btn-solid watch-product">Ver +</a>
                                                        <a href="#" className="btn btn-favourite"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/heart.png`} className="img-fluid" alt="" /></a>
                                                        <a href="#" className="btn btn-favourite btn-likes"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/likes.png`} className="img-fluid" alt="" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="product-index">01/02</div>
                        </div>*/}
                    </Slider>
                </section>
        </>);
	}

    myArrow({ type, onClick, isEdge }) {
        //const pointer = type === consts.PREV ? '👈' : '👉'
        /*return (
          <Button onClick={onClick} disabled={isEdge}>
            {pointer}
          </Button>
        )*/
        
        return (
            <div className="p-0 btn-categ-prod">
                {/*console.log(isEdge)*/}
                <Link to={`#`} className={isEdge == false ? "btn btn-scroll" : "btn btn-scroll visible"} onClick={onClick} disabled={isEdge}>...</Link>      
            </div>
        )
        
      }

    render(){
        //document.body.style.backgroundColor = "#F8ECCB";
        const {items, symbol, addToCart, addToWishlist, addToCompare} = this.props;
        return (
            <div>
                <Helmet>
                    <title>Products</title>
                </Helmet>
                {/*{console.log('DATA',this.state.results_categ)}*/}
                <div className="section-categories">
                <div className="container-section-categ">
                    <div className="row row-section-categ">
                        <div className="styling-example">  
                        <Carousel itemsToShow={3} renderArrow={this.myArrow}>
                            {this.state.results_categ.map((item,index) => (
                                <div className="p-0 btn-categ-prod" key={index}>
                                <Link to={`#`}  className={`btn btn-full ${item.selected==1?'' : 'btn-opacity'}`} style={{border: `2px solid ${item.title_color?item.title_color:'#F8EEE1'}`, color: `${item.title_color?item.title_color:'#F8EEE1'}`, fontWeight: '900'}} onClick={() => this.getProductByClick(item)}>{item.name}</Link>
                                
                                {/*<button type="button" className="btn btn-full" onClick={() => this.getProductByClick(item.id,1)} style={{border: `2px solid ${item.color}`, color: `${item.color}`}}>{item.name}</button>*/}

                                </div>
                            ))}
                        </Carousel>
                            {/*<div className="p-0 btn-categ-prod">
                                <Link to={`#`} className="btn btn-scroll" onClick={() => this.scrollWin()}>...</Link>      
                            </div>*/}
                        </div>
                    </div>
                    </div>
                </div>
                {this.renderResults()}


                {/*About Section*/}
                {/*<section className="beauty-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 col-md-12 offset-xl-1 text-center">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/beauty/about-us.jpg`} alt="" className="img-fluid blur-up lazyload" />
                            </div>
                            <div className="col-xl-5 col-lg-6 col-md-12">
                                <div className="about-section">
                                    <div>
                                        <h2>about us</h2>
                                        <div className="about-text">
                                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                                accusantium doloremque laudantium, totam rem aperiam.sit voluptatem
                                                accusantium doloremque laudantium,totam rem aperiam.</p>
                                        </div>
                                        <div className="service small-section pb-0">
                                            <div className="row">
                                                <div className="col-sm-4 service-block1">
                                                    <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                                                    <h5>free shipping</h5>
                                                </div>
                                                <div className="col-sm-4 service-block1">
                                                    <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                                                    <h5>24 X 7 service</h5>
                                                </div>
                                                <div className="col-sm-4 service-block1">
                                                    <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                                                    <h5>festival offer</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}
                {/*About Section End*/}

                {/*Product slider*/}
                {/*<TopCollection type={'beauty'} />*/}
                {/*Product slider End*/}

                {/*Video Section*/}
                {/*<section className="video-section pt-0">
                    <div className="title1">
                        <h4>special offer</h4>
                        <h2 className="title-inner1">product tutorial</h2>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <a href="javascript:void(0)" onClick={this.onOpenModal}>
                                    <div className="video-img">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/beauty/video_1.jpg`} alt="" className="img-fluid blur-up lazyload" />
                                        <div className="play-btn">
                                            <span><i className="fa fa-play" aria-hidden="true"></i></span>
                                        </div>
                                    </div>
                                </a>
                                <Modal
                                    open={this.state.open}
                                    onClose={this.onCloseModal}
                                    id="video"
                                    className="modal fade video-modal" center>
                                    <iframe src="https://www.youtube.com/embed/FRIDLxM8Roc"
                                            allowFullScreen></iframe>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </section>*/}
                {/*Video Section End*/}

                {/*Product slider*/}
                {/*<TopCollection type={'beauty'} />*/}
                {/*Product slider End*/}

                {/*Blog Section*/}
                {/*<div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="title1">
                                <h4>Recent Story</h4>
                                <h2 className="title-inner1">from the blog</h2>
                            </div>
                        </div>
                    </div>
                </div>*/}
                {/*<section className="blog p-t-0 ratio3_2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <BlogSection />
                            </div>
                        </div>
                    </div>
                </section>*/}
                {/*Blog Section end*/}


                {/*Instagram Section*/}
                {/*<div className="section-b-space">
                    <Instagram type="watch" />
                </div>*/}
                {/*Instagram Section End*/}

                {/*<FooterOne logoName={'layout3/logo.png'}/>*/}

                {/*<ThemeSettings />*/}
                <div id="addCart-overlay" className="ver-modal-overlay addCart-overlay">
                        <div>
                            <span className="closebtn" onClick={this.closeAddCartCorrectly} title="Close Overlay">×</span>
                            <div className="overlay-content options-overlay-content">
                                <div className="container">
                                    {/*<div style={{marginBottom: '2.5rem'}}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/logout_info.png`} className="img-fluid" alt="" style={{display: 'block',margin: '0 auto'}}/>
                                    </div>*/}
                                    <div style={{marginBottom: '2.5rem'}}>
									<img src={`${process.env.PUBLIC_URL}/assets/images/icon/add_message.png`} className="img-fluid" alt="" style={{display: 'block',margin: '0 auto'}}/>
								    </div>
                                    <div>
                                        <label className="logout-text1">SE AGREGÓ EL PRODUCTO AL CARRITO</label>
                                    </div>
                                    {/*<div className="row div-logout-button">
                                        <Link to={`${process.env.PUBLIC_URL}/login`} onClick={() => this.handlerLogout()} className="btn btn-solid btn-logout"
                                            >SALIR</Link>
                                                </div>*/}
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        items: getBestSeller(state.data.products),
        symbol: state.data.symbol
    }
}


//export default connect(mapStateToProps) (Accesories);
export default connect(mapStateToProps,{addToCart}) (Accesories);