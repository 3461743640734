import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Slider from 'react-slick';
import ig from 'fetch-instagram';

import {Slider5, Slider7} from "../../../services/script"

class Categorias extends Component {

    constructor (props) {
        super (props)

        this.state = {
            InstaData: []
        }
    }

    componentWillMount() {
        const instagram = ig({
            accessToken: '8295761913.aa0cb6f.2914e9f04dd343b8a57d9dc9baca91cc',
        });

        const users = instagram.media();
        users.then(res => this.setState({InstaData:res.data}));

    }

    render (){

        const {InstaData} = this.state;
        const {type} = this.props;

        var Sliders = {};
        if(type === 'watch'){
            Sliders = Slider5;
        }else{
            Sliders = Slider7;
        }


        return (
            <div className="section-categories">
                <div className={`container${(type === 'watch')?'':'-fluid'} buttons-categ`}>
                    <div className="row row_categ">
                        <div className="col-md-1 p-0 categories_products bookmark_top btn-opacity">
                            <div className="categ-image">
                                TOP
                            </div>
                        </div>
                        <div className="col-md-1 p-0 categories_products btn-selected">
                            <div className="categ-image">
                                <img src="/assets/images/plant.png" className="img-fluid"></img>
                                PREMIUM
                            </div> 
                        </div>
                        <div className="col-md-1 p-0 categories_products btn-opacity">
                            <div className="categ-image">
                                LITE
                            </div>
                        </div>
                        <div className="col-md-1 p-0 categories_products btn-opacity">
                            <div className="categ-image">
                                BASIC
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Categorias;