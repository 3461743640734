import React, { Component } from 'react';
//import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
//import PaypalExpressBtn from 'react-paypal-express-checkout';
import SimpleReactValidator from 'simple-react-validator';

//import Breadcrumb from '../common/breadcrumb';
import { removeFromWishlist } from '../../actions';
import { getCartTotal } from '../../services';
import Select from 'react-select';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import Swal from 'sweetalert2';
//import Modal from 'react-responsive-modal';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import Collapsible from 'react-collapsible';
import Moment from 'moment';
import { calculateTotal } from '../../libs/sum';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger ver-alert" role="alert">
        Este campo es obligatorio!
      </div>
    );
  }
};

class VerProfile extends Component {
  constructor(props) {
    super(props);
    this.handleAddAddress = this.handleAddAddress.bind(this);
    this.handleUpdateAddress = this.handleUpdateAddress.bind(this);
    this.handleUpdateUser = this.handleUpdateUser.bind(this);
    this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
    //this.handleRegister = this.handleRegister.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    //this.onChangeUbigeo = this.onChangeUbigeo.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);
    this.onChangeInterior = this.onChangeInterior.bind(this);
    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onChangeReference = this.onChangeReference.bind(this);
    this.onchangeName = this.onchangeName.bind(this);
    this.onchangeLastName = this.onchangeLastName.bind(this);
    this.onchangeEmail = this.onchangeEmail.bind(this);
    this.onChangeCellphone = this.onChangeCellphone.bind(this);
    this.onChangeNroDoc = this.onChangeNroDoc.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.state = {
      action: '',
      name: '',
      last_name: '',
      id_ambassador: 0,
      cellphone: '',
      email: '',
      nro_doc: '',
      data_ref: [],
      data_orders: [],
      data_orders_referidos: [],
      data_address: [],
      data_districts: [],
      open: false,
      open_history: false,
      message: '',
      address: '',
      url_address: '',
      ubigeo: '',
      number: '',
      interior: '',
      district: '',
      id_address: '',
      type_document: '',
      password: '',
      confirm_password: '',
      selectedDistrictOption: null,
      selectedTypeAddressOption: null,
      selectedTypeDocOption: null,
      errors: [
        {
          district: '',
        },
      ],
      dataTipoDireccion: [
        { value: 'AV', label: 'AV' },
        { value: 'CALLE', label: 'CALLE' },
        { value: 'JIRON', label: 'JIRÓN' },
      ],
      dataTipo: [
        //{value: 'ADMIN',label: 'Administrador'},
        { value: 'DNI', label: 'DNI' },
        { value: 'PASAPORTE', label: 'Pasaporte' },
      ],
      dataEdit: 0,
    };
    this.validator = new SimpleReactValidator();
  }

  componentWillMount() {
    var user = AuthService.getCurrentUser();
    if (user) {
      document.body.style = `background-color: #161616;font-family: 'Work Sans'`;
      this.checkOpen();
    } else {
      this.props.history.push('/login');
      window.location.reload();
    }
  }

  checkOpen() {
    var user = AuthService.getCurrentUser();
    ApiService.checkOpen(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('res.data',res.data);
          if (res.data.option_value == 'NO') {
            if (window.location.pathname != '/closed') {
              this.props.history.push('/closed');
            }
          } else {
            this.getUserData();
            this.getUserAddresses();
            this.getUserReferreds();
            this.getUserHistoryOrders();
            this.getUserHistoryOrdersReferidos();
            this.getDistricts();
          }
        } else {
          this.props.history.push('/closed');
          window.location.reload();
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getUserData() {
    var user = AuthService.getCurrentUser();
    ApiService.getUserDetails(user.token, user.id).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('data',res.data);
          this.setState({ name: res.data.name ? res.data.name : '' });
          //this.setState({last_name: res.data.last_name?res.data.last_name:''});
          this.setState({
            id_ambassador: res.data.id_ambassador ? res.data.id_ambassador : 0,
          });
          this.setState({ email: res.data.email ? res.data.email : '' });
          this.setState({
            cellphone: res.data.Profile.cell_phonenumber
              ? res.data.Profile.cell_phonenumber
              : '',
          });
          //this.setState({nro_doc: res.data.nro_document?res.data.nro_document:''});
          this.setState({
            type_document: res.data.type_document ? res.data.type_document : '',
          });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getDistricts() {
    var user = AuthService.getCurrentUser();
    ApiService.getDistricts(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('address',res.data);
          this.items = res.data.map(function(row) {
            return { value: row.id, label: row.name, key: row.id };
          });
          this.setState({ data_districts: this.items });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  handleDistrict = (selectedDistrictOption) => {
    this.setState({ selectedDistrictOption }, () => console.log(``));
  };

  handleTypeAddress = (selectedTypeAddressOption) => {
    this.setState({ selectedTypeAddressOption }, () => console.log(``));
  };

  handleTypeDoc = (selectedTypeDocOption) => {
    //console.log('handleTypeDoc',selectedTypeDocOption)
    this.setState({ selectedTypeDocOption }, () => console.log(``));
  };

  handleUpdateUser(e) {
    e.preventDefault();

    //console.log('handleUpdateUser 1', this.checkBtn.context._errors.length , this.state.errors.referred, this.state.dataEdit);
    this.setState({
      message: '',
      loading: true,
    });

    this.form.validateAll();

    if (!this.state.selectedTypeDocOption && this.state.dataEdit == 5) {
      //console.log('if selectedTypeDocOption', this.checkBtn.context._errors.length , this.state.errors.referred);
      this.setState({
        errors: { referred: 'Error' },
      });
      return true;
    } else {
      this.setState({
        errors: { referred: '' },
      });
    }

    //console.log('handleUpdateUser 2', this.checkBtn.context._errors.length , this.state.errors.referred);
    if (
      this.checkBtn.context._errors.length === 0 &&
      !this.state.errors.referred
    ) {
      //this.setState({ open: false });
      var user = AuthService.getCurrentUser();
      //console.log('handleUpdateUser user', user );
      let data_user = {
        name: this.state.name,
        //'last_name': this.state.last_name?this.state.last_name:'',
        email: this.state.email,
        cell_phonenumber: this.state.cellphone,
        id_ambassador: this.state.id_ambassador,
        //'nro_document': this.state.nro_doc?this.state.nro_doc:'',
        type_document: this.state.selectedTypeDocOption
          ? this.state.selectedTypeDocOption.value
          : '',
        id_user: user.id, //this.state.id_referred,
      };

      //console.log('data_user',data_user);
      //return false;

      ApiService.updateUserData(user.token, data_user).then(
        (res) => {
          if (res) {
            if (res && res.status == 'success') {
              document.getElementById('update-user-overlay').style.display =
                'none';
              Swal.fire({
                icon: 'success',
                title: 'Hecho!',
                text:
                  'Los cambios se han solicitado al administrador. Se verán reflejados en máximo 24 horas.',
              });
              this.getUserData();
            } else {
              //console.log('status error',data_user);
            }
          }
        },
        (error) => {
          //console.log('error',data_user);
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      //console.log('handleUpdateUser error');
      this.setState({
        loading: false,
      });
    }
  }

  handleUpdatePassword(e) {
    e.preventDefault();

    this.setState({
      message: '',
      loading: true,
    });

    this.form.validateAll();

    if (
      !this.state.password ||
      this.state.password == '' ||
      !this.state.confirm_password ||
      this.state.confirm_password == ''
    ) {
      return true;
    }

    if (this.state.password != this.state.confirm_password) {
      return true;
    }

    if (this.checkBtn.context._errors.length === 0) {
      //this.setState({ open: false });
      var user = AuthService.getCurrentUser();
      //console.log('handleUpdateUser user', user );
      let data_user = {
        password: this.state.password,
        //'id_user': user.id,//this.state.id_referred,
      };

      //console.log('data_user',data_user);
      //return false;

      ApiService.updateUserPassword(user.token, data_user).then(
        (res) => {
          if (res) {
            if (res && res.status == 'success') {
              document.getElementById('update-password-overlay').style.display =
                'none';
              Swal.fire({
                icon: 'success',
                title: 'Hecho!',
                text: 'Contraseña actualizada.',
              });
              this.getUserData();
            } else {
              //console.log('status error',data_user);
            }
          }
        },
        (error) => {
          //console.log('error',data_user);
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      //console.log('handleUpdateUser error');
      this.setState({
        loading: false,
      });
    }
  }

  getUserAddresses() {
    var user = AuthService.getCurrentUser();
    ApiService.getUserAddresses(user.token, user.id).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('address',res.data);
          this.setState({ data_address: res.data });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getUserReferreds() {
    var user = AuthService.getCurrentUser();
    ApiService.getUserReferreds(user.token, user.id).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('ref',res.data);
          this.setState({ data_ref: res.data });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getUserHistoryOrders() {
    var user = AuthService.getCurrentUser();
    ApiService.getUserHistoryOrders(user.token, user.id).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('ref',res.data);
          for (let i = 0; i < res.data.length; i++) {
            res.data[i]['date'] = Moment(res.data[i]['date']).format(
              'DD-MM-YYYY'
            );
          }
          this.setState({ data_orders: res.data });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getUserHistoryOrdersReferidos() {
    var user = AuthService.getCurrentUser();
    ApiService.getUserHistoryOrdersReferidos(user.token, user.id).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('ref',res.data);
          for (let i = 0; i < res.data.length; i++) {
            res.data[i]['date'] = Moment(res.data[i]['date']).format(
              'DD-MM-YYYY'
            );
          }
          this.setState({ data_orders_referidos: res.data });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  handleUpdateAddress(e) {
    e.preventDefault();

    this.setState({
      message: '',
      loading: true,
    });

    this.form.validateAll();

    if (!this.state.selectedDistrictOption) {
      this.setState({
        errors: { district: 'Error' },
      });
      return true;
    } else {
      this.setState({
        errors: { district: '' },
      });
    }

    if (
      this.checkBtn.context._errors.length === 0 &&
      !this.state.errors.district
    ) {
      //this.setState({ open: false });
      //this.form.validateAll();
      var user = AuthService.getCurrentUser();
      let data_user = {
        id_address: this.state.id_address,
        type_address: this.state.selectedTypeAddressOption.value,
        address: this.state.address,
        number: this.state.number,
        interior: this.state.interior,
        url_google: this.state.url_address,
        ubigeo: this.state.selectedDistrictOption.value,
        district: this.state.selectedDistrictOption.label,
        id_user: user.id,
      };

      ApiService.updateAddressData(user.token, data_user).then(
        (res) => {
          if (res) {
            if (res && res.status == 'success') {
              document.getElementById('add-address-overlay').style.display =
                'none';
              Swal.fire({
                icon: 'success',
                title: 'Hecho!',
                text:
                  'Los cambios se han solicitado al administrador. Se verán reflejados en máximo 24 horas.',
              });
              this.getUserAddresses();
            } else {
            }
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  handleAddAddress(e) {
    e.preventDefault();

    this.setState({
      message: '',
      loading: true,
    });

    this.form.validateAll();

    if (!this.state.selectedDistrictOption) {
      this.setState({
        errors: { district: 'Error' },
      });
      return true;
    } else {
      this.setState({
        errors: { district: '' },
      });
    }

    if (
      this.checkBtn.context._errors.length === 0 &&
      !this.state.errors.district
    ) {
      //this.setState({ open: false });
      //this.form.validateAll();
      var user = AuthService.getCurrentUser();
      /*let data_user = {
                'id_address': this.state.id_address,
                'address': this.state.address,
                'url_google': this.state.url_address,
                'id_user': user.id,
            }/*/

      let data_user = {
        type_address: this.state.selectedTypeAddressOption.value,
        address: this.state.address,
        number: this.state.number,
        interior: this.state.interior,
        url_google: this.state.url_address,
        ubigeo: this.state.selectedDistrictOption.value,
        district: this.state.selectedDistrictOption.label,
        id_user: user.id,
      };

      //console.log(data_user);

      ApiService.addAddressData(user.token, data_user).then(
        (res) => {
          if (res) {
            if (res && res.status == 'success') {
              document.getElementById('add-address-overlay').style.display =
                'none';
              Swal.fire({
                icon: 'success',
                title: 'Hecho!',
                text:
                  'Los cambios se han solicitado al administrador. Se verán reflejados en máximo 24 horas.',
              });
              this.getUserAddresses();
              /*const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', Swal.stopTimer)
                              toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                          })
                          
                          Toast.fire({
                            icon: 'success',
                            title: '<span style="color:#161616">Dirección Registrada!<span>',
                          })
                        this.props.history.push("/welcome");
                        //this.props.history.push("/welcome");
                        this.setState({
                            loading: false
                          });
                        this.getUserAddresses();*/
            } else {
            }
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  onChangeAddress(e) {
    this.setState({
      address: e.target.value,
    });
  }

  /*onChangeUbigeo(e) {
        this.setState({
            ubigeo: e.target.value
        });
      }*/

  onChangeNumber(e) {
    this.setState({
      number: e.target.value,
    });
  }

  onChangeInterior(e) {
    this.setState({
      interior: e.target.value,
    });
  }

  onChangeUrl(e) {
    this.setState({
      url_address: e.target.value,
    });
  }

  onChangeReference(e) {
    this.setState({
      reference: e.target.value,
    });
  }
  onchangeName(e) {
    this.setState({
      name: e.target.value,
    });
  }
  onchangeLastName(e) {
    this.setState({
      last_name: e.target.value,
    });
  }
  onchangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }
  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }
  onChangeConfirmPassword(e) {
    this.setState({
      confirm_password: e.target.value,
    });
  }

  onChangeCellphone(e) {
    //console.log('cambio numero', e.target.value);
    let val = e.target.value;
    let max = 9;
    let vale_lenght = val.toString().length;

    //console.log('val.toString().length', val.toString().length);
    //console.log('vale_lenght', vale_lenght, max);
    //console.log('this.state.cellphone', this.state.cellphone);
    if (vale_lenght <= max) {
      this.setState({
        cellphone: e.target.value,
      });
    } else {
      this.setState({
        cellphone: this.state.cellphone,
      });
    }

    /*this.setState({
            cellphone: e.target.value
        });*/
  }

  onChangeNroDoc(e) {
    this.setState({
      nro_doc: e.target.value,
    });
  }

 
  removeAddress = (item) => {
    var user = AuthService.getCurrentUser();
    
    Swal.fire({
      title: 'Confirmar',
      text: '¿Desea elminar la dirección ' + item.address + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        var user = AuthService.getCurrentUser();
        ApiService.deletetUserAddress(user.token, user.id, item.id).then(
          (res) => {
            if (res && res.status == 'success') {
              Swal.fire({
                icon: 'success',
                title: 'Hecho!',
                text: 'Dirección Eliminada!',
              });
              this.getUserAddresses();
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    });
  
  };

  editAddress = (item) => {
    var user = AuthService.getCurrentUser();
    //console.log(item);
    this.setState({ id_address: item.id ? item.id : '' });
    this.setState({ address: item.address ? item.address : '' });
    this.setState({ url_address: item.url_google ? item.url_google : '' });
    this.setState({ number: item.number ? item.number : '' });
    this.setState({ interior: item.interior ? item.interior : '' });
    this.setState({ ubigeo: item.ubigeo ? item.ubigeo : '' });
    for (let i = 0; i < this.state.data_districts.length; i++) {
      if (this.state.data_districts[i]['value'] === item.ubigeo) {
        this.handleDistrict(this.state.data_districts[i]);
      }
    }
    for (let i = 0; i < this.state.dataTipoDireccion.length; i++) {
      if (this.state.dataTipoDireccion[i]['value'] === item.type_address) {
        this.handleTypeAddress(this.state.dataTipoDireccion[i]);
      }
    }
    document.getElementById('add-address-overlay').style.display = 'block';
    this.setState({ action: 'update' });

  };

  registerAddress = (e) => {
    this.setState({ id_address: '' });
    this.setState({ address: '' });
    this.setState({ url_address: '' });
    this.setState({ number: '' });
    this.setState({ interior: '' });
    this.setState({ ubigeo: '' });
    this.setState({ district: '' });
    document.getElementById('add-address-overlay').style.display = 'block';
    this.setState({ action: 'register' });
  };

  editUser = (item) => {
    this.setState({ dataEdit: item });
    var user = AuthService.getCurrentUser();
    //console.log('editUser', item, user, this.state);
    for (let i = 0; i < this.state.dataTipo.length; i++) {
      if (this.state.dataTipo[i]['value'] === this.state.type_document) {
        this.handleTypeDoc(this.state.dataTipo[i]);
        //console.log(this.state.dataTipo[i]);
      }
    }
    document.getElementById('update-user-overlay').style.display = 'block';
  
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

 
  onOpenModalHistoryReferidos() {
    document.getElementById('history-overlay-referidos').style.display = 'block';
  }

  onCloseModalHistoryReferidos() {
    document.getElementById('history-overlay-referidos').style.display = 'none';
  }
  onOpenModalHistory() {
    document.getElementById('history-overlay').style.display = 'block';
  }

  onCloseModalHistory() {
    document.getElementById('history-overlay').style.display = 'none';
  }

  onCloseModalAddAddress() {
    document.getElementById('add-address-overlay').style.display = 'none';
  }

  onCloseModalUpdateUser() {
    document.getElementById('update-user-overlay').style.display = 'none';
  }

  onOpenModalUpdatePassword() {
    document.getElementById('update-password-overlay').style.display = 'block';
  }

  onCloseModalPassword() {
    document.getElementById('update-password-overlay').style.display = 'none';
  }

  removeReferred = (item) => {
    var user = AuthService.getCurrentUser();
    //console.log(item);
    Swal.fire({
      title: 'Confirmar',
      text: '¿Desea elminar el referdio ' + item.name + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        var user = AuthService.getCurrentUser();
        /*let data = {
                    'id_user': data_row.original.id,
                    'status_confirm': status,
                }*/
        /*ApiService.postStateUser(user.token,data).then(
                    (res) => {
                        if(res && res.status == 'success'){
                            if(status==1)
                            {
                                Swal.fire("Hecho!", "Usuario Aprobado!", "success");
                            }else{
                                Swal.fire("Hecho!", "Usuario Rechazado!", "success");
                            }
                            //toast.success("Orden Aceptada!");
                            this.setState({results: []});
                            this.getDataUser();
                        }
                    },
                    error => {
                      const resMessage =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                  );*/
      }
    });
    /*ApiService.deleteCartUser(user.token,item.id).then(
            (res) => {
                if(res && res.status == 'success'){
                    this.getUserAddresses();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );*/
  };

  render() {
    const { cartItems, symbol, total } = this.props;
    const { selectedDistrictOption } = this.state;
    const { selectedTypeAddressOption } = this.state;
    const { selectedTypeDocOption } = this.state;

    document.body.classList.add('dark');

    const customStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        //console.log({ data, isDisabled, isFocused, isSelected });
        return {
          ...styles,
          backgroundColor: isFocused ? '#161616' : '#161616',
          color: isFocused ? '#F8EEE1' : '#F8EEE1',
        };
      },
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          position: 'absolute',
          display: 'inherit',
          paddingLeft: '2px',
        };
      },
      control: (base, state) => ({
        ...base,
        background: '#161616',
        //opacity: 0.5,
        border: '2px solid #F8EEE1',
        //color: "#cfd4da",
        borderRadius: 30,
        backgroundColor: state.isFocused ? '#161616' : null,
      }),
      singleValue: (base, state) => ({
        ...base,
        color: '#F8EEE1',
        position: 'absolute',
        display: 'inherit',
        paddingLeft: '2px',
      }),
      input: (base, state) => ({
        ...base,
        position: 'absolute',
        color: '#F8EEE1',
      }),
      valueContainer: (base, state) => ({
        ...base,
        minHeight: 'inherit',
      }),
    };

    return (
      <div
        className="cart-container"
        style={{ backgroundColor: '#161616 !important' }}
      >
        <div className="breadcrumb-section title-cart profile-tittle">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title page-tittle2">
                  {/*<h1>{title}</h1>*/}
                  <label className="text-confirm-order">MI PERFIL</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="section-b-space" style={{ paddingTop: '0px' }}>
          <div className="container padding-cls">
            <div className="checkout-page container-profile">
              <div className="checkout-form profile-content">
                <div
                  className="checkout row container-data-profile"
                  style={{ margin: 'auto' }}
                >
                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="profile-data">
                      {/*<div className="referred-checkout-title">
                                                    <label>Datos del referido</label>
                                                </div>*/}
                      <div className="row check-out row-check-date">
                        <div className="form-group col-md-6 col-sm-6 col-xs-12 profile-input">
                          <div className="field-label ver-label">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/icon/ver-user.png`}
                              alt=""
                              className="img-fluid"
                            />{' '}
                            Nombres:
                          </div>
                          <input
                            type="text"
                            name="name"
                            value={this.state.name}
                            readOnly
                          />
                          {this.state.id_ambassador == 0 ? (
                            <span
                              className="fa fa-pencil btn-edit-profile"
                              onClick={() => this.editUser(1)}
                            ></span>
                          ) : (
                            ''
                          )}
                        </div>

                        <div
                          className="form-group col-md-6 col-sm-6 col-xs-12 profile-input"
                          style={{ marginBottom: '10px' }}
                        >
                          <div className="field-label ver-label">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/icon/mail.png`}
                              alt=""
                              className="img-fluid"
                            />{' '}
                            Mail:
                          </div>
                          <input
                            type="text"
                            name="email"
                            value={this.state.email}
                            readOnly
                          />
                          {this.state.id_ambassador == 0 ? (
                            <span
                              className="fa fa-pencil btn-edit-profile"
                              onClick={() => this.editUser(3)}
                            ></span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className="row check-out row-check-date">
                        <div className="form-group col-md-6 col-sm-6 col-xs-12 profile-input">
                          <div className="field-label ver-label">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/icon/ver-phone.png`}
                              alt=""
                              className="img-fluid"
                            />{' '}
                            Número de cel:
                          </div>
                          <input
                            type="text"
                            name="cellphone"
                            value={this.state.cellphone}
                            readOnly
                          />
                          {this.state.id_ambassador == 0 ? (
                            <span
                              className="fa fa-pencil btn-edit-profile"
                              onClick={() => this.editUser(4)}
                            ></span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      {(AuthService.getCurrentUser() &&
                        AuthService.getCurrentUser().role == 'EMBAJADOR') ||
                      (AuthService.getCurrentUser() &&
                        AuthService.getCurrentUser().role == 'CLIENTE') ? (
                        <div className="row check-out row-check-date">
                          <div
                            className="form-group col-md-6 col-sm-6 col-xs-12 profile-input"
                            style={{ margin: '0 auto' }}
                          >
                            <div className="field-label ver-label">
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/icon/ver-address.png`}
                                alt=""
                                className="img-fluid"
                              />{' '}
                              Mis direcciones:
                            </div>
                            <div className="profile-address">
                              {this.state.data_address.map((item, index) => {
                                return (
                                  <div key={index}>
                                    <a
                                      className="icon"
                                      onClick={() => this.editAddress(item)}
                                    >
                                      {item.type_address} {item.address}{' '}
                                      {item.number}
                                    </a>
                                    <span className="btn-edit-address">
                                      <a
                                        style={{ marginLeft: '20px' }}
                                        className="icon"
                                        onClick={() => this.removeAddress(item)}
                                      >
                                        <i className="fa fa-times"></i>
                                      </a>
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(AuthService.getCurrentUser() &&
              AuthService.getCurrentUser().role == 'EMBAJADOR') ||
            (AuthService.getCurrentUser() &&
              AuthService.getCurrentUser().role == 'CLIENTE') ? (
              <div>
                <div
                  className="row check-out row-check-date"
                  style={{ textAlign: 'center', marginTop: '1.5rem' }}
                >
                  {AuthService.getCurrentUser() &&
                  AuthService.getCurrentUser().role == 'EMBAJADOR' ? (
                  <div className="form-group col-md-12 col-sm-6 col-xs-12 profile-buttons bnt-ver-profile">
                    <Link
                      to={`${process.env.PUBLIC_URL}/referreds`}
                      className="btn-solid btn btn-edit-reffered btn-primary"
                    >
                    
                      EDITAR REFERIDOS{' '}
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icon/ver-reffered.png`}
                        alt=""
                        className="img-fluid"
                        style={{ marginLeft: '5px' }}
                      />
                    </Link>
                  </div>
                
                    
                    
                  ) : (
                    ''
                  )}

                {AuthService.getCurrentUser() &&
                  AuthService.getCurrentUser().role == 'EMBAJADOR' ? (
                    <div className="form-group col-md-12 col-sm-6 col-xs-12 profile-buttons bnt-ver-profile">
                    <a
                      href="javascript:void(0)"
                      onClick={this.onOpenModalHistoryReferidos}
                      className="ver-btn-solid btn btn-order-history btn-primary"
                    >
                      PEDIDOS REFERIDOS
                    </a>
                  </div>
                
                    
                    
                  ) : (
                    ''
                  )}      
                  <div className="form-group col-md-12 col-sm-6 col-xs-12 profile-buttons bnt-ver-profile">
                    <a
                      href="javascript:void(0)"
                      onClick={this.onOpenModalUpdatePassword}
                      className="ver-btn-solid btn btn-order-history btn-primary"
                    >
                      {/*{this.state.loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}*/}
                      CAMBIAR CONTRASEÑA
                    </a>
                  </div>
                  <div className="form-group col-md-12 col-sm-6 col-xs-12 profile-buttons bnt-ver-profile">
                    <a
                      href="javascript:void(0)"
                      onClick={this.onOpenModalHistory}
                      className="ver-btn-solid btn btn-order-history btn-primary"
                    >
                      HISTORIAL DE PEDIDOS
                    </a>
                  </div>
                  <div className="form-group col-md-12 col-sm-6 col-xs-12 profile-buttons bnt-ver-profile">
                    <a
                      href="javascript:void(0)"
                      onClick={() => this.registerAddress()}
                      className="ver-btn-solid btn btn-save-address btn-primary"
                    >
                      {/*{this.state.loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}*/}
                      AGREGAR DIRECCION
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            {AuthService.getCurrentUser() &&
            AuthService.getCurrentUser().role == 'REFERIDO' ? (
              <>
                <div
                  className="row check-out row-check-date"
                  style={{ textAlign: 'center', marginTop: '1.5rem' }}
                >
                  <div className="form-group col-md-12 col-sm-6 col-xs-12 profile-buttons bnt-ver-profile">
                    <a
                      href="javascript:void(0)"
                      onClick={this.onOpenModalUpdatePassword}
                      className="ver-btn-solid btn btn-order-history btn-primary"
                    >

                      CAMBIAR CONTRASEÑA
                    </a>
                  </div>
                </div>
                <div className="ref-order-history">
                  <div className="row">
                    <label className="text-order-history">
                      HISTORIAL DE PEDIDOS
                    </label>
                  </div>
                  <div className="row">
                    <div className="container padding-cls">
                      <div className="checkout-page">
                        <div className="checkout-form register-address-form">
                          <div
                            className="checkout row container-data-profile"
                            style={{ margin: 'auto' }}
                          >
                            <div className="col-lg-12 col-sm-12 col-xs-12">
                              <div className="row">
                                {this.state.data_orders.map((item, index) => {
                                  return (
                                    <Collapsible
                                      triggerStyle={{ color: '#12DB6F' }}
                                      key={index}
                                      trigger={
                                        <div style={{ display: 'flex' }}>
                                          <span>
                                            <i
                                              className="fa fa-circle"
                                              aria-hidden="true"
                                            ></i>
                                            PEDIDO. #{item.id}
                                          </span>
                                          <span
                                            style={{
                                              position: 'absolute',
                                              right: '40px',
                                            }}
                                          >
                                            {item.date}
                                          </span>
                                        </div>
                                      }
                                    >
                                      <div className="order-history-details">
                                        <div>
                                          {item.Purchases_detail.map(
                                            (sub_item, subindex) => (
                                              <p
                                                key={`sub_item_name` + subindex}
                                              >
                                               ({sub_item.quantity}) {sub_item.Product.name}
                                              </p>
                                            )
                                          )}
                                        </div>
                                        <div>
                                          <p>Delivery :
                                          {' '}
                                          {item.delivery? parseFloat(item.delivery).toFixed(2) : 0}
                                          </p> 
                                          <p>SubTotal :
                                          {' '}
                                          {parseFloat(item.total).toFixed(2)}
                                          </p>
                                          <p>Total</p>
                                          <p>
                                            <img
                                              src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`}
                                              alt=""
                                              className="img-fluid"
                                              style={{
                                                position: 'relative',
                                                bottom: '2px',
                                              }}
                                            />{' '}
                                            {calculateTotal(parseFloat(item.total).toFixed(2), parseFloat(item.delivery).toFixed(2))}
                                          </p>
                                        </div>
                                      </div>
                                    </Collapsible>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </section>

        <div id="history-overlay" className="search-overlay history-overlay">
          <div>
            <span
              className="closebtn"
              onClick={this.onCloseModalHistory}
              title="Close Overlay"
            >
              ×
            </span>
            <div
              className="overlay-content modal-profile-overlay-content"
              style={{ position: 'absolute', top: '5.5rem' }}
            >
              <div className="container">
                <div className="row" style={{ marginBottom: '2rem' }}>
                  <div className="col-md-12">
                    <div className="page-title title-modal-profile">
                      {/*<h1>{title}</h1>*/}
                      <label className="text-confirm-order">
                        HISTORIAL DE PEDIDOS
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="container padding-cls">
                    <div className="checkout-page">
                      <div className="checkout-form register-address-form">
                        <div
                          className="checkout row container-data-profile"
                          style={{ margin: 'auto' }}
                        >
                          <div className="col-lg-12 col-sm-12 col-xs-12">
                            <div className="row">
                              {this.state.data_orders.map((item, index) => {
                                return (
                                  <Collapsible
                                    triggerStyle={{ color: '#12DB6F' }}
                                    key={index}
                                    trigger={
                                      <div style={{ display: 'flex' }}>
                                        <span>
                                          <i
                                            className="fa fa-circle"
                                            aria-hidden="true"
                                          ></i>
                                          PEDIDO #{item.id}
                                        </span>
                                        <span
                                          style={{
                                            position: 'absolute',
                                            right: '40px',
                                          }}
                                        >
                                          {item.date}
                                        </span>
                                      </div>
                                    }
                                  >
                                    <div className="order-history-details">
                                      <div>
                                        {item.Purchases_detail.map(
                                          (sub_item, subindex) => (
                                            <p key={`sub_item_name` + subindex}>
                                              ({sub_item.quantity}) {sub_item.Product.name}
                                            </p>
                                          )
                                        )}
                                      </div>
                                      <div>
                                      <p>Delivery :
                                      {' '}
                                      {item.delivery? parseFloat(item.delivery).toFixed(2) : 0}
                                      </p> 
                                      <p>SubTotal :
                                      {' '}
                                      {parseFloat(item.total).toFixed(2)}
                                      </p>
                                      <p>Total <br />
                                      <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`}
                                        alt=""
                                        className="img-fluid"
                                        style={{
                                          position: 'relative',
                                          bottom: '2px',
                                        }}
                                      />{' '}
                                      {calculateTotal(parseFloat(item.total).toFixed(2), parseFloat(item.delivery).toFixed(2))}
                                      </p>
                                      </div>
                                    </div>
                                  </Collapsible>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="history-overlay-referidos" className="search-overlay history-overlay">
          <div>
            <span
              className="closebtn"
              onClick={this.onCloseModalHistoryReferidos}
              title="Close Overlay"
            >
              ×
            </span>
            <div
              className="overlay-content modal-profile-overlay-content"
              style={{ position: 'absolute', top: '5.5rem' }}
            >
              <div className="container">
                <div className="row" style={{ marginBottom: '2rem' }}>
                  <div className="col-md-12">
                    <div className="page-title title-modal-profile">
                      {/*<h1>{title}</h1>*/}
                      <label className="text-confirm-order">
                        PEDIDOS DE REFERIDOS
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="container padding-cls">
                    <div className="checkout-page">
                      <div className="checkout-form register-address-form">
                        <div
                          className="checkout row container-data-profile"
                          style={{ margin: 'auto' }}
                        >
                          <div className="col-lg-12 col-sm-12 col-xs-12">
                            <div className="row">
                              {this.state.data_orders_referidos.map((item, index) => {
                                return (
                                  <Collapsible
                                    triggerStyle={{ color: '#12DB6F' }}
                                    key={index}
                                    trigger={
                                      <div style={{ display: 'flex' }}>
                                        <span>
                                          <i
                                            className="fa fa-circle"
                                            aria-hidden="true"
                                          ></i>
                                          PEDIDO #{item.id_order} - {item.User.name}
                                        </span>
                                        <span
                                          style={{
                                            position: 'absolute',
                                            right: '40px',
                                          }}
                                        >
                                          {item.date}
                                        </span>
                                      </div>
                                    }
                                  >
                                    <div className="order-history-details">
                                      <div>
                                        {item.Purchases_detail.map(
                                          (sub_item, subindex) => (
                                            <p key={`sub_item_name` + subindex}>
                                              ({sub_item.quantity}) {sub_item.Product.name}
                                            </p>
                                          )
                                        )}
                                      </div>
                                      <div>
                                        <p>Total</p>
                                        <p>
                                          <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`}
                                            alt=""
                                            className="img-fluid"
                                            style={{
                                              position: 'relative',
                                              bottom: '2px',
                                            }}
                                          />{' '}
                                          {parseFloat(item.total).toFixed(2)}
                                        </p>
                                      </div>
                                    </div>
                                  </Collapsible>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="add-address-overlay"
          className="ver-modal-overlay add-address-overlay"
          style={{ overflow: 'auto' }}
        >
          <div>
            <span
              className="closebtn"
              onClick={this.onCloseModalAddAddress}
              title="Close Overlay"
            >
              ×
            </span>
            <div
              className="overlay-content"
              style={{ position: 'absolute', top: '5.5rem' }}
            >
              <div
                className="container"
                style={{ padding: '0px 30px 0px 20px' }}
              >
                <div className="row" style={{ marginBottom: '2rem' }}>
                  <div className="col-md-12">
                    <div className="page-title title-modal-profile">
                      {/*<h1>{title}</h1>*/}
                      <label className="text-confirm-order">
                        {this.state.action == 'register'
                          ? 'REGISTRAR DIRECCIÓN'
                          : 'ACTUALIZAR DIRECCIÓN'}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="container padding-cls">
                    <div className="checkout-page">
                      <div className="checkout-form register-address-form">
                        <div
                          className="checkout row container-data-address"
                          style={{ margin: 'auto' }}
                        >
                          <div className="col-lg-12 col-sm-12 col-xs-12">
                            <div className="row">
                              <Form
                                style={{ width: '100%' }}
                                //onSubmit={this.handleAddAddress}
                                onSubmit={
                                  this.state.action == 'register'
                                    ? this.handleAddAddress
                                    : this.handleUpdateAddress
                                }
                                autoComplete="off"
                                encType="multipart/form-data"
                                method="POST"
                                ref={(c) => {
                                  this.form = c;
                                }}
                              >
                                {/*<div className="referred-checkout-title">
                                                                <label>Datos del referido</label>
                                                            </div>*/}
                                <div
                                  className="row check-out"
                                  style={{ marginBottom: '1rem' }}
                                >
                                  <div
                                    className="form-group col-md-4 col-sm-4 col-xs-12"
                                    style={{ maxWidth: '100%', flex: 'auto' }}
                                  >
                                    <div
                                      className="field-label"
                                      style={{ marginBottom: '10px' }}
                                    >
                                      AV/ CALLE/ JIRÓN
                                    </div>
                                    <Select
                                      value={selectedTypeAddressOption}
                                      onChange={this.handleTypeAddress}
                                      options={this.state.dataTipoDireccion}
                                      placeholder={'Seleccione una opción'}
                                      styles={customStyles}
                                    />
                                    {this.state.errors.typeAddress &&
                                    !this.state.selectedTypeAddressOption ? (
                                      <div
                                        className="alert alert-danger ver-alert"
                                        role="alert"
                                      >
                                        Este campo es obligatorio!
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="row check-out row-check-date"
                                  style={{ marginBottom: '1rem' }}
                                >
                                  <Input
                                    type="text"
                                    name="id_address"
                                    value={this.state.id_address}
                                    hidden="1"
                                  />
                                  <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                    <div className="field-label">NOMBRE</div>
                                    <Input
                                      type="text"
                                      name="address"
                                      value={this.state.address}
                                      onChange={this.onChangeAddress}
                                      validations={[required]}
                                    />
                                    {/*{this.validator.message('name', this.state.address, 'required|alpha')}*/}
                                  </div>
                                </div>
                                <div
                                  className="row check-out row-check-date"
                                  style={{ marginBottom: '1rem' }}
                                >
                                  <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                    <div className="field-label">NÚMERO</div>
                                    <Input
                                      type="text"
                                      name="address"
                                      value={this.state.number}
                                      onChange={this.onChangeNumber}
                                      validations={[required]}
                                    />
                                    {/*{this.validator.message('name', this.state.address, 'required|alpha')}*/}
                                  </div>
                                </div>
                                <div
                                  className="row check-out row-check-date"
                                  style={{ marginBottom: '1rem' }}
                                >
                                  <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                    <div className="field-label">INTERIOR</div>
                                    <Input
                                      type="text"
                                      name="address"
                                      value={this.state.interior}
                                      onChange={this.onChangeInterior}
                                      validations={[required]}
                                    />
                                    {/*{this.validator.message('name', this.state.address, 'required|alpha')}*/}
                                  </div>
                                </div>
                                <div
                                  className="row check-out"
                                  style={{ marginBottom: '1rem' }}
                                >
                                  <div
                                    className="form-group col-md-4 col-sm-4 col-xs-12"
                                    style={{ maxWidth: '100%', flex: 'auto' }}
                                  >
                                    <div
                                      className="field-label"
                                      style={{ marginBottom: '10px' }}
                                    >
                                      DISTRITO
                                    </div>
                                    <Select
                                      value={selectedDistrictOption}
                                      onChange={this.handleDistrict}
                                      options={this.state.data_districts}
                                      placeholder={'Seleccione un distrito'}
                                      styles={customStyles}
                                    />
                                    {this.state.errors.district &&
                                    !this.state.selectedDistrictOption ? (
                                      <div
                                        className="alert alert-danger ver-alert"
                                        role="alert"
                                      >
                                        Este campo es obligatorio!
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="row check-out row-check-date"
                                  style={{ marginBottom: '2rem' }}
                                >
                                  <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                    <div className="field-label">
                                      LINK EN GOOGLE MAPS
                                    </div>
                                    <Input
                                      type="text"
                                      name="url_address"
                                      value={this.state.url_address}
                                      onChange={this.onChangeUrl}
                                      validations={[required]}
                                    />
                                    <a
                                      target="_blank"
                                      href="https://www.google.com/maps"
                                    >
                                      <div
                                        className="field-label"
                                        style={{ fontSize: '12px' }}
                                      >
                                        IR A GOOGLE MAPS
                                      </div>
                                    </a>
                                    {/*{this.validator.message('url_address', this.state.url_address, 'required|alpha')}*/}
                                  </div>
                                </div>
                                <div
                                  className="row check-out row-check-date"
                                  style={{
                                    textAlign: 'center',
                                    marginTop: '1rem',
                                  }}
                                >
                                  <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                    <button className="ver-btn-solid btn btn-save-address btn-primary">
                                      {/*{this.state.loading && (
                                                                    <span className="spinner-border spinner-border-sm"></span>
                                                                )}*/}
                                      GUARDAR
                                    </button>
                                  </div>
                                </div>
                                {this.state.message && (
                                  <div className="form-group">
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {this.state.message}
                                    </div>
                                  </div>
                                )}
                                <CheckButton
                                  style={{ display: 'none' }}
                                  ref={(c) => {
                                    this.checkBtn = c;
                                  }}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="update-user-overlay"
          className="ver-modal-overlay update-user-overlay"
          style={{ overflow: 'auto' }}
        >
          <div>
            <span
              className="closebtn"
              onClick={this.onCloseModalUpdateUser}
              title="Close Overlay"
            >
              ×
            </span>
            <div
              className="overlay-content"
              style={{ position: 'absolute', top: '5.5rem' }}
            >
              <div
                className="container"
                style={{ padding: '0px 30px 0px 20px' }}
              >
                <div className="row" style={{ marginBottom: '2rem' }}>
                  <div className="col-md-12">
                    <div className="page-title title-modal-profile">
                      <label className="text-confirm-order">
                        ACTUALIZAR DATOS
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="container padding-cls">
                    <div className="checkout-page">
                      <div className="checkout-form register-address-form">
                        <div
                          className="checkout row container-data-address"
                          style={{ margin: 'auto' }}
                        >
                          <div className="col-lg-12 col-sm-12 col-xs-12">
                            <div className="row">
                              <Form
                                style={{ width: '100%' }}
                                onSubmit={this.handleUpdateUser}
                                autoComplete="off"
                                encType="multipart/form-data"
                                method="POST"
                                ref={(c) => {
                                  this.form = c;
                                }}
                              >
                                {this.state.dataEdit == 1 ? (
                                  <div
                                    className="row check-out row-check-date"
                                    style={{ marginBottom: '1rem' }}
                                  >
                                    <Input
                                      type="text"
                                      name="id_referred"
                                      value={this.state.id_referred}
                                      hidden="1"
                                    />
                                    <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                      <div className="field-label">NOMBRES</div>
                                      <Input
                                        type="text"
                                        name="address"
                                        value={this.state.name}
                                        onChange={this.onchangeName}
                                        validations={[required]}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {/*this.state.dataEdit==2?
                                    <div className="row check-out row-check-date" style={{marginBottom: '1rem'}}>
                                        <Input type="text" name="id_referred" value={this.state.id_referred} hidden="1"/>
                                        <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                            <div className="field-label">APELLIDOS</div>
                                            <Input type="text" name="address" value={this.state.last_name} onChange={this.onchangeLastName} validations={[required]}/>
                                        </div>
                                    </div>
                                    :''
                                */}
                                {this.state.dataEdit == 3 ? (
                                  <div
                                    className="row check-out row-check-date"
                                    style={{ marginBottom: '1rem' }}
                                  >
                                    <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                      <div className="field-label">MAIL</div>
                                      <Input
                                        type="text"
                                        name="address"
                                        value={this.state.email}
                                        onChange={this.onchangeEmail}
                                        validations={[required]}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {this.state.dataEdit == 4 ? (
                                  <div
                                    className="row check-out row-check-date"
                                    style={{ marginBottom: '1rem' }}
                                  >
                                    <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                      <div className="field-label">CELULAR</div>
                                      <Input
                                        type="number"
                                        name="address"
                                        value={this.state.cellphone}
                                        onChange={(e) =>
                                          this.onChangeCellphone(e)
                                        }
                                        validations={[required]}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {this.state.dataEdit == 5 ? (
                                  <div
                                    className="row check-out"
                                    style={{ marginBottom: '1rem' }}
                                  >
                                    <div
                                      className="form-group col-md-4 col-sm-4 col-xs-12"
                                      style={{ maxWidth: '100%', flex: 'auto' }}
                                    >
                                      <div
                                        className="field-label"
                                        style={{ marginBottom: '10px' }}
                                      >
                                        TIPO DE DOCUMENTO
                                      </div>
                                      <Select
                                        value={selectedTypeDocOption}
                                        onChange={this.handleTypeDoc}
                                        options={this.state.dataTipo}
                                        placeholder={
                                          'Seleccione un tipo de documento'
                                        }
                                        styles={customStyles}
                                      />
                                      {this.state.errors.referred &&
                                      !this.state.selectedTypeDocOption ? (
                                        <div
                                          className="alert alert-danger ver-alert"
                                          role="alert"
                                        >
                                          Este campo es obligatorio!
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                                
                                <div
                                  className="row check-out row-check-date"
                                  style={{
                                    textAlign: 'center',
                                    marginTop: '2rem',
                                  }}
                                >
                                  <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                    <button className="ver-btn-solid btn btn-save-address btn-primary">
                                      GUARDAR
                                    </button>
                                  </div>
                                </div>
                                {this.state.message && (
                                  <div className="form-group">
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {this.state.message}
                                    </div>
                                  </div>
                                )}
                                <CheckButton
                                  style={{ display: 'none' }}
                                  ref={(c) => {
                                    this.checkBtn = c;
                                  }}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="update-password-overlay"
          className="ver-modal-overlay update-password-overlay"
          style={{ overflow: 'auto' }}
        >
          <div>
            <span
              className="closebtn"
              onClick={this.onCloseModalPassword}
              title="Close Overlay"
            >
              ×
            </span>
            <div
              className="overlay-content"
              style={{ position: 'absolute', top: '5.5rem' }}
            >
              <div
                className="container"
                style={{ padding: '0px 30px 0px 20px' }}
              >
                <div className="row" style={{ marginBottom: '2rem' }}>
                  <div className="col-md-12">
                    <div className="page-title title-modal-profile">
                      <label className="text-confirm-order">
                        CAMBIAR CONTRASEÑA
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="container padding-cls">
                    <div className="checkout-page">
                      <div className="checkout-form register-address-form">
                        <div
                          className="checkout row container-data-address"
                          style={{ margin: 'auto' }}
                        >
                          <div className="col-lg-12 col-sm-12 col-xs-12">
                            <div className="row">
                              <Form
                                style={{ width: '100%' }}
                                onSubmit={this.handleUpdatePassword}
                                autoComplete="off"
                                encType="multipart/form-data"
                                method="POST"
                                ref={(c) => {
                                  this.form = c;
                                }}
                              >
                                <div
                                  className="row check-out row-check-date"
                                  style={{ marginBottom: '1rem' }}
                                >
                                  <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                    <div className="field-label">
                                      CONTRASEÑA
                                    </div>
                                    <Input
                                      type="password"
                                      name="address"
                                      value={this.state.password}
                                      onChange={this.onChangePassword}
                                    />
                                    {!this.state.password ||
                                    this.state.password == '' ? (
                                      <div
                                        className="alert alert-danger ver-alert"
                                        role="alert"
                                      >
                                        Este campo es obligatorio!
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="row check-out row-check-date"
                                  style={{ marginBottom: '1rem' }}
                                >
                                  <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                    <div className="field-label">
                                      CONFIRMAR CONTRASEÑA
                                    </div>
                                    <Input
                                      type="password"
                                      name="address"
                                      value={this.state.confirm_password}
                                      onChange={this.onChangeConfirmPassword}
                                    />
                                    {!this.state.confirm_password ||
                                    this.state.confirm_password == '' ? (
                                      <div
                                        className="alert alert-danger ver-alert"
                                        role="alert"
                                      >
                                        Este campo es obligatorio!
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    {this.state.password &&
                                    this.state.confirm_password &&
                                    this.state.password !=
                                      this.state.confirm_password ? (
                                      <div
                                        className="alert alert-danger ver-alert"
                                        role="alert"
                                      >
                                        La contraseña no coincide
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="row check-out row-check-date"
                                  style={{
                                    textAlign: 'center',
                                    marginTop: '2rem',
                                  }}
                                >
                                  <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                    <button className="ver-btn-solid btn btn-save-address btn-primary">
                                      GUARDAR
                                    </button>
                                  </div>
                                </div>
                                {this.state.message && (
                                  <div className="form-group">
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {this.state.message}
                                    </div>
                                  </div>
                                )}
                                <CheckButton
                                  style={{ display: 'none' }}
                                  ref={(c) => {
                                    this.checkBtn = c;
                                  }}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  symbol: state.data.symbol,
  total: getCartTotal(state.cartList.cart),
});

export default connect(mapStateToProps, { removeFromWishlist })(VerProfile);
