import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'


import Breadcrumb2 from "../common/breadcrumb2";
import {getCartTotal} from "../../services";
import {removeFromPurchaseRefered, incrementQty, decrementQty, getFromPurchaseRefered,getFromCartUser} from '../../actions'
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Checkbox from '@material-ui/core/Checkbox';
import Swal from 'sweetalert2';
import store from '../../store';

var pucharse_selected = [];
class referredOrders extends Component {

    constructor (props) {
        super (props);
        this.handlePurchase = this.handlePurchase.bind(this);
        this.state = {
            results: [],
            order_results: [],
            total: 0,
        };
    }

    componentWillMount() {
        var user = AuthService.getCurrentUser();
        if(user)
        {
            pucharse_selected = [];
            this.checkOpen();
        }else{
            this.props.history.push("/login");
        }
    }

    checkOpen(){
        var user = AuthService.getCurrentUser();
        ApiService.checkOpen(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('res.data',res.data);
					if(res.data.option_value == 'NO')
					{
						if(window.location.pathname != '/closed')
						{
							this.props.history.push("/closed")
						}
					}else{
                        this.getRefOrders(true);
                    }
                }else{
					this.props.history.push("/closed")
            		window.location.reload();
				}
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    componentDidUpdate(prevProps, prevState){
		//console.log('prevProps',prevProps)
        //console.log('prevState',prevState)
        //console.log('this.props',this.props)
        //console.log('this.state',this.state)
        if(this.props.order_results != prevProps.order_results){
            //console.log('this.removeFromPurchaseRefered')
            
            /*let dataFilter = prevProps.cartItems.filter(data1=>{
                let res = this.props.cartItems.find((data2)=>{
                 return data2.id == data1.id;
                 });
              return res == undefined;
              });

            //console.log('dataFilter', dataFilter)
            if(dataFilter.length>0){
                this.removeFromPurchaseReferedUpdate(dataFilter[0]);
            }*/
            this.getRefOrders(false);
        }
        //
    }


    handlePurchase(e) {
        if(pucharse_selected.length == 0)
        {
            Swal.fire("Debe seleccionar al menos un pedido.");
            return true;
        }else{
            localStorage.removeItem("orders_selected");
            localStorage.setItem("orders_selected", []);
            localStorage.setItem("orders_selected", JSON.stringify(pucharse_selected));
            this.props.history.push("/referred-checkout");
        }
      }

      handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        //console.log(value)
        var item_check =  JSON.parse(event.target.value);
        //console.log(item_check.id);
        if(value == true)
        {
            pucharse_selected.push(item_check);
        }else{
            for (let i = 0; i < pucharse_selected.length; i++)
            {
                if(pucharse_selected[i].id == item_check.id)
                {
                    pucharse_selected.splice(i, 1);
                }
            }
        }
        //console.log(pucharse_selected);
      }
    

    decrementQty = (item) => {

        var user = AuthService.getCurrentUser();
         var cart_quantity = item.quantity - 1;
         if(cart_quantity == 0){
             return true;
         }
         let data_cart = {
            'id_cart': item.id,
            'token': user.token_order,
            'id_user': user.id,
            'id_product': item.id_product,
            'quantity': cart_quantity,
            'price': item.price,
        }

        ApiService.updateCartUser(user.token,data_cart).then(
             (res) => {
                 if(res && res.status == 'success'){
                    var new_data = this.state.results.map(function(row) {
                        return { 
                          id: row.id, 
                          id_product: row.id_product, 
                          id_user: row.id_user, 
                          price: row.price, 
                          quantity: row.id === item.id && row.quantity > 1? row.quantity - 1 : row.quantity, 
                          token: row.token, 
                          url: row.url,
                          name: row.name,
                        }
                     })
                    
                     this.setState({ results: new_data });
            
                     var sum_total = 0;
                        for (let i = 0; i < new_data.length; i++){
                            sum_total = sum_total + Number(new_data[i]['price'] * new_data[i]['quantity']);
                        }
                     this.setState({ total:sum_total });
                 }
             },
             error => {
               const resMessage =
                 (error.response &&
                   error.response.data &&
                   error.response.data.message) ||
                 error.message ||
                 error.toString();
             }
           );
    }

    incrementQty = (item) => {

         var user = AuthService.getCurrentUser();
         var cart_quantity = item.quantity + 1;
         let data_cart = {
            'id_cart': item.id,
            'token': user.token_order,
            'id_user': user.id,
            'id_product': item.id_product,
            'quantity': cart_quantity,
            'price': item.price,
        }

        ApiService.updateCartUser(user.token,data_cart).then(
             (res) => {
                 if(res && res.status == 'success'){
                    var new_data = this.state.results.map(function(row) {
                        return { 
                          id: row.id, 
                          id_product: row.id_product, 
                          id_user: row.id_user, 
                          price: row.price, 
                          quantity: row.id === item.id ? row.quantity + 1 : row.quantity, 
                          token: row.token, 
                          url: row.url,
                          name: row.name,
                        }
                     })
                    
                     this.setState({ results: new_data });
            
                     var sum_total = 0;
                        for (let i = 0; i < new_data.length; i++){
                            sum_total = sum_total + Number(new_data[i]['price'] * new_data[i]['quantity']);
                        }
                     this.setState({ total:sum_total });
                 }
             },
             error => {
               const resMessage =
                 (error.response &&
                   error.response.data &&
                   error.response.data.message) ||
                 error.message ||
                 error.toString();
             }
           );
    }

    removeFromPurchaseRefered = (item) => {
        //console.log('item', item);
        Swal.fire({
            title: 'Confirmar',
            text: '¿Desea cancelar el pedido?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
         }).then((result) => {
            if(result.value){
        var user = AuthService.getCurrentUser();
        let body = {
            "idPurchase": item.id,
            "id_user": item.id_user,
        }
        //console.log('body',body);
        ApiService.postCancelPurchase(user.token,body).then(
            (res) => {
                if(res && res.status == 'success'){
                    store.dispatch(removeFromPurchaseRefered(item))
                    this.getRefOrders(false);
                    Swal.fire({
                        type: 'success',
                        title: 'Hecho!',
                        text: 'Pedido Cancelado!',
                        timer: 1500
                    });
                }else{
                    if(res.mensajeError)
                    {
                        Swal.fire({
                            showConfirmButton: false,
                            type: 'warning',
                            title: 'Aviso!',
                            html: res.mensajeError,
                        });
                    }else if(res.message){
                        Swal.fire({
                            showConfirmButton: false,
                            type: 'warning',
                            title: 'Aviso!',
                            html: res.message,
                        });
                    }                    
                }
                /*const items = this.showStatus(res.data).map(function(row) {
                    return { Imagen: row.background_url, id: row.id, Nombre : row.name, Color : row.color, Estado : row.estado, status : row.status }
                 })*/
                /*const items = res.data.map(function(row) {
                    return { id: row.id, Nombre : row.name, Color : row.color, Estado : row.status }
                 })*/
                //this.setState({ results_categ:res });
                //this.renderResults();
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        }
        })
    }

    getRefOrders(storeUpdate){
        var user = AuthService.getCurrentUser();
        ApiService.getReferredOrders(user.token,user.token_order,user.id).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('data',res.data);
                    if(storeUpdate == true){
                        store.dispatch(getFromPurchaseRefered(res.data))
                        store.dispatch(getFromCartUser(res.carts_data))
                    }
                    this.setState({ order_results:res.data });
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    render (){

        const {cartItems, order_results, total} = this.props;
        document.body.classList.add('dark');
        const symbol = <img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{width: 'auto',height: 'auto', bottom: '2px', marginRight: '5px'}}/>;//'S/ ';
        return (
            <div className="cart-container" style={{backgroundColor: '#161616 !important', height: '100vh'}}>
                {/*SEO Support*/}
                {/*<Helmet>
                    <title>MultiKart | Cart List Page</title>
                    <meta name="description" content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses." />
                </Helmet>*/}
                {/*SEO Support End */}

                {/*<Breadcrumb2 title={'Cart Page'}/>*/}
                <div className="breadcrumb-section title-cart">
                <div className="container">
                    <div className="row">
                            <div className="col-md-12">
                                <div className="page-title page-tittle2">
                                    {/*<h1>{title}</h1>*/}
                                    <label className="text-confirm-order">REFERIDOS</label>
                                </div>
                            </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title referred-order-text">
                                <label className="text-credit">no te olvides de coordinar la entrega directamente</label>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                {this.state.order_results.length>0 ?
                <section className="cart-section section-b-space section-details">
                    <div className="container">
                        <div className="row">
                            {/*<label className="title-products">REFERIDOS</label>*/}
                            <div className="col-sm-12 cart-container">
                                <table className="table cart-table table-responsive-xs table-items table-ref-orders">
                                    <thead>
                                    <tr className="table-head th-referred-orders">
                                        <th scope="col">Referido</th>
                                        <th scope="col"><span>producto</span></th>
                                        <th scope="col">cantidad</th>
                                        <th scope="col">precio</th>
                                        <th scope="col">total</th>
                                        <th scope="col">mover al carrito</th>
                                    </tr>
                                    </thead>
                                    {/*console.log(this.state.order_results)*/}
                                    {this.state.order_results.map((item, index) => {
                                        return (
                                        <tbody key={index}>
                                            <tr>
                                                <td className="td-checkout1">
                                                <span>{item.User.name}</span>
                                                <div className="tb-movil-ref-items">
                                                { 
                                                        item.Purchases_detail.map((sub_item, subindex) =>
                                                        <div key={`sub_item_name`+subindex} style={{display: 'flex', marginBottom: '10px'}}>
                                                        <div style={{marginRight: '15px'}}>
                                                        <img src={`${process.env.REACT_APP_URL_FILES}images/products/` + (sub_item && sub_item.Product && sub_item.Product.url ? sub_item.Product.url : (sub_item && sub_item.Product.logo ? sub_item.Product.logo : ''))} className="sub_item_img"/>
                                                        </div>
                                                        <div className="tb-movil-prod-name">
                                                            {sub_item.Product.name} {(sub_item && sub_item.Product && sub_item.Product.size ? ' - ' + sub_item.Product.size : '')}
                                                        </div>
                                                        </div>
                                                        )
                                                }
                                                </div>
                                                </td>
                                                <td className="td-checkout1">
                                                    <div className="tb-movil-ref-actions">
                                                        <Checkbox
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            value={JSON.stringify(item)}
                                                            onChange={this.handleInputChange}
                                                        />
                                                        <a style={{marginLeft: '20px'}} className="icon" onClick={() => this.removeFromPurchaseRefered(item)}>
                                                            <i className="fa fa-times fa-lg"></i>
                                                        </a>
                                                    </div>
                                                    <div className="ref-order-img-name">
                                                    { 
                                                        item.Purchases_detail.map((sub_item, subindex) =>
                                                        <div key={`sub_item_name`+subindex} style={{display: 'flex', marginBottom: '10px'}}>
                                                        <div style={{marginRight: '15px'}}>
                                                        <img src={`${process.env.REACT_APP_URL_FILES}images/products/` + (sub_item && sub_item.Product && sub_item.Product.url ? sub_item.Product.url : (sub_item && sub_item.Product.logo ? sub_item.Product.logo : ''))} className="sub_item_img"/>
                                                        </div>
                                                        <div>
                                                            {sub_item.Product.name} {(sub_item && sub_item.Product && sub_item.Product.size ? ' - ' + sub_item.Product.size : '')}
                                                        </div>
                                                        </div>
                                                        )
                                                    }
                                                    </div>
                                                    <div className="tb-movil-ref-cant">
                                                        { 
                                                            item.Purchases_detail.map((sub_item, subindex) =>
                                                            <div key={subindex}>
                                                                <div className="tb-movil-quantity" key={`sub_item_quantity`+subindex} style={{paddingBottom: '20px'}}><span style={{marginLeft: '15px'}}>{sub_item.quantity}</span></div>
                                                                <div key={`sub_item_price`+subindex} style={{paddingBottom: '20px'}}>{symbol}{(parseFloat(sub_item.price)).toFixed(0)}</div>
                                                            </div>
                                                            )
                                                        }
                                                    </div>
                                                    {/*<div className="mobile-cart-content row">
                                                        <div className="col-xs-3">
                                                            <h2 className="td-color">
                                                                <a href="#" className="icon" onClick={() => this.removeFromPurchaseRefered(item)}>
                                                                    <i className="icon-close"></i>
                                                                </a>
                                                            </h2>
                                                        </div>
                                                        <div className="col-xs-3 col-quantity">
                                                                <div className="qty-box box-quantity">
                                                                    <div className="input-group">
                                                                        <span className="input-group-prepend">
                                                                            <button type="button" className="btn quantity-left-minus" onClick={() => this.decrementQty(item)} data-type="minus" data-field="">
                                                                            <i className="fa fa-angle-left"></i>
                                                                            </button>
                                                                        </span>
                                                                        <input type="text" name="quantity" value={item.quantity} readOnly={true} className="form-control input-number" />

                                                                        <span className="input-group-prepend">
                                                                        <button className="btn quantity-right-plus" onClick={() => this.incrementQty(item)}  data-type="plus" disabled={(item.quantity >= item.stock)? true : false}>
                                                                        <i className="fa fa-angle-right"></i>
                                                                        </button>
                                                                        </span>
                                                                    </div>
                                                                </div>{(item.qty >= item.stock)? 'out of Stock' : ''}
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <h2 className="td-color">{symbol}{item.price}</h2>
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <h2 className="td-color">TOTAL: {symbol}{(item.quantity * item.price).toFixed(2)}</h2>
                                                        </div>
                                                    </div>*/}
                                                </td>
                                                <td className="td-checkout2">
                                                    { 
                                                        item.Purchases_detail.map((sub_item, subindex) =>
                                                        <div key={`sub_item_quantity`+subindex} style={{paddingBottom: '20px'}}>{sub_item.quantity}</div>
                                                        )
                                                    }
                                                </td>
                                                <td className="td-checkout2">
                                                    { 
                                                        item.Purchases_detail.map((sub_item, subindex) =>
                                                        <div key={`sub_item_price`+subindex} style={{paddingBottom: '20px'}}>{symbol}{(parseFloat(sub_item.price)).toFixed(0)}</div>
                                                        )
                                                    }
                                                    {/*<h2>{symbol}{item.price}</h2>*/}
                                                </td>
                                                <td className="td-checkout1">
                                                    {symbol}{(parseFloat(item.total)).toFixed(0)}
                                                </td>
                                                <td>
                                                    <Checkbox
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        value={JSON.stringify(item)}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    <a style={{marginLeft: '20px'}} className="icon" onClick={() => this.removeFromPurchaseRefered(item)}>
                                                        <i className="fa fa-times fa-lg"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody> )
                                    })}
                                </table>
                            </div>
                        </div>
                        <div className="row chk-cart-buttons">
                            <div className="col-12 cart-btn-checkout">
                                {/*<Link to={`${process.env.PUBLIC_URL}/welcome`} className="btn btn-solid btn-referred-checkout"
                                onClick={() => this.handlePurchase()}
                                >AGREGAR REFERIDO</Link>*/}
                                <Link to={`#`} className="btn btn-solid btn-referred-checkout"
                                onClick={() => this.handlePurchase()}
                                >CHECKOUT</Link>
                            </div>
                        </div>
                    </div>
                </section>
                :
                <section className="cart-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div >
                                    <div className="col-sm-12 empty-cart-cls text-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                                        <h3>
                                            <strong>No hay pedidos de tus referidos</strong>
                                        </h3>
                                        {/*<h4>Explore more shortlist some items.</h4>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    order_results: state.purchaseReferedList.purchaseRefered,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    {removeFromPurchaseRefered, incrementQty, decrementQty}
)(referredOrders)