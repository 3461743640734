import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import AuthService from "../../services/auth.service";

let data_children = [];
class VerProductList extends Component {

    constructor(props){
        super(props)

        this.state = {
            open: false,
            stock: 'InStock',
            quantity: 1,
            image: '',
            total: this.props.product.price,
            price: this.props.product.price,
            size_selected: 0,
            //children: this.props.product.Children
        }
    }

    onClickHandle(img) {
        this.setState({ image : img} );
    }
    onOpenModal = (e) => {
        e.preventDefault();
        //console.log(this.props.product.price);
        //this.setState({ total: this.props.product.price });
        //this.setState({ price: this.props.product.price });
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    minusQty = () => {
        if((this.state.quantity - 1) > 0){
            this.setState({quantity: this.state.quantity - 1})
            let total = this.state.price * (this.state.quantity-1);
            this.setState({total: total});
        }
    }

    plusQty = () => {
        this.setState({quantity: this.state.quantity+1})
        let total = this.state.price * (this.state.quantity+1);
        this.setState({total: total});
    }

    changeQty = (e) => {
        if(e.target.value != '')
        {
            this.setState({ quantity: parseInt(e.target.value) })
            let total = this.props.product.price * e.target.value;
            this.setState({total: total});
        }
    }
    //HandleAddCart(product, this.state.quantity )

    thisHandleAddCart(product, quantity, redirect){
        if(data_children.length > 0)
        {
            this.props.handlerAddCart(data_children[this.state.size_selected], quantity, redirect);
        }else{
            this.props.handlerAddCart(product, quantity, redirect);
        }
        //this.props.handlerAddCart(product, quantity, redirect);
        this.onCloseModal();
    }

    select_item(value){
        this.setState({size_selected: value});
        let price = data_children[value].price;
        this.setState({price: price});
        //console.log(price)
        let quantity = this.state.quantity;
        //console.log(quantity)
        let total = price * quantity;
        //console.log(total);
        this.setState({total: total});
    }

    handleImageLoaded() {
        //console.log('loaded!')
        document.getElementsByClassName("page-loader")[0].style.display = "none";
        //this.setState({ imageStatus: "loaded" });
      }

    stopLoading() {
        //console.log('loaded!')
        document.getElementsByClassName("page-loader")[0].style.display = "none";
        //this.setState({ imageStatus: "loaded" });
      }

    render() {
        data_children = this.props.product.Children;
        const {product, onAddToCartClicked, BuynowClicked, onAddToWishlistClicked, onAddToCompareClicked, categoria, indice,cant_categ} = this.props;

        //console.log('categoria ver-list-product', categoria);
        var colorBoton = categoria.color;
        var color_2 = categoria.color_2?categoria.color_2:'#161616';
        var textColor = categoria.text_color?categoria.text_color:'#161616';
        //console.log('categoria ver-list-product categoria.text_color', categoria.text_color , categoria.text_color?categoria.text_color:'#161616');
        var background_image = `${process.env.REACT_APP_URL_FILES}images/categories/` + categoria.background_url;

        //const symbol = '';
        let RatingStars = []

        const customStyles = {
            backgroundColor: colorBoton,
            color: textColor,
            border: "2px solid "+colorBoton,
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                // const color = chroma(data.color);
                //console.log({ data, isDisabled, isFocused, isSelected });
                return {
                  ...styles,
                  backgroundColor: colorBoton,
                  color: colorBoton
                };
              },
            control: (base, state) => ({
              ...base,
              background: "#212121",
              opacity: 0.5,
              border: "2px solid "+colorBoton,
              //color: "#cfd4da",
              borderRadius: 30,
              backgroundColor: colorBoton,
            }),
            singleValue: (base, state) => ({
                ...base,
                color: colorBoton
            }),
          };

        for(var i = 0; i < product.rating; i++) {
            RatingStars.push(<i className="fa fa-star" key={i}></i>)
        }
        var handleAddCart  =   this.props.handlerAddCart;

        let url_image = `${process.env.REACT_APP_URL_FILES}images/products/`;
        let url_image_grammage = `${process.env.REACT_APP_URL_FILES}images/grammage/`;

        let array_grammage = [];
        array_grammage = product.Grammage_Product;
        let img_grammage1 = '';
        let img_grammage2 = '';
        let img_grammage3 = '';
        if(array_grammage.length > 0)
        {
            for (let index = 0; index < array_grammage.length; index++) {
                const element = array_grammage[index];
                //console.log(element.Grammage);
                if(element.Grammage.type == 'TIPO1')
                {
                    img_grammage1 = element.Grammage.image;
                }else if(element.Grammage.type == 'TIPO2'){
                    img_grammage2 = element.Grammage.image;
                }else{
                    img_grammage3 = element.Grammage.image;
                }
            }
        }
        //var ruta = AuthService.getCurrentUser() && AuthService.getCurrentUser().role ? (AuthService.getCurrentUser().role == 'REFERIDO' ? 'cart' : 'user-checkout') : 'cart';
        return (
                <div>
                    <div className="home home-list-products">
                        <div className="container">
                            <div>
                                <div className="row_product row_prods">
                                    <div className="list-product content-product-img background-circle-prod" style={{background: 'linear-gradient(162.68deg, '+colorBoton+' 8.81%, '+color_2+' 89.66%)'}}>
                                        {/* {product.background_url}
                                        {product.image_1?product.image_1:''}
                                        {product.image_2?product.image_2:''}
                                        {product.image_3?product.image_3:''} */}
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/fondo_bolsa.png`} className="img-fluid" alt="" />
                                        {product && product.logo ? <img src={url_image + product.logo} className="img-fluid" onLoad={this.handleImageLoaded.bind(this)}/> :this.stopLoading()}
                                        {img_grammage1 != ''? <img src={url_image_grammage + img_grammage1} className="img-fluid"/> :'' }
                                        {img_grammage2 != ''? <img src={url_image_grammage + img_grammage2} className="img-fluid"/> :'' }
                                        {img_grammage3 != ''? <img src={url_image_grammage + img_grammage3} className="img-fluid"/> :'' }
                                    </div>
                                    <div className="slider-contain side-watch side-prods">
                                        <div className="items-product">
                                            <h1 className="item-prod-name">{product.name}</h1>
                                            <div className="buttons-product">
                                                <a href="#" data-toggle="modal"
                                                data-target="#quick-view"
                                                title="Quick View"
                                                style={customStyles}
                                                onClick={this.onOpenModal} className="btn ver-btn-solid watch-product">Ver +</a>
                                                {/*<a href="#" className="btn btn-favourite"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/heart.png`} className="img-fluid" alt="" /></a>
                                                <a href="#" className="btn btn-favourite btn-likes"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/likes.png`} className="img-fluid" alt="" /></a>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="product-index"><label>{indice}</label> / {cant_categ}</div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="product-index">01/02</div>*/}
                    {/*<div className="img-wrapper">
                        <div className="lable-block">
                            {(product.new == true)? <span className="lable3">new</span> : ''}
                            {(product.sale == true)? <span className="lable4">on sale</span> : ''}

                        </div>
                        <div className="front">
                            <Link to={`${process.env.PUBLIC_URL}/left-sidebar/product/${product.id}`} ><img
                                src={`${
                                    product.variants?
                                        this.state.image?this.state.image:product.variants[0].images
                                        :product.pictures[0]
                                    }`}
                                className="img-fluid"
                                alt="" /></Link>
                        </div>
                        <div className="cart-info cart-wrap">
                            <button title="Add to cart" onClick={onAddToCartClicked}>
                                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                            </button>
                            <a href="javascript:void(0)" title="Add to Wishlist" onClick={onAddToWishlistClicked}>
                                <i className="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="javascript:void(0)" data-toggle="modal"
                               data-target="#quick-view"
                               title="Quick View"
                               onClick={this.onOpenModal}><i className="fa fa-search" aria-hidden="true"></i></a>
                            <Link to={`${process.env.PUBLIC_URL}/compare`} title="Compare" onClick={onAddToCompareClicked}>
                                <i className="fa fa-refresh" aria-hidden="true"></i></Link>
                        </div>
                        {product.variants?
                        <ul className="product-thumb-list">
                            { product.variants.map((vari, i) =>
                                <li className={`grid_thumb_img ${(vari.images === this.state.image)?'active':''}`} key={i}>
                                    <a href="javascript:void(0)" title="Add to Wishlist">
                                        <img src={`${vari.images}`} onClick={() => this.onClickHandle(vari.images)} />
                                    </a>
                                </li>)}
                        </ul>:''}

                            </div>*/}
                    {/*<div className="product-detail">
                        <div>
                            <div className="rating">
                                {RatingStars}
                            </div>
                            <Link to={`${process.env.PUBLIC_URL}/left-sidebar/product/${product.id}`}>
                                <h6>{product.name}</h6>
                            </Link>
                            <h4>{symbol}{product.price-(product.price*product.discount/100)}
                                <del><span className="money">{symbol}{product.price}</span></del>
                            </h4>
                            {product.variants?
                            <ul className="color-variant">
                                {product.variants.map((vari, i) => {
                                    return (
                                        <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}></li>)
                                })}
                            </ul>:''}
                        </div>
                    </div>*/}
                    <Modal open={this.state.open} onClose={this.onCloseModal} center classNames={{
                        closeButton: 'btn-close-modal-product'
                        }}>
                        <div className="modal-dialog modal-lg modal-dialog-centered" role="document" style={{marginTop: '4rem'}}>
                            <div className="modal-content quick-view-modal modal-product">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-lg-6  col-xs-12">
                                            <div className="quick-view-img list-product content-product-img background-circle-prod modal-background-prod" style={{background: 'linear-gradient(162.68deg, '+colorBoton+' 8.81%, '+color_2+' 89.66%)'}}>
                                                {/* {product.image_4?product.image_4:''}
                                                {product.logo?product.logo:''} */}
                                                
                                                {product && product.image_4 ? <img src={url_image + product.image_4} className="img-fluid"/> :'' }
                                                {product && product.logo ? <img src={url_image + product.logo} className="img-fluid"/> :'' }
                                            </div>
                                        </div>
                                        <div className="col-lg-6 rtl-text">
                                            <div className="product-right">
                                                {/*<h2> {product.name} </h2>*/}
                                                {/*<h3>{symbol}{product.price}</h3>*/}
                                                {/*{product.variants?
                                                <ul className="color-variant">
                                                    {product.variants.map((vari, i) =>
                                                        <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}></li>)
                                                    }
                                                </ul>:''}*/}
                                                {/*<div className="border-product">
                                                    <h6 className="product-title">product details</h6>
                                                    <p>{product.shortDetails}</p>
                                                </div>*/}
                                                <div className="product-description border-product">
                                                    {/*{product.size?
                                                    <div className="size-box">
                                                        <ul>
                                                            {product.size.map((size, i) => {
                                                                return <li key={i}><a href="#">{size}</a></li>
                                                            })}
                                                        </ul>
                                                    </div>:''}*/}
                                                    <div className="details-prop">
                                                        <div className="row props ver-detail-props">
                                                            <div className="col-md-2  product-info ver-product-info">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_aroma.png`} alt="" />
                                                            </div>
                                                            <div className="col-md-8 ver-product-data">
                                                                <label style={customStyles}>AROMA</label>
                                                                <span>{product.smell}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row props ver-detail-props">
                                                            <div className="col-md-2  product-info ver-product-info">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_puesta.png`} alt="" />
                                                            </div>
                                                            <div className="col-md-8 ver-product-data">
                                                                <label style={customStyles}>PUESTA</label>
                                                                <span>{product.puesta}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row props ver-detail-props">
                                                            <div className="col-md-2  product-info ver-product-info">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_dato.png`} alt="" />
                                                            </div>
                                                            <div className="col-md-8 ver-product-data">
                                                                <label style={customStyles}>DATO</label>
                                                                <span>{product.info}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                    data_children.length > 0?
                                                    <div className="ver-prod-size">
                                                        { data_children.map((item, index ) =>
                                                                <div key={index} className={`ver-size ${this.state.size_selected==index?'size-selected' : 'size-unselected'}`} onClick={() => this.select_item(index)}>
                                                                    {index==0?<img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver-size.png`} alt="" style={{width: '18%', margin: '0 auto', marginTop: '5px', marginBottom: '5px'}}/>:<img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver-size-2.png`} alt="" style={{width: '45%', margin: '0 auto', marginTop: '5px', marginBottom: '5px'}}/>}
                                                                    <span>{item.size}</span>
                                                                    <hr style={{color: 'rgb(248, 238, 225)',backgroundColor: 'rgb(248, 238, 225)',height: '2px', width: '100%', marginTop: '5px', marginBottom: '10px'}}></hr>
                                                                    <label><img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{position: 'relative', bottom: '2px'}}/> {(parseFloat(item.price)).toFixed(0)} </label>
                                                                </div>
                                                            )
                                                        }
                                                        {/*<div className={`ver-size ${this.state.size_selected==1?'size-selected' : 'size-unselected'}`} onClick={() => this.select_item(1)}>
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver-size.png`} alt="" style={{width: '18%', margin: '0 auto', marginTop: '5px', marginBottom: '5px'}}/>
                                                            <span>7GR</span>
                                                            <hr style={{color: 'rgb(248, 238, 225)',backgroundColor: 'rgb(248, 238, 225)',height: '2px', width: '100%', marginTop: '5px', marginBottom: '10px'}}></hr>
                                                            <label><img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{position: 'relative', bottom: '2px'}}/> {(parseFloat(product.price * this.state.quantity)).toFixed(0)} </label>
                                                        </div>
                                                        <div className={`ver-size ${this.state.size_selected==2?'size-selected' : 'size-unselected'}`} onClick={() => this.select_item(2)}>
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver-size-2.png`} alt="" style={{width: '45%', margin: '0 auto', marginTop: '5px', marginBottom: '5px'}}/>
                                                            <span>7GR</span>
                                                            <hr style={{color: 'rgb(248, 238, 225)',backgroundColor: 'rgb(248, 238, 225)',height: '2px', width: '100%', marginTop: '5px', marginBottom: '10px'}}></hr>
                                                            <label><img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{position: 'relative', bottom: '2px'}}/> {(parseFloat(product.price * this.state.quantity)).toFixed(0)} </label>
                                                        </div>*/}
                                                    </div>:''
                                                    }
                                                    <h6 className="product-title-2">CANTIDAD</h6>
                                                    <div className="qty-box">
                                                        <div className="input-group">
                                                              <span className="input-group-prepend span-btn-left">
                                                                <button type="button" className="btn quantity-left-minus modal-btn-number modal-btn-number-left" onClick={this.minusQty} data-type="minus" data-field="">
                                                                <i className="fa fa-minus"></i>
                                                                </button>
                                                              </span>
                                                            <input type="text" name="quantity" value={this.state.quantity}  onChange={this.changeQty} className="form-control input-number" />
                                                                <span className="input-group-prepend">
                                                                <button type="button" className="btn quantity-right-plus modal-btn-number modal-btn-number-right" onClick={this.plusQty} data-type="plus" data-field="">
                                                                <i className="fa fa-plus"></i>
                                                                </button>
                                                               </span>
                                                        </div>
                                                    </div>
                                                    <div className="detail-total">
                                                        <div>
                                                        TOTAL:
                                                        <label style={{marginLeft: '10px'}}><img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{position: 'relative', bottom: '2px'}}/> {(parseFloat(this.state.total)).toFixed(0)} </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                        <div className="row check-out row-check-date " style={{textAlign: 'center'}}>
                                                            <div className="form-group col-md-6 col-sm-6 col-xs-6 profile-buttons">
                                                                {/*<Link to={`${process.env.PUBLIC_URL}/products`} className="ver-btn-solid btn btn-order-history btn-primary"
                                                                style={{
                                                                    backgroundColor: colorBoton,
                                                                    border: "2px solid "+colorBoton,color: colorBoton }} >
                                                                    {this.state.loading && (
                                                                        <span className="spinner-border spinner-border-sm"></span>
                                                                    )}
                                                                    AGREGAR AL CARRITO</Link> */}

                                                                <span className="ver-btn-solid btn btn-add-prod btn-primary"
                                                                style={{
                                                                    backgroundColor: colorBoton,
                                                                    border: '2px solid ' + colorBoton,
                                                                    color: textColor }}
                                                                onClick={() => this.thisHandleAddCart(product, this.state.quantity , null)} >AGREGAR AL CARRITO</span> 
                                                            </div>
                                                        </div>
                                                        <div className="product-buttons">
                                                            {/*<button  className="btn btn-solid btn-purchase" onClick={() => onAddToCartClicked(product, this.state.quantity)} >AÑADIR AL CARRITO</button>*/}
                                                            {/*<span className="btn btn-solid btn-purchase bnt-buy" onClick={() => handleAddCart(product, this.state.quantity)} >Pagar</span>*/}
                                                            
                                                            <span style={customStyles} 
                                                            className="btn ver-btn-solid btn-purchase product-btn-purchase"
                                                            onClick={() => this.thisHandleAddCart(product, this.state.quantity, 1 )}
                                                            >
                                                              PAGAR</span>


                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
        )
    }
}

export default VerProductList;