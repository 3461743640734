import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import {Link, Redirect } from 'react-router-dom'
import PaypalExpressBtn from 'react-paypal-express-checkout';
import SimpleReactValidator from 'simple-react-validator';

import Breadcrumb from "../common/breadcrumb";
import {removeFromWishlist} from '../../actions'
import {getCartTotal} from "../../services";
import Select from 'react-select';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Swal from 'sweetalert2';
import Modal from 'react-responsive-modal';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Collapsible from 'react-collapsible';

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger ver-alert" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };
  

class VerReferreds extends Component {

    constructor (props) {
        super (props)
        this.handleRegisterReferred = this.handleRegisterReferred.bind(this);
        this.handleUpdateReferred = this.handleUpdateReferred.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        //this.onChangeUbigeo = this.onChangeUbigeo.bind(this);
        this.onChangeNumber = this.onChangeNumber.bind(this);
        this.onChangeInterior = this.onChangeInterior.bind(this);
        this.onChangeUrl = this.onChangeUrl.bind(this);
        this.onChangeReference = this.onChangeReference.bind(this);
        this.onchangeName = this.onchangeName.bind(this);
        this.onchangeLastName = this.onchangeLastName.bind(this);
        this.onchangeEmail = this.onchangeEmail.bind(this);
        this.onChangeCellphone = this.onChangeCellphone.bind(this);
        this.onChangeNroDoc = this.onChangeNroDoc.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            action: '',
            name: '',
            last_name: '',
            cellphone: '',
            email: '',
            nro_doc: "99998888",
            password: "",
            data_ref: [],
            data_orders: [],
            data_address: [],
            data_districts: [],
            open: false,
            open_history: false,
            message: "",
            address: "",
            url_address: "",
            ubigeo: "",
            number: "",
            interior: "",
            district: "",
            id_referred: "",
            selectedTypeDocOption: "DNI",
            errors: [{
                district: '',
              }
            ],
            dataTipo: [
                {value: 'DNI',label: 'DNI'},
                {value: 'PASAPORTE',label: 'Pasaporte'}
            ],
        }
        this.validator = new SimpleReactValidator();
    }

    componentWillMount() {
        var user = AuthService.getCurrentUser();
        if(user)
        {
            document.body.style = `background-color: #212121;font-family: 'Work Sans'`;
            this.checkOpen();
        }else{
            this.props.history.push("/login");
            window.location.reload();
        }
    }

    checkOpen(){
        var user = AuthService.getCurrentUser();
        ApiService.checkOpen(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('res.data',res.data);
					if(res.data.option_value == 'NO')
					{
						if(window.location.pathname != '/closed')
						{
							this.props.history.push("/closed")
						}
					}else{
                        this.getUserData();
                        this.getUserAddresses();
                        this.getUserReferreds();
                        this.getUserHistoryOrders();
                        this.getDistricts();
                    }
                }else{
					this.props.history.push("/closed")
            		window.location.reload();
				}
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    getUserData()
    {
        var user = AuthService.getCurrentUser();
        ApiService.getUserDetails(user.token,user.id).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('data',res.data);
                    this.setState({name: res.data.name?res.data.name:''});
                    this.setState({last_name: res.data.last_name?res.data.last_name:''});
                    this.setState({email: res.data.email?res.data.email:''});
                    this.setState({cellphone: res.data.Profile.cell_phonenumber?res.data.Profile.cell_phonenumber:''});
                    this.setState({nro_doc: res.data.nro_document?res.data.nro_document:''});
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    getDistricts()
    {
        var user = AuthService.getCurrentUser();
        ApiService.getDistricts(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('address',res.data);
                    this.items = res.data.map(function(row) {
                        return { value: row.id, label : row.name, key: row.id}
                     })
                    this.setState({data_districts: this.items});
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    handleTypeDoc = selectedTypeDocOption => {
        this.setState(
          { selectedTypeDocOption },
          () => console.log(``)
        );
    };

    getUserAddresses()
    {
        var user = AuthService.getCurrentUser();
        ApiService.getUserAddresses(user.token,user.id).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('address',res.data);
                    this.setState({data_address: res.data});
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    getUserReferreds()
    {
        var user = AuthService.getCurrentUser();
        ApiService.getUserReferreds(user.token,user.id).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('ref',res.data);
                    this.setState({data_ref: res.data});
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    getUserHistoryOrders()
    {
        var user = AuthService.getCurrentUser();
        ApiService.getUserHistoryOrders(user.token,user.id).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('ref',res.data);
                    this.setState({data_orders: res.data});
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    handleUpdateReferred(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
          });
      
          this.form.validateAll();

        if(!this.state.selectedTypeDocOption){
            this.setState({
                errors: {referred : "Error"}
            }) 
            return true;
        }else{
            this.setState({
                errors: {referred : ""}
            }) 
        }
       
        if (this.checkBtn.context._errors.length === 0 && !this.state.errors.referred) {
            //this.setState({ open: false });
            var user = AuthService.getCurrentUser();
            let data_user = {
                'name': this.state.name,
                'last_name': this.state.last_name,
                'email': this.state.email,
                'cell_phonenumber': this.state.cellphone,
                'id_ambassador': user.id,
                'nro_document': this.state.nro_doc,
                'type_document': this.state.selectedTypeDocOption.value,
                'id_user': this.state.id_referred,
            }

            ApiService.updateRefered(user.token,data_user).then(
            (res) => {
                if(res){
                    if(res && res.status == 'success'){
                        document.getElementById("add-referred-overlay").style.display = "none";
                        Swal.fire({
                            icon: 'success',
                            title: 'Hecho!',
                            text: 'Los cambios se han solicitado al administrador. Se verán reflejados en máximo 24 horas.',
                        });
                        this.getUserAddresses();
                    }else{
                    }
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
            this.setState({
              loading: false
            });
          }
        
      }


    handleRegisterReferred(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
          });
      
          this.form.validateAll();

        if(!this.state.selectedTypeDocOption){
            this.setState({
                errors: {referred : "Error"}
            }) 
            return true;
        }else{
            this.setState({
                errors: {referred : ""}
            }) 
        }
       
        if (this.checkBtn.context._errors.length === 0 && !this.state.errors.referred) {
            //this.setState({ open: false });

            var user = AuthService.getCurrentUser();

            let data_user = {
                'name': this.state.name,
                'last_name': this.state.last_name,
                'email': this.state.email,
                'cell_phonenumber': this.state.cellphone,
                //'password': this.state.password,
                'id_ambassador': user.id,
                'nro_document': this.state.nro_doc,
                'type_document': this.state.selectedTypeDocOption.value,
                'role': 'REFERIDO',
                'status': 'DESACTIVADO',
            }
            
            ApiService.postUser(user.token,data_user).then(
            (res) => {
                if(res){
                    if(res && res.status == 'success'){
		                document.getElementById("add-referred-overlay").style.display = "none";
                        Swal.fire({
                            icon: 'success',
                            title: 'Hecho!',
                            text: 'El registro se ha solicitado al administrador. Se verán reflejados en máximo 24 horas.',
                        });
                        this.getUserReferreds();
                        
                    }else{
                    }
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
            this.setState({
              loading: false
            });
          }
        
      } 


    onChangeAddress(e) {
        this.setState({
            address: e.target.value
        });
      }

    /*onChangeUbigeo(e) {
        this.setState({
            ubigeo: e.target.value
        });
      }*/

    onChangeNumber(e) {
        this.setState({
            number: e.target.value
        });
      }

    onChangeInterior(e) {
        this.setState({
            interior: e.target.value
        });
      }

    onChangeUrl(e) {
        this.setState({
            url_address: e.target.value
        });
      }

      onChangeReference(e) {
        this.setState({
            reference: e.target.value
        });
      }

      onchangeName(e) {
        this.setState({
            name: e.target.value
        });
      }

      onchangeLastName(e) {
        this.setState({
            last_name: e.target.value
        });
      }

      onchangeEmail(e) {
        this.setState({
            email: e.target.value
        });
      }
      
      onChangeCellphone(e) {
        //console.log('cambio numero', e.target.value);
        let val = e.target.value
        let max = 9
        let vale_lenght = val.toString().length
        
        //console.log('val.toString().length', val.toString().length);
        //console.log('vale_lenght', vale_lenght, max);
        //console.log('this.state.cellphone', this.state.cellphone);
        if(vale_lenght <= max){
            this.setState({
                cellphone: e.target.value
            });
        }else{
            this.setState({
                cellphone: this.state.cellphone
            });
        }

        /*this.setState({
            cellphone: e.target.value
        });*/
      }

      onChangeNroDoc(e) {

        let val = e.target.value
        let max = 12
        let vale_lenght = val.toString().length
        
        //console.log('val.toString().length', val.toString().length);
        //console.log('vale_lenght', vale_lenght, max);
        //console.log('this.state.cellphone', this.state.cellphone);
        if(vale_lenght <= max){
            this.setState({
                nro_doc: e.target.value
            });
        }else{
            this.setState({
                nro_doc: this.state.nro_doc
            });
        }
        
      }

      onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
      }

    handleRegister(e) {
        //e.preventDefault();
    
        //this.form.validateAll();
            var user = AuthService.getCurrentUser();
            let data_user = {
                'address': this.state.address,
                'ubigeo': this.state.ubigeo,
                'url_google': this.state.url_address,
                //'reference': this.state.reference,
                'id_user': user.id,
            }

            ApiService.postAddressUser(user.token,data_user).then(
            (res) => {
                if(res){
                    if(res && res.status == 'success'){
                        this.props.history.push("/welcome");
                    }else{
                    }
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        
      }

      removeReferred = (item) => {
        var user = AuthService.getCurrentUser();
        Swal.fire({
            title: 'Confirmar',
            text: '¿Desea elminar el referido ' + item.name + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
         }).then((result) => {
            if(result.value){

                var user = AuthService.getCurrentUser();
                ApiService.deletetUserRefered(user.token,user.id,item.id).then(
                    (res) => {
                        if(res && res.status == 'success'){
                            Swal.fire({
                                icon: 'success',
                                title: 'Hecho!',
                                text: 'Referido Eliminado!',
                            });
                            this.getUserReferreds();
                        }
                    },
                    error => {
                      const resMessage =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                  );
           }
         })
        
        
    } 

    editReferred = (item) => {
        //var user = AuthService.getCurrentUser();
        //console.log(item);
        this.setState({id_referred: item.id?item.id:''});
        this.setState({name: item.name?item.name:''});
        this.setState({last_name: item.last_name?item.last_name:''});
        this.setState({email: item.email?item.email:''});
        this.setState({cellphone: item.Profile.cell_phonenumber?item.Profile.cell_phonenumber:''});
        this.setState({nro_doc: item.nro_document?item.nro_document:''});
        
        for (let i = 0; i < this.state.dataTipo.length; i++){
            if(this.state.dataTipo[i]['value'] === item.type_document){
                this.handleTypeDoc(this.state.dataTipo[i])
            }
          }
        this.setState({ action: 'update' });
        document.getElementById("add-referred-overlay").style.display = "block";
        //this.onOpenModalEditReferred();
        //this.setState({ open: true });
    } 

    registerReferred = (e) => {
        e.preventDefault();
        this.setState({id_referred: ""});
        this.setState({name: ""});
        this.setState({last_name: ""});
        this.setState({email: ""});
        this.setState({cellphone: ""});
        this.setState({nro_doc: "99998888"});
        this.setState({action: 'register' });
        document.getElementById("add-referred-overlay").style.display = "block";
    }

    onCloseModal = () => {
        this.setState({ open: false });
    };

    

    onOpenModalHistory() {
        document.getElementById("history-overlay").style.display = "block";
    }
	
	onCloseModalHistory() {
		document.getElementById("history-overlay").style.display = "none";
    }
	
	onCloseModalAddAddress() {
		document.getElementById("add-referred-overlay").style.display = "none";
    }

    render (){
        const {cartItems, symbol, total} = this.props;
        const { selectedTypeDocOption } = this.state;

        document.body.classList.add('dark');

        const customStyles = {
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                // const color = chroma(data.color);
                //console.log({ data, isDisabled, isFocused, isSelected });
                return {
                  ...styles,
                  backgroundColor: isFocused ? "#161616" : "#161616",
                  color: isFocused ? "#F8EEE1" : "#F8EEE1"
                };
              },
            placeholder: (defaultStyles) => {
                return {
                    ...defaultStyles,
                    position: 'absolute',
                    display: 'inherit',
                    paddingLeft: '2px',
                }
            },
            control: (base, state) => ({
              ...base,
              background: "#161616",
              //opacity: 0.5,
              border: "2px solid #F8EEE1",
              //color: "#cfd4da",
              borderRadius: 30,
              backgroundColor: state.isFocused ? "#161616" : null,
              
            }),
            singleValue: (base, state) => ({
                ...base,
                color: '#F8EEE1',
                position: 'absolute',
                display: 'inherit',
                paddingLeft: '2px'
            }),
            input: (base, state) => ({
                ...base,
                position: 'absolute',
                color: '#F8EEE1'
            }),
            valueContainer: (base, state) => ({
                ...base,
                minHeight: 'inherit',
            }),
          };

        return (

            <div className="cart-container" style={{backgroundColor: '#161616 !important'}}>
                <div className="breadcrumb-section title-cart profile-tittle">
                <div className="container">
                    <div className="row">
                            <div className="col-md-12">
                                <div className="page-title page-tittle2">
                                    {/*<h1>{title}</h1>*/}
                                    <label className="text-confirm-order">MANEJA TUS REFERIDOS</label>
                                </div>
                            </div>
                    </div>
                </div>
                </div>

                <section className="section-b-space" style={{paddingTop: '0px'}}>
                    <div className="container padding-cls">
                        <div className="checkout-page container-profile">
                            <div className="checkout-form profile-content">
                                    <div className="checkout row container-data-profile" style={{margin: 'auto'}}>
                                        <div className="col-lg-12 col-sm-12 col-xs-12">
                                            <div className="profile-data">
                                                
                                                <div className="row check-out row-check-date">
                                                {this.state.data_ref.map((item, index) => {
                                                    return (
                                                        <div key={index} className="form-group col-md-6 col-sm-6 col-xs-12 profile-input">
                                                            <div className="input-container">
                                                            <div className="field-label ver-label">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver-user.png`} alt="" className="img-fluid"/> Referido {index+1}:</div>
                                                            <input type="text" name="name" value={item.name + ' ' + item.last_name} readOnly/>
                                                            <span className="fa fa-pencil btn-edit-profile" style={{right: '25px'}} onClick={() => this.editReferred(item)}></span>
                                                            {/*<span className="fa fa-times btn-edit-profile" onClick={() => this.removeReferred(item)}></span>*/}
                                                            </div>
                                                        </div> 
                                                        )
                                                })}
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        {
						AuthService.getCurrentUser() && AuthService.getCurrentUser().role=='EMBAJADOR' || AuthService.getCurrentUser() && AuthService.getCurrentUser().role=='CLIENTE'?
                        <div>
                            <div className="row check-out row-check-date" style={{textAlign: 'center',marginTop: '1.5rem'}}>
                                <div className="form-group col-md-12 col-sm-6 col-xs-12 profile-buttons">
                                    <a href="#" onClick={this.registerReferred} className="ver-btn-solid btn btn-save-address btn-primary">
                                        {/*{this.state.loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}*/}
                                        AGREGAR REFERIDO</a>
                                </div>
                            </div>
                        </div>
                        :''}
                    </div>
                </section>

                <div id="history-overlay" className="search-overlay history-overlay">
                    <div>
                        <span className="closebtn" onClick={this.onCloseModalHistory} title="Close Overlay">×</span>
                        <div className="overlay-content modal-profile-overlay-content">
                            <div className="container">
                                <div className="row" style={{marginBottom: '2rem'}}>
                                    <div className="col-md-12">
                                        <div className="page-title title-modal-profile">
                                            {/*<h1>{title}</h1>*/}
                                            <label className="text-confirm-order">HISTORIAL DE PEDIDOS</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="container padding-cls">
                                        <div className="checkout-page">
                                            <div className="checkout-form register-address-form">
                                                <div className="checkout row container-data-profile" style={{margin: 'auto'}}>
                                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                                        <div className="row">
                                                        {
                                                        this.state.data_orders.map((item, index) => {
                                                            return (
                                                            <Collapsible key={index} trigger={<div style={{display:'flex'}}><span><i className="fa fa-circle" aria-hidden="true"></i>PEDIDO #{item.id}</span><span style={{position: 'absolute', right: '40px'}}>{item.date}</span></div>} >
                                                                <div className="order-history-details">
                                                                <div>
                                                                { 
                                                                    item.Purchases_detail.map((sub_item, subindex) =>
                                                                    <p key={`sub_item_name`+subindex}>
                                                                        {sub_item.Product.name}
                                                                    </p>
                                                                    )
                                                                }
                                                                </div>
                                                                <div>
                                                                    <p>Total</p>
                                                                    <p><img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{position: 'relative', bottom: '2px'}}/> {item.total}</p>
                                                                </div>
                                                                </div>
                                                            </Collapsible>
                                                            )
                                                        })}
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
							</div>
                        </div>
                    </div>
                </div>

                <div id="add-referred-overlay" className="ver-modal-overlay add-referred-overlay" style={{overflow: 'auto'}}>
                    <div>
                        <span className="closebtn" onClick={this.onCloseModalAddAddress} title="Close Overlay">×</span>
                        <div className="overlay-content" style={{position: 'absolute', top: '5.5rem'}}>
                            <div className="container" style={{padding: '0px 30px 0px 20px'}}>
                                <div className="row" style={{marginBottom: '2rem'}}>
                                    <div className="col-md-12">
                                        <div className="page-title title-modal-profile">
                                            {/*<h1>{title}</h1>*/}
                                            <label className="text-confirm-order">{this.state.action=='register'?'AGREGA A TU REFERIDO':'ACTUALIZA A TU REFERIDO'}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="container padding-cls">
                                        <div className="checkout-page">
                                            <div className="checkout-form register-address-form">
                                                <div className="checkout row container-data-address" style={{margin: 'auto'}}>
                                                    <div className="col-lg-12 col-sm-12 col-xs-12">
                                                        <div className="row">
                                                        
                                                        <Form
                                                            style={{width: '100%'}}
                                                            //onSubmit={this.handleRegisterReferred} 
                                                            onSubmit={this.state.action=='register'?this.handleRegisterReferred:this.handleUpdateReferred}
                                                            autoComplete="off"
                                                            encType="multipart/form-data" 
                                                            method="POST"
                                                            ref={c => {this.form = c;
                                                        }}>
                                                            {/*<div className="referred-checkout-title">
                                                                <label>Datos del referido</label>
                                                            </div>*/}
                                                            <div className="row check-out row-check-date" style={{marginBottom: '1rem'}}>
                                                                <Input type="text" name="id_referred" value={this.state.id_referred} hidden="1"/>
                                                                <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                                    <div className="field-label">NOMBRES</div>
                                                                    <Input type="text" name="address" value={this.state.name} onChange={this.onchangeName} validations={[required]}/>
                                                                    {/*{this.validator.message('name', this.state.address, 'required|alpha')}*/}
                                                                </div>
                                                            </div>
                                                            <div className="row check-out row-check-date" style={{marginBottom: '1rem'}}>
                                                                <Input type="text" name="id_referred" value={this.state.id_referred} hidden="1"/>
                                                                <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                                    <div className="field-label">APELLIDOS</div>
                                                                    <Input type="text" name="address" value={this.state.last_name} onChange={this.onchangeLastName} validations={[required]}/>
                                                                    {/*{this.validator.message('name', this.state.address, 'required|alpha')}*/}
                                                                </div>
                                                            </div>
                                                            <div className="row check-out row-check-date" style={{marginBottom: '1rem'}}>
                                                                <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                                    <div className="field-label">MAIL</div>
                                                                    <Input type="text" name="address" value={this.state.email} onChange={this.onchangeEmail} validations={[required]}/>
                                                                    {/*{this.validator.message('name', this.state.address, 'required|alpha')}*/}
                                                                </div>
                                                            </div>
                                                            <div className="row check-out row-check-date" style={{marginBottom: '1rem'}}>
                                                                <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                                    <div className="field-label">CELULAR</div>
                                                                    <Input type="number" name="address" value={this.state.cellphone} 
                                                                    onChange={ (e) => this.onChangeCellphone(e)} validations={[required]}/>

                                                                    {/*{this.validator.message('name', this.state.address, 'required|alpha')}*/}
                                                                </div>
                                                            </div>
                                                            <div className="row check-out" style={{marginBottom: '1rem', display: 'none'}}>
                                                                <div className="form-group col-md-4 col-sm-4 col-xs-12" style={{maxWidth: '100%', flex: 'auto'}}>
                                                                    <div className="field-label" style={{marginBottom: '10px'}}>TIPO DE DOCUMENTO</div>
                                                                    <Select
                                                                        value={selectedTypeDocOption}
                                                                        onChange={this.handleTypeDoc}
                                                                        options={this.state.dataTipo}
                                                                        placeholder={'Seleccione un documento'}
                                                                        styles={customStyles}
                                                                    />
                                                                    {/*
                                                                        this.state.errors.referred && !this.state.selectedTypeDocOption?
                                                                        <div className="alert alert-danger ver-alert" role="alert" >Este campo es obligatorio!</div>
                                                                    :'' */}
                                                                </div>
                                                            </div>
                                                            <div className="row check-out row-check-date" style={{marginBottom: '1rem', display: 'none'}}>
                                                                <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                                    <div className="field-label">NRO. DE DOCUMENTO</div>
                                                                    <Input type="number" name="address" value={this.state.nro_doc} onChange={ (e) => this.onChangeNroDoc(e)} /*validations={[required]}*/ />
                                                                </div>
                                                            </div>
                                                           
                                                            <div className="row check-out row-check-date" style={{textAlign: 'center',marginTop: '2rem'}}>
                                                                <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                                <button className="ver-btn-solid btn btn-save-address btn-primary">
                                                                {/*{this.state.loading && (
                                                                    <span className="spinner-border spinner-border-sm"></span>
                                                                )}*/}
                                                                    GUARDAR</button>
                                                                </div>
                                                            </div>
                                                            {this.state.message && (
                                                                <div className="form-group">
                                                                    <div className="alert alert-danger" role="alert">
                                                                        {this.state.message}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <CheckButton
                                                                style={{ display: "none" }}
                                                                ref={c => {
                                                                    this.checkBtn = c;
                                                                }}
                                                            />
                                                        </Form>

                                                    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
							</div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    {removeFromWishlist}
)(VerReferreds)