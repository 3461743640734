import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IntlActions } from 'react-redux-multilingual';
import Pace from 'react-pace-progress';

// Import custom components
import store from '../../../store';
import NavBar from './common/navbar';
import SideBar from './common/sidebar';
import CartContainerHear from '../../../containers/CartContainer';
import PurchaseReferedContainer from '../../../containers/PurchaseReferedContainer';
import TopBar from './common/topbar';
import UserContainer from '../../../containers/UserContainer';
import LogoImage from './common/logo';
import { changeCurrency } from '../../../actions';
import { connect } from 'react-redux';
import NavBar_Categ from './common/navbar_categ';
import { withRouter } from 'react-router-dom';
import { getCartTotal } from '../../../services';
import AuthService from '../../services/auth.service';
import ApiService from '../../services/api.service';
import IdleTimer from 'react-idle-timer';

import {
  addToCart,
  addToWishlist,
  addToCompare,
  incrementQty,
  decrementQty,
  removeFromCart,
} from '../../../actions';
import apiService from '../../services/api.service';

class HeaderMarket extends Component {
  constructor(props) {
    super(props);
    this.handlerLogout = this.handlerLogout.bind(this);
    this.checkCart = this.checkCart.bind(this);
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.state = {
      isLoading: false,
      changeBackgroundColor: this.props.changeBackgroundColor,
      transparentBackground: this.props.transparentBackground,
      homeView: this.props.homeView,
      changeClass: this.props.changeClass,
      data_categ_parents: [],
      styleDebt: 'none',
      totalDebt: 0,
    };
  }
  /*=====================
         Pre loader
         ==========================*/
  componentDidMount() {
    //console.log('this.props', this.props.location , "/welcome");
    setTimeout(function() {
      document.querySelector('.loader-wrapper').style = 'display: none';
    }, 2000);

    this.setState({ open: true });
  }

  /*componentDidUpdate(prevProps, prevState){
		//console.log('prevProps',prevProps)
        //console.log('prevState',prevState)
    }*/

  componentWillMount() {
    if (!AuthService.getCurrentUser()) {
      this.props.history.push('/login');
    } else {
      this.getCategParent();
      //this.getDebt();
    }
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    //this.setState({ styleDebt: 'none' });

    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let number =
      window.pageXOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    /*if (number >= 300) {
            if (window.innerWidth < 576) {
                document.getElementById("sticky").classList.remove('fixed');
            }else
            	document.getElementById("sticky").classList.add('fixed');
        } else {
            document.getElementById("sticky").classList.remove('fixed');
        }*/
  };

  // async getDebt() {
  //   var user = AuthService.getCurrentUser();

  //   //document.getElementById('has-debt').style.display = 'block';

  //   const debt = await apiService.getSUmDebt(user.token);

  //   if (debt.totalDebt > 0) {
  //     this.setState({ styleDebt: 'block', totalDebt: debt.totalDebt });
  //   }

  //   console.log(debt);
  // }

  handlerLogout() {
    AuthService.logout();
  }

  changeLanguage(lang) {
    store.dispatch(IntlActions.setLocale(lang));
  }

  openNav() {
    var openmyslide = document.getElementById('mySidenav');
    if (openmyslide) {
      openmyslide.classList.add('open-side');
    }
  }
  openSearch() {
    document.getElementById('search-overlay').style.display = 'block';
  }

  closeSearch() {
    document.getElementById('search-overlay').style.display = 'none';
  }

  openOptions(e) {
    e.preventDefault();
    document.getElementById('options-overlay').style.display = 'block';
  }

  closeOptions() {
    document.getElementById('options-overlay').style.display = 'none';
  }

  openLogout(e) {
    e.preventDefault();
    document.getElementById('options-overlay').style.display = 'none';
    document.getElementById('logout-overlay').style.display = 'block';
  }

  closeLogout() {
    document.getElementById('logout-overlay').style.display = 'none';
  }

  closeEmpty() {
    document.getElementById('empty-overlay').style.display = 'none';
  }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false });
    });
  };

  renderResults() {
    let userData = AuthService.getCurrentUser();
    //console.log('renderResults getCurrentUser',userData);
    return (
      <>
        {userData && userData.role == 'EMBAJADOR' ? (
          <PurchaseReferedContainer logo={this.props.logoName} />
        ) : (
          ''
        )}
        <CartContainerHear
          logo={this.props.logoName}
          checkCart={this.checkCart}
        />
      </>
    );
  }

  checkCart = (flag) => {
    if (flag == 1) {
      if (AuthService.getCurrentUser() && AuthService.getCurrentUser().role) {
        if (AuthService.getCurrentUser().role == 'REFERIDO') {
          this.props.history.push('/cart');
        } else {
          this.props.history.push('/user-checkout');
        }
      } else {
        this.props.history.push('/cart');
      }
    } else {
      document.getElementById('empty-overlay').style.display = 'block';
    }
  };

  getCategParent() {
    var user = AuthService.getCurrentUser();
    ApiService.getCategParents(user.token, 0).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('data',res.data);
          //this.setState({ data_categ_parents: this.showImage(res.data) });
          this.setState({ data_categ_parents: res.data });
          //console.log(this.state.data_categ_parents);
          //this.setState({ order_results:res.data });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  handleOnAction(event) {
    //console.log('user did something', event)
  }

  handleOnActive(event) {
    //console.log('user is active', event)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle(event) {
    //console.log('user is idle', event)
    //console.log('last active', this.idleTimer.getLastActiveTime())
    AuthService.logout();
    this.props.history.push('/login');
  }

  render() {
    const user = AuthService.getCurrentUser();
    const ColoredLine = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          height: 2,
          width: '75%',
          marginTop: '0px',
          marginBottom: '32px',
        }}
      />
    );

    this.state.changeClass = this.props.changeClass;
    this.state.changeBackgroundColor = this.props.changeBackgroundColor;

    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * 20}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        {/*console.log(this.state)*/}
        <header
          id="sticky"
          className={`sticky header-welcome ${
            this.state.changeClass ? this.state.changeClass : ''
          }`}
        >
          {/*<header id="sticky" className={`sticky header-welcome ${this.state.changeBackgroundColor === true? 'header_products' : this.state.transparentBackground === true? 'transparent_header' : ''}`} >*/}
          {this.state.isLoading ? <Pace color="#27ae60" /> : null}
          {/*<div className="mobile-fix-option"></div>*/}
          {/*Top Header Component*/}
          {/*<TopBar/>*/}

          <div className="container container-header">
            <div className="row">
              <div className="col-sm-12 col-menu-m">
                <div className="main-menu menu-mh nheader_welcome">
                  <div className="menu-left">
                    <div className="brand-logo page-logo">
                      <LogoImage logo={this.props.logoName} />
                    </div>
                  </div>
                  <div
                    className="menu-right pull-right"
                    style={{ zIndex: '10001' }}
                  >
                    {/*Top Navigation Bar Component*/}
                    {/*<NavBar/>*/}
                    <div>
                      <div className="icon-nav" style={{ zIndex: '10001' }}>
                        <ul>
                          {/*<li className="onhover-div mobile-icon">
														<div><img src={`${process.env.PUBLIC_URL}/assets/images/icon/gift.png`} className="img-fluid" alt="" />
														</div>
													</li>*/}
                          {/*Header Cart Component */}
                          {/*<CartContainerHear  key={1} cartList={this.cartList}  total={this.total} symbol={this.symbol}/>*/}
                          {/*<li className="onhover-div ">
														{this.props.logoName=='ver_logo.png'?
														<div><img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_search_1.png`} onClick={this.openSearch} className="img-fluid" alt="" />
															</div>
														:
														<div><img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_search_2.png`} onClick={this.openSearch} className="img-fluid" alt="" />
															</div>
														}
													</li>*/}
                          {this.renderResults()}

                          {/*<UserContainer
            											handlerLogout = {this.handlerLogout}
													/>*/}
                          {/*<li className="onhover-div mobile-search">*/}
                        </ul>
                      </div>
                    </div>
                    {this.props.showNav ? (
                      <div
                        id="right-navbar"
                        className={`right-navbar ${
                          this.state.changeBackgroundColor === true
                            ? 'bar-products'
                            : this.state.homeView === true
                            ? 'home-products'
                            : ''
                        }`}
                      >
                        <div className="navbar">
                          <a href="#" onClick={this.openOptions}>
                            <div className="bar-style">
                              {this.props.logoName == 'ver_logo.png' ? (
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_menu_1.png`}
                                  className="img-fluid"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_menu_2.png`}
                                  className="img-fluid"
                                  alt=""
                                />
                              )}
                              {/*<i className="fa fa-bars sidebar-bar" aria-hidden="true"></i>*/}
                            </div>
                          </a>
                          {/*SideBar Navigation Component*/}
                          <SideBar />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div id="search-overlay" className="search-overlay">
          <div>
            <span
              className="closebtn"
              onClick={this.closeSearch}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <form>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Search a Product"
                        />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        <i className="fa fa-search"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="options-overlay" className="search-overlay options-overlay">
          <div>
            <span
              className="closebtn"
              onClick={this.closeOptions}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content options-overlay-content">
              <div className="container">
                <div className="row">
                  <div className="form-group bar-options">
                    <Link
                      to={`${process.env.PUBLIC_URL}/welcome`}
                      onClick={this.closeOptions}
                      className={
                        this.props.location.pathname == '/welcome'
                          ? 'bar-option-home'
                          : ''
                      }
                      data-lng="en"
                    >
                      HOME
                    </Link>
                  </div>
                </div>

                {this.state.data_categ_parents.map((item, index) => {
                  //console.log('item',item);
                  var urlTemplate = '';
                  if (item.template == 'TEMPLATE1') {
                    urlTemplate = 'products/' + item.id;
                  } else if (item.template == 'TEMPLATE2') {
                    urlTemplate = 'categProducts/' + item.id;
                  } else {
                    urlTemplate = 'accesories/' + item.id;
                  }
                  return (
                    <div className="row" key={index}>
                      <div className="form-group bar-options">
                        <Link
                          to={`${process.env.PUBLIC_URL}/${urlTemplate}`}
                          className={
                            this.props.location.pathname == '/' + urlTemplate
                              ? 'bar-option-home'
                              : ''
                          }
                          onClick={this.closeOptions}
                          data-lng="en"
                        >
                          {item.name}
                        </Link>
                      </div>
                    </div>
                  );
                })}

                {/*<div className="row">
									<div className="form-group bar-options">
										<Link to={`${process.env.PUBLIC_URL}/categProducts`} onClick={this.closeOptions} data-lng="en">OTROS</Link>		
									</div>
                            	</div>
								<div className="row">
									<div className="form-group bar-options">
										<Link to={`${process.env.PUBLIC_URL}/accesories`} onClick={this.closeOptions} data-lng="en">ACCESORIOS</Link>				
									</div>
										</div>*/}

                <ColoredLine color="#F8EEE1" />
                <div className="row">
                  <div className="form-group bar-options">
                    <Link
                      to={`${process.env.PUBLIC_URL}/profile`}
                      className={
                        this.props.location.pathname == '/profile'
                          ? 'bar-option-home'
                          : ''
                      }
                      onClick={this.closeOptions}
                      data-lng="en"
                    >
                      MI PERFIL
                    </Link>
                  </div>
                </div>
                {/*{
								AuthService.getCurrentUser() && AuthService.getCurrentUser().role=='EMBAJADOR'?
								<div>
									<div className="row">
										<div className="form-group bar-options">
											<Link to={`${process.env.PUBLIC_URL}/register-referred`} onClick={this.closeOptions} data-lng="en">Registrar referidos</Link>			
										</div>
									</div>
									<div className="row">
										<div className="form-group bar-options">
											<Link to={`${process.env.PUBLIC_URL}/register-address`} onClick={this.closeOptions} data-lng="en">Registrar dirección</Link>			
										</div>
									</div>
								</div>
								:
								''
								}*/}
                <div className="row">
                  <div className="form-group bar-options">
                    <a href="#" onClick={this.openLogout} data-lng="en">
                      CERRAR SESIÓN
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="logout-overlay" className="search-overlay logout-overlay">
          <div>
            <span
              className="closebtn"
              onClick={this.closeLogout}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content options-overlay-content">
              <div className="container">
                <div style={{ marginBottom: '2.5rem' }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/logout_info.png`}
                    className="img-fluid"
                    alt=""
                    style={{ display: 'block', margin: '0 auto' }}
                  />
                </div>
                <div>
                  <label className="logout-text1">
                    ¿Estás seguro de cerrar tu sesión?
                  </label>
                  <label className="logout-text2">
                    ¡No te pierdas nuestros nuevos productos!
                  </label>
                </div>
                <div className="row div-logout-button">
                  <Link
                    to={`${process.env.PUBLIC_URL}/login`}
                    onClick={() => this.handlerLogout()}
                    className="btn btn-solid btn-logout"
                  >
                    SALIR
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="empty-overlay" className="search-overlay empty-overlay">
          <div>
            <span
              className="closebtn"
              onClick={this.closeEmpty}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content options-overlay-content">
              <div className="container">
                <div style={{ marginBottom: '2.5rem' }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icon/car_empty.png`}
                    className="img-fluid"
                    alt=""
                    style={{ display: 'block', margin: '0 auto' }}
                  />
                </div>
                <div>
                  <label className="logout-text1">
                    Tu carrito de compras está <br /> vacío
                  </label>
                  <label className="logout-text2">
                    Revisa nuestros productos, <br /> Seguro encontrarás algo{' '}
                    <br /> que te guste ;)
                  </label>
                </div>
                <div className="row div-logout-button">
                  <Link
                    to={`${process.env.PUBLIC_URL}/welcome`}
                    onClick={() => this.closeEmpty()}
                    className="btn btn-solid btn-explore"
                  >
                    EXPLORAR
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(HeaderMarket);
/*const mapStateToProps = (state, ownProps) => ({
    symbol: state.data.symbol
}) */

/*export default connect(null,
    { changeCurrency }
)(HeaderMarket);*/

/*export default connect(mapStateToProps,
    {
        addToCart,
        addToWishlist,
        addToCompare,
        incrementQty,
        decrementQty,
        removeFromCart
    }) (HeaderMarket);*/
