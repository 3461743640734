import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    INCREMENT_QTY,
    DECREMENT_QTY,
    REMOVE_CART,
    GET_CART_USER } from "../constants/ActionTypes";


export default function cartReducer(state = {
    cart: []
}, action) {
    
    //console.log('action',action);
    
    const productId = action && action.product && action.product.id ? action.product.id : 0
    switch (action.type) {
        case GET_CART_USER:
            
            //console.log('GET_CART_USER',action);
            //const productId = action.product.id
            //if (state.purchaseRefered.findIndex(product => product.id === productId) !== -1) {
                const cart = action.product.reduce((cartAcc, product) => {
                    /*if (product.id === productId) {
                        purchaseReferedAcc.push({ ...product, qty: product.qty+1, sum: (product.price*product.discount/100)*(product.qty+1) }) // Increment qty
                    } else {*/
                       // purchaseReferedAcc = product
                       cartAcc.push(product)
                   // }

                    return cartAcc
                }, [])

                return { ...state, cart }
            //}

            //return { ...state, purchaseRefered: [...state.purchaseRefered, { ...action.product, qty: action.qty, sum: (action.product.price*action.product.discount/100)*action.qty }] }

        
        case ADD_TO_CART:
            //console.log('ADD_TO_CART');
            //const productId = action.product.id
            if (state.cart.findIndex(product => product.id === productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    /*if (product.id === productId) {
                        cartAcc.push({ ...product, qty: product.qty+action.qty, sum: (product.price*product.discount/100)*(product.qty+action.qty) }) // Increment qty
                    } else {*/
                        cartAcc.push(product)
                    //}

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            return { ...state, cart: [...state.cart, { ...action.product, qty: action.qty, sum: (action.product.price*action.product.discount/100)*action.qty }] }

        case INCREMENT_QTY:
            //console.log('INCREMENT_QTY');
            //const productId = action.product.id
            //console.log('state.cart', state.cart);
            //console.log('action.productId',  action.productId);

            if (state.cart.findIndex(product => product.id === action.productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    //console.log('cartAcc, produc',cartAcc, product);
                    if (product.id === action.productId) {
                        cartAcc.push({ ...product, quantity: product.quantity+1, sum: (product.price*product.discount/100)*(product.quantity+1) }) // Increment qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            //return { ...state, cart: [...state.cart, { ...action.product, qty: action.qty, sum: (action.product.price*action.product.discount/100)*action.qty }] }
            return { ...state, cart: [...state.cart, { ...action.product, quantity: action.quantity, sum: 0 }] }

        case DECREMENT_QTY:
            //console.log('DECREMENT_QTY');
            
            if (state.cart.findIndex(product => product.id === action.productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === action.productId && product.quantity > 1) {
                        //console.log('price: '+product.price+'Qty: '+product.qty)
                        cartAcc.push({ ...product, quantity: product.quantity-1, sum: (product.price*product.discount/100)*(product.quantity-1) }) // Decrement qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            return { ...state, cart: [...state.cart, { ...action.product, quantity: action.quantity, sum: 0 }] }

        case REMOVE_FROM_CART:
            return {
                cart: state.cart.filter(item => item.id !== action.product_id.id)
            }
        case REMOVE_CART:
                return {
                    cart: []
                }

        default:
    }
    return state;
}
