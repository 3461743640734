import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import AuthService from "../../services/auth.service";

class BannersList extends Component {

    constructor(props){
        super(props);
        this.state = {
            open: false,
            image: '',
        }
    }

    handleImageLoaded() {
        //console.log('loaded!')
        document.getElementsByClassName("page-loader")[0].style.display = "none";
        //this.setState({ imageStatus: "loaded" });
      }

    stopLoading() {
        //console.log('loaded!')
        document.getElementsByClassName("page-loader")[0].style.display = "none";
        //this.setState({ imageStatus: "loaded" });
      }

    render() {
        const {product, qty, symbol, onAddToCartClicked, BuynowClicked, onAddToWishlistClicked, onAddToCompareClicked, categoria, indice,cant_categ} = this.props;
        var background_image = '';
        let RatingStars = []

        for(var i = 0; i < product.rating; i++) {
            RatingStars.push(<i className="fa fa-star" key={i}></i>)
        }
        //var ruta = AuthService.getCurrentUser() && AuthService.getCurrentUser().role ? (AuthService.getCurrentUser().role == 'REFERIDO' ? 'cart' : 'user-checkout') : 'cart';
        let url_image = `${process.env.REACT_APP_URL_FILES}images/banners/`;
        return (
                <div>
                    <div className="home home-list-banners">
                        <div className="container">
                            <div>
                                <div className="row_banner">
                                    <div className="background-img-banner">
                                        {product && product.image ?<a href={`${product.url?product.url:"#"}`} target={`${product.url?"_blank":""}`}><img src={url_image + product.image} /></a> :''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default BannersList;