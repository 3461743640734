import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Slider from 'react-slick';
import ig from 'fetch-instagram';

import {Slider5, Slider7} from "../../../services/script"

class Categorias extends Component {

    constructor (props) {
        super (props)

        this.state = {
            InstaData: []
        }
    }

    componentWillMount() {
        const instagram = ig({
            accessToken: '8295761913.aa0cb6f.2914e9f04dd343b8a57d9dc9baca91cc',
        });

        const users = instagram.media();
        users.then(res => this.setState({InstaData:res.data}));

    }

    render (){

        const {InstaData} = this.state;
        const {type} = this.props;

        var Sliders = {};
        if(type === 'watch'){
            Sliders = Slider5;
        }else{
            Sliders = Slider7;
        }

        //console.log(this.props.rowData)


        return (
            <div className="section-categories">
                <div className="container-section-categ">
                    <div className="row row-section-categ">

                        {this.props.rowData.map((item,index) => (
                            <div className="p-0 btn-categ-prod">
                            <Link to={`${process.env.PUBLIC_URL}/cart`} key={index} className="btn btn-full" >{item.name}</Link> 
                            </div>
                        ))}

                        {/*<div className="p-0 btn-categ-prod">
                            <Link to={`${process.env.PUBLIC_URL}/cart`} className="btn btn-full" >ACEITE</Link> 
                        </div>
                        <div className="p-0 btn-categ-prod">
                            <Link to={`${process.env.PUBLIC_URL}/cart`} className="btn btn-full" >HONGOS</Link> 
                        </div>
                        <div className="p-0 btn-categ-prod">
                            <Link to={`${process.env.PUBLIC_URL}/cart`} className="btn btn-full" >WAX</Link>   
                        </div>
                        <div className="p-0 btn-categ-prod">
                            <Link to={`${process.env.PUBLIC_URL}/cart`} className="btn btn-scroll" >...</Link>      
                        </div>*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default Categorias;