import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import {Link, Redirect } from 'react-router-dom'
import PaypalExpressBtn from 'react-paypal-express-checkout';
import SimpleReactValidator from 'simple-react-validator';

import Breadcrumb from "../common/breadcrumb";
import {removeFromWishlist, getFromPurchaseRefered,getFromCartUser} from '../../actions'
import {getCartTotal} from "../../services";
import Select from 'react-select';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import authService from '../services/auth.service';
import store from '../../store';

class VerThanks extends Component {

    constructor (props) {
        super (props)
        this.state = {
            cash:0
        }
    }

    componentWillMount() {
        //this.getRefOrders();
        this.getCash();
    }

    getRefOrders(){
        var user = AuthService.getCurrentUser();
        ApiService.getReferredOrders(user.token,user.token_order,user.id).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('getRefOrders data',res);

                    store.dispatch(getFromPurchaseRefered(res.data))
                    store.dispatch(getFromCartUser(res.carts_data))
                    
                    //this.setState({ order_results:res.data });
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    getCash(){
        var user = AuthService.getCurrentUser();
        AuthService.getCash(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('getCash data',res.data);
                    let cash_amount = res && res.data && res.data.amount_cash  ? Math.round(res.data.amount_cash*100)/100 : 0
                    this.setState({ cash: cash_amount });
                    
                    //console.log(thGERALSSSis.state.data_categ_parents);
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    render (){
        const {cartItems, symbol, total} = this.props;
        document.body.classList.add('dark');
        return (

            <div className="cart-container" style={{backgroundColor: '#161616 !important'}}>
                <div className="breadcrumb-section title-cart" style={{paddingBottom: '20px'}}>
                <div className="container">
                    <div className="row">
                            <div className="col-md-12">
                                <div className="page-title page-tittle2">
                                    {/*<h1>{title}</h1>*/}
                                    <div style={{marginBottom: '2.5rem'}}>
									<img src={`${process.env.PUBLIC_URL}/assets/images/icon/thanks_img.png`} className="img-fluid" alt="" style={{display: 'block',margin: '0 auto'}}/>
								    </div>
                                    <label className="text-confirm-order">!GRACIAS POR TU COMPRA!</label>
                                </div>
                            </div>
                    </div>
                </div>
                </div>

                <section className="section-b-space section-thanks-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div >
                                    <div className="col-sm-12 empty-cart-cls text-center thanks-container">

                                        <div className="ver-thanks-accumulated">
                                            <div>
                                            <label>Has Acumulado</label>
                                            <label>20 <img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit_2.png`} alt="" className="img-fluid" style={{position: 'relative', bottom: '2px', marginLeft: '5px'}}/></label>
                                            </div>
                                        </div>

                                        <div>
                                            tu crédito actual es :
                                        </div>

                                        <div className="ver-thanks-credit">
                                            <label><img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{position: 'relative', bottom: '2px', marginRight: '5px'}}/> {this.state.cash}</label> 
                                        </div>

                                        <div>
                                            Nos comunicaremos contigo cuando el pedido esté en camino y se encuentre en tu domicilio
                                        </div>

                                        <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                            <Link to={`${process.env.PUBLIC_URL}/welcome`} className="ver-btn-solid btn btn-finish-order btn-primary">
                                                FINALIZAR</Link>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    {removeFromWishlist}
)(VerThanks)