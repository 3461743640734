import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    INCREMENT_QTY,
    DECREMENT_QTY_PURCHASE_REFERED,
    ADD_TO_PURCHASE_REFERED,
    REMOVE_FROM_PURCHASE_REFERED,
    GET_PURCHASE_REFERED } from "../constants/ActionTypes";


export default function purchaseReferedReducer(state = {
    purchaseRefered: []
}, action) {
    //console.log('purchaseReferedReducer',action);
    switch (action.type) {
        case GET_PURCHASE_REFERED:
            
            //console.log('GET_PURCHASE_REFERED',action);
            //const productId = action.product.id
            //if (state.purchaseRefered.findIndex(product => product.id === productId) !== -1) {
                const purchaseRefered = action.purchaseRefered.reduce((purchaseReferedAcc, product) => {
                    /*if (product.id === productId) {
                        purchaseReferedAcc.push({ ...product, qty: product.qty+1, sum: (product.price*product.discount/100)*(product.qty+1) }) // Increment qty
                    } else {*/
                       // purchaseReferedAcc = product
                        purchaseReferedAcc.push(product)
                   // }

                    return purchaseReferedAcc
                }, [])

                return { ...state, purchaseRefered }
            //}

            //return { ...state, purchaseRefered: [...state.purchaseRefered, { ...action.product, qty: action.qty, sum: (action.product.price*action.product.discount/100)*action.qty }] }

        
        case ADD_TO_PURCHASE_REFERED:
            const productId = action.product.id
            if (state.purchaseRefered.findIndex(product => product.id === productId) !== -1) {
                const purchaseRefered = state.purchaseRefered.reduce((purchaseReferedAcc, product) => {
                    if (product.id === productId) {
                        purchaseReferedAcc.push({ ...product, qty: product.qty+1, sum: (product.price*product.discount/100)*(product.qty+1) }) // Increment qty
                    } else {
                        purchaseReferedAcc.push(product)
                    }

                    return purchaseReferedAcc
                }, [])

                return { ...state, purchaseRefered }
            }

            return { ...state, purchaseRefered: [...state.purchaseRefered, { ...action.product, qty: action.qty, sum: (action.product.price*action.product.discount/100)*action.qty }] }

        case DECREMENT_QTY_PURCHASE_REFERED:
            
            if (state.purchaseRefered.findIndex(product => product.id === action.productId) !== -1) {
                const purchaseRefered = state.purchaseRefered.reduce((purchaseReferedAcc, product) => {
                    if (product.id === action.productId && product.qty > 1) {
                        //console.log('price: '+product.price+'Qty: '+product.qty)
                        purchaseReferedAcc.push({ ...product, qty: product.qty-1, sum: (product.price*product.discount/100)*(product.qty-1) }) // Decrement qty
                    } else {
                        purchaseReferedAcc.push(product)
                    }

                    return purchaseReferedAcc
                }, [])

                return { ...state, purchaseRefered }
            }

            return { ...state, purchaseRefered: [...state.purchaseRefered, { ...action.product, qty: action.qty, sum: action.product.price*action.qty }] }

        case REMOVE_FROM_PURCHASE_REFERED:
            return {
                purchaseRefered: state.purchaseRefered.filter(item => item.id !== action.product_id.id)
            }

        default:
    }
    return state;
}
