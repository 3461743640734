import React, { Component } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import SimpleReactValidator from 'simple-react-validator';

import Breadcrumb from '../common/breadcrumb';
import {
  removeFromWishlist,
  getFromPurchaseRefered,
  getFromCartUser,
} from '../../actions';
import { removeFromCart, incrementQty, decrementQty } from '../../actions';
import { removeToCart } from '../../actions';
import { getCartTotal } from '../../services';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from '../common/calendar';
import Moment from 'moment';
import store from '../../store';
import Swal from 'sweetalert2';
import VerThanks from '../common/ver-thanks';

let checkout_items = [];

class VercartComponent extends Component {
  constructor(props) {
    super(props);
    this.handlePurchase = this.handlePurchase.bind(this);
    this.onChangeCash = this.onChangeCash.bind(this);
    this.gotoHome = this.gotoHome.bind(this);
    this.state = {
      payment: 'stripe',
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      country: '',
      address: '',
      city: '',
      state: '',
      pincode: '',
      create_account: '',
      cash_amount: 0,
      show_input_cash: false,
      selectedAddressOption: null,
      errors: [
        {
          address: '',
        },
        {
          hour: '',
        },
      ],
      data_address: [],
      total_order: 0,
      selectedHourOption: null,
      data_hour: [
        /*{value: '10:00',label: '10:00', key: 0},
                {value: '12:30',label: '12:30', key: 1},
                {value: '15:30',label: '15:30', key: 2}*/
      ],
      data_orders: [],
      date: null,
      cash: 0,
      previous_cash: 0,
      new_cash: 0,
      cash_win: 0,
      showThanks: false,
      minimum_amount: 0,
      cost_delivery: 0,
      old_cost_delivery: 0,
    };
    this.validator = new SimpleReactValidator();
    this.data_hour = [];
  }

  componentWillMount() {
    var user = AuthService.getCurrentUser();
    if (user) {
      document.body.style = `background-color: #161616;font-family: 'Work Sans'`;
      this.checkOpen();
    } else {
      this.props.history.push('/login');
      window.location.reload();
    }
  }

  checkOpen() {
    var user = AuthService.getCurrentUser();
    ApiService.checkOpen(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('res.data',res.data);
          if (res.data.option_value == 'NO') {
            if (window.location.pathname != '/closed') {
              this.props.history.push('/closed');
            }
          } else {
            this.getCheckoutSettings();
            this.getRefOrders();
            this.setDataOrder();

            //this.setDataOrder();
            this.getCash();
          }
        } else {
          this.props.history.push('/closed');
          window.location.reload();
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cartItems != prevProps.cartItems) {
      this.setDataOrder();
    }
  }

  getCheckoutSettings() {
    var user = AuthService.getCurrentUser();
    ApiService.getCheckoutSettings(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('settings',res.data);
          let data = res.data;
          this.setState({
            minimum_amount: data.minimum_amount.option_value,
          });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  onChangeCash(e) {
    this.setState({
      cash_amount: e.target.value,
    });

    if (e.target.value > this.state.cash) {
      this.setState({
        errors: { cash: true },
      });
      //console.log('onChangeCash if',this.state.cash_amount, e.target.value,this.state.cash);
      return true;
    } else {
      this.setState({
        errors: { cash: false },
      });
      //console.log('onChangeCash else',this.state.cash_amount, e.target.value, this.state.cash);
      return true;
    }
  }

  show_input() {
    //console.log('show_input show_input_cash',this.state.show_input_cash);
    if (!this.state.show_input_cash == true) {
      //this.setState({ cash_amount: this.state.cash });
    } else {
      this.setState({ cash_amount: 0 });
    }
    //console.log('show_input cash_amount',this.state.cash_amount);

    this.setState({
      show_input_cash: !this.state.show_input_cash,
    });
  }

  getRefOrders() {
    var user = AuthService.getCurrentUser();
    ApiService.getReferredOrders(user.token, user.token_order, user.id).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('data',res.data);

          store.dispatch(getFromPurchaseRefered(res.data));
          store.dispatch(getFromCartUser(res.carts_data));

          //this.setState({ order_results:res.data });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  gotoHome() {
    /*document.getElementById("ver-section-buy").style.display = "none";
        document.getElementById("verThanks").style.display = "block";*/
    this.setState({
      showThanks: true,
    });
  }

  handlePurchase(e) {
    //console.log(JSON.stringify(checkout_items));

    if (this.state.data_orders.length > 0) {
      if (this.state.cash_amount > this.state.cash) {
        this.setState({
          errors: { cash: true },
        });
        //console.log('handlePurchase if',this.state.cash_amount, this.state.cash);
        return true;
      } else {
        this.setState({
          errors: { cash: false },
        });
        //onsole.log('handlePurchase else',this.state.cash_amount, this.state.cash);
      }

      let pago_total = this.state.total_order - this.state.cash_amount;
      if (pago_total < this.state.minimum_amount) {
        Swal.fire({
          showConfirmButton: false,
          type: 'warning',
          title: 'Aviso!',
          text: 'El monto mínimo es de ' + this.state.minimum_amount,
          timer: 5000,
        });
        return false;
      }

      this.setState({
        loading: true,
      });

      var user = AuthService.getCurrentUser();
      /*//console.log(checkout_items);
            //console.log(this.state.date);
            //console.log(this.state.selectedAddressOption);
            //console.log(this.state.selectedHourOption);*/

      //console.log('this.state.selectedHourOption.value',this.state.selectedHourOption.value);
      //console.log('hour_programmed',hour_programmed);
      //console.log('this.data_hour',this.data_hour);
      //return false;

      let data_purchase = {
        token: user.token_order,
        id_user: user.id,
        cash: this.state.cash_amount ? this.state.cash_amount : 0,
      };
      //console.log(data_checkout);
      document.getElementsByClassName('page-loader')[0].style.display = 'flex';
      
      ApiService.postPurchase(user.token, data_purchase).then(
        (res) => {
          document.getElementsByClassName('page-loader')[0].style.display =
            'none';
          if (res && res.status == 'success') {
            store.dispatch(removeToCart());
            user.token_order = res.token_order;
            localStorage.setItem('user', JSON.stringify(user));
            this.getNewCash();
            if (this.state.cash_amount > 0) {
              Swal.fire({
                showConfirmButton: false,
                type: 'success',
                title: 'Hecho!',
                text: 'Tu crédito se aplicó correctamente!',
                timer: 2000,
              });
              setTimeout(this.gotoHome, 1000);
              //this.props.history.push("/thanks");
            } else {
              //this.props.history.push("/thanks");
              this.setState({
                showThanks: true,
              });
            }
          } else {
            if (res.mensajeError) {
              var message_error = res.mensajeError;
            } else {
              var message_error = res.message;
            }

            Swal.fire({
              showConfirmButton: false,
              type: 'warning',
              title: 'Aviso!',
              html: message_error,
            });

            this.setState({
              loading: false,
            });
          }
        },
        (error) => {
          document.getElementsByClassName('page-loader')[0].style.display =
            'none';
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.setState({
            loading: false,
          });
        }
      );
    }
  }

  /*setDataOrder(){
        checkout_items = JSON.parse(localStorage.getItem('orders_selected'));
        //console.log('checkout_items',checkout_items)


        
        this.setState({data_orders: checkout_items}, function () {
            //console.log(this.state.data_orders);
            var total = 0;
            this.state.data_orders.map(function(row) {
                total = total + parseFloat(row.total);
             })
             this.setState({total_order: total});
        });
        /*this.setState({data_orders: JSON.parse(localStorage.getItem('orders_selected'))});
        //console.log(this.state.data_orders);*/
  //}

  setDataOrder() {
    var user = AuthService.getCurrentUser();
    ApiService.getCartUser(user.token, user.id).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('getCartUser data',res.data);
          checkout_items = res.data;
          if (checkout_items.length == 0 && this.state.showThanks == false) {
            this.props.history.push('/welcome');
          }
          
          this.setState({ data_orders: checkout_items }, function() {
            //console.log(' this.state.data_order 1', this.state.data_orders);
            var total = 0;
            this.state.data_orders.map(function(row) {
              total = total + row.quantity * row.price;
            });
            this.setState({ total_order: total });
          });
          if (this.state.total_order >= this.state.maximum_amount_no_delivery) {
            this.setState({
              cost_delivery: 0,
            });
          } else {
            this.setState({
              cost_delivery: this.state.old_cost_delivery,
            });
          }
          
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  setStateFromInput = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  setStateFromCheckbox = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.checked;
    this.setState(obj);

    if (!this.validator.fieldValid(event.target.name)) {
      this.validator.showMessages();
    }
  };

  checkhandle(value) {
    this.setState({
      payment: value,
    });
  }

  StripeClick = () => {
    if (this.validator.allValid()) {
      alert('You submitted the form and stuff!');

      var handler = window.StripeCheckout.configure({
        key: 'pk_test_glxk17KhP7poKIawsaSgKtsL',
        locale: 'auto',
        token: (token) => {
          //console.log(token)
          this.props.history.push({
            pathname: '/order-success',
            state: {
              payment: token,
              items: this.props.cartItems,
              orderTotal: this.props.total,
              symbol: this.props.symbol,
            },
          });
        },
      });
      handler.open({
        name: 'Multikart',
        description: 'Online Fashion Store',
        amount: this.amount * 100,
      });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };

  WatchOrder = (item) => {
    //console.log(item)
    for (let i = 0; i < checkout_items.length; i++) {
      checkout_items[i]['visible'] = 0;
    }
    let index = checkout_items.findIndex((x) => x.id === item.id);
    //console.log('index',index);
    checkout_items[index].visible = 1;
    this.setState({ data_orders: checkout_items });
  };

  incrementQty = (item) => {
    //console.log('incrementQty item', item);
    var user = AuthService.getCurrentUser();
    var cart_quantity = item.quantity + 1;
    let data_cart = {
      id_cart: item.id,
      token: user.token_order,
      id_user: user.id,
      id_product: item.id_product,
      quantity: cart_quantity,
      price: item.price,
    };

    ApiService.updateCartUser(user.token, data_cart).then(
      (res) => {
        if (res && res.status == 'success') {
          store.dispatch(incrementQty(data_cart.id_cart));
          var new_data = this.state.data_orders.map(function(row) {
            //console.log('this.state.data_orders map', row);
            return {
              id: row.id,
              id_product: row.id_product,
              id_user: row.id_user,
              price: row.price,
              quantity: row.id === item.id ? row.quantity + 1 : row.quantity,
              token: row.token,
              Product: {
                url: row.Product.url,
                name: row.Product.name,
                logo: row.Product.logo,
              },
            };
          });

          //console.log('this.setState new_data', new_data);
          this.setState({ data_orders: new_data });

          //console.log('this.state.data_orders set ', this.state.data_orders);

          var sum_total = 0;
          for (let i = 0; i < new_data.length; i++) {
            sum_total =
              sum_total +
              Number(new_data[i]['price'] * new_data[i]['quantity']);
          }
          this.setState({ total_order: sum_total });
        } else {
          if (res.mensajeError) {
            Swal.fire({
              showConfirmButton: false,
              type: 'warning',
              title: 'Aviso!',
              html: res.mensajeError,
            });
          } else if (res.message) {
            Swal.fire({
              showConfirmButton: false,
              type: 'warning',
              title: 'Aviso!',
              html: res.message,
            });
          }
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    //console.log('new_checkout_items',checkout_items);
  };

  decrementQty = (item) => {
    var user = AuthService.getCurrentUser();
    var cart_quantity = item.quantity - 1;
    if (cart_quantity == 0) {
      return true;
    }
    let data_cart = {
      id_cart: item.id,
      token: user.token_order,
      id_user: user.id,
      id_product: item.id_product,
      quantity: cart_quantity,
      price: item.price,
    };

    ApiService.updateCartUser(user.token, data_cart).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('updateCartUser',data_cart);
          store.dispatch(decrementQty(data_cart.id_cart));
          var new_data = this.state.data_orders.map(function(row) {
            return {
              id: row.id,
              id_product: row.id_product,
              id_user: row.id_user,
              price: row.price,
              quantity:
                row.id === item.id && row.quantity > 1
                  ? row.quantity - 1
                  : row.quantity,
              token: row.token,
              Product: {
                url: row.Product.url,
                name: row.Product.name,
                logo: row.Product.logo,
              },
            };
          });

          this.setState({ data_orders: new_data });

          var sum_total = 0;
          for (let i = 0; i < new_data.length; i++) {
            sum_total =
              sum_total +
              Number(new_data[i]['price'] * new_data[i]['quantity']);
          }
          this.setState({ total_order: sum_total });
        } else {
          if (res.mensajeError) {
            Swal.fire({
              showConfirmButton: false,
              type: 'warning',
              title: 'Aviso!',
              html: res.mensajeError,
            });
          } else if (res.message) {
            Swal.fire({
              showConfirmButton: false,
              type: 'warning',
              title: 'Aviso!',
              html: res.message,
            });
          }
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  };

  showDireccionHora() {}

  removeFromCartUpdate = (item) => {
    //console.log('removeFromCartUpdate item', item);
    //store.dispatch(removeFromCart(item))

    if (item && item.id > 0 && item.Product) {
      Swal.fire({
        title: 'Confirmar',
        text: '¿Desea eliminar el producto ' + item.Product.name + '?',
        //icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.value) {
          var user = AuthService.getCurrentUser();
          ApiService.deleteCartUser(user.token, item.id).then(
            (res) => {
              if (res && res.status == 'success') {
                store.dispatch(removeFromCart(item));
                this.getRefOrders();
                Swal.fire({
                  type: 'success',
                  title: 'Hecho!',
                  text: 'Producto Eliminado!',
                  timer: 1500,
                });
              } else {
                if (res.mensajeError) {
                  Swal.fire({
                    showConfirmButton: false,
                    type: 'warning',
                    title: 'Aviso!',
                    html: res.mensajeError,
                  });
                } else if (res.message) {
                  Swal.fire({
                    showConfirmButton: false,
                    type: 'warning',
                    title: 'Aviso!',
                    html: res.message,
                  });
                }
              }
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        }
      });
    }
  };

  getCash() {
    var user = AuthService.getCurrentUser();
    AuthService.getCash(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('getCash data',res.data);
          let cash_amount =
            res && res.data && res.data.amount_cash
              ? Math.round(res.data.amount_cash * 100) / 100
              : 0;
          //this.setState({ cash_amount: cash_amount });
          this.setState({ cash: cash_amount });
          this.setState({ previous_cash: cash_amount });

          //console.log(this.state.data_categ_parents);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getNewCash() {
    var user = AuthService.getCurrentUser();
    AuthService.getCash(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          //console.log('getCash data',res.data);
          let cash_amount =
            res && res.data && res.data.amount_cash
              ? Math.round(res.data.amount_cash * 100) / 100
              : 0;
          //this.setState({ cash_amount: cash_amount });
          this.setState({ cash: cash_amount });
          this.setState({ new_cash: cash_amount });
          //console.log(this.state.new_cash);
          //console.log(this.state.cash_amount)
          //console.log(this.state.previous_cash)
          //var cash_win = 0;
          //console.log(cash_win)
          this.setState({
            cash_win: 0,
          });
          //console.log(this.state.data_categ_parents);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  renderResults() {
    //console.log('renderResults()',this.state.data_hour);
    const { selectedHourOption } = this.state;
    const customStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        //console.log({ data, isDisabled, isFocused, isSelected });
        return {
          ...styles,
          backgroundColor: isFocused ? '#999999' : null,
          color: '#333333',
        };
      },
      control: (base, state) => ({
        ...base,
        background: '#212121',
        //opacity: 0.5,
        border: '2px solid #F8EEE1',
        //color: "#cfd4da",
        borderRadius: 30,
        backgroundColor: state.isFocused ? '#212121' : null,
        // match with the menu
        // Overwrittes the different states of border
        //borderColor: state.isFocused ? "yellow" : "green",
        // Removes weird border around container
        /*boxShadow: state.isFocused ? null : null,
              "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "red" : "blue"
              }*/
      }),
      singleValue: (base, state) => ({
        ...base,
        color: '#F8EEE1',
      }),
    };

    return (
      <Select
        value={selectedHourOption}
        onChange={this.handleHour}
        options={this.state.data_hour}
        placeholder={'Seleccione una hora'}
        styles={customStyles}
      />
    );
  }

  render() {
    const { cartItems, total } = this.props;
    const symbol = (
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`}
        alt=""
        className="img-fluid"
        style={{
          width: 'auto',
          height: 'auto',
          bottom: '2px',
          marginRight: '5px',
        }}
      />
    ); //'S/ ';
    document.body.classList.add('dark');

    // Paypal Integration
    const onSuccess = (payment) => {
      //console.log("The payment was succeeded!", payment);
      this.props.history.push({
        pathname: '/order-success',
        state: {
          payment: payment,
          items: cartItems,
          orderTotal: total,
          symbol: symbol,
        },
      });
    };

    const onCancel = (data) => {
      //console.log('The payment was cancelled!', data);
    };

    const onError = (err) => {
      //console.log("Error!", err);
    };

    const client = {
      sandbox:
        'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
      production:
        'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
    };

    const { selectedAddressOption } = this.state;
    //const { selectedHourOption } = this.state;
    const customStyles = {
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        //console.log({ data, isDisabled, isFocused, isSelected });
        return {
          ...styles,
          backgroundColor: isFocused ? '#999999' : null,
          color: '#333333',
        };
      },
      control: (base, state) => ({
        ...base,
        background: '#212121',
        //opacity: 0.5,
        border: '2px solid #F8EEE1',
        //color: "#cfd4da",
        borderRadius: 30,
        backgroundColor: state.isFocused ? '#212121' : null,
       
      }),
      singleValue: (base, state) => ({
        ...base,
        color: '#F8EEE1',
      }),
    };

    return (
      <div
        className="cart-container ref-chk-container"
        style={{ backgroundColor: '#161616 !important' }}
      >
        {this.state.showThanks == false ? (
          <section className="section-b-space">
            <div className="container padding-cls">
              <div className="checkout-page">
                <div className="checkout-form">
                  <form>
                    <div className="checkout row">
                      {
                        <div
                          id="verConfirmarPedido"
                          className="col-lg-12 col-sm-12 col-xs-12 ref-container-checkout-details"
                        >
                          <div className="row">
                            <div className="col-md-8 col-sm-8 offset-md-2 offset-sm-2 col-xs-8 offset-xs-2">
                              <div className="page-title page-tittle2">
                                {/*<h1>{title}</h1>*/}
                                <label className="text-confirm-order">
                                  CONFIRMA TU PEDIDO..
                                </label>
                              </div>
                            </div>
                          </div>

                          {/*<div className="referred-checkout-title">
                                                <label>Tu pedido</label>
                                            </div>*/}
                          <div
                            className="checkout-details ref-checkout-details data-ref-items-container"
                            style={{
                              marginBottom: '1.5rem',
                              marginTop: '1.5rem',
                            }}
                          >
                            <div className="order-box checkout-order-box">
                              {/*<div className="title-box">
                                                        <div>Product <span> Total</span></div>
                                                    </div>*/}
                              <ul className="qty">
                                {this.state.data_orders.map((item, index) => {
                                  return (
                                    <div key={index}>
                                      {
                                        /*item.Purchases_detail.map((sub_item, subindex) =>*/
                                        <div>
                                          <div className="col-xs-3 div-checkout-remove">
                                            <h2 className="td-color">
                                              <a
                                                className="icon checkout-remove"
                                                onClick={() =>
                                                  this.removeFromCartUpdate(
                                                    item
                                                  )
                                                }
                                              >
                                                <i className="icon-close"></i>
                                              </a>
                                            </h2>
                                          </div>
                                          <div
                                            key={`sub_item_name` + index}
                                            className="chk_sub_item_container"
                                          >
                                            <div className="chk_sub_item_img">
                                              <img
                                                src={
                                                  `${process.env.REACT_APP_URL_FILES}images/products/` +
                                                  (item &&
                                                  item.Product &&
                                                  item.Product.url
                                                    ? item.Product.url
                                                    : item && item.Product.logo
                                                    ? item.Product.logo
                                                    : '')
                                                }
                                                className="sub_item_img_checkout"
                                              />
                                            </div>
                                            <div className="chk_sub_item_details">
                                              <div className="chk_sub_item_name">
                                                <label>
                                                  {item &&
                                                  item.Product &&
                                                  item.Product.name
                                                    ? item.Product.name
                                                    : item && item.name
                                                    ? item.name
                                                    : ''}{' '}
                                                  {item &&
                                                  item.Product &&
                                                  item.Product.size
                                                    ? ' - ' + item.Product.size
                                                    : ''}
                                                </label>
                                                <div className="chk_sub_item_amount">
                                                  {symbol}{' '}
                                                  {parseFloat(
                                                    item.price
                                                  ).toFixed(2)}
                                                </div>
                                                <div className="qty-box box-quantity chk-qty">
                                                  <div className="input-group">
                                                    <span className="input-group-prepend">
                                                      <button
                                                        type="button"
                                                        className="btn quantity-left-minus btn-checkout-minus"
                                                        onClick={() =>
                                                          this.decrementQty(
                                                            item
                                                          )
                                                        }
                                                        data-type="minus"
                                                        data-field=""
                                                      >
                                                        <i className="fa fa-minus"></i>
                                                      </button>
                                                    </span>
                                                    <input
                                                      type="text"
                                                      name="quantity"
                                                      value={item.quantity}
                                                      readOnly={true}
                                                      className="form-control input-number chk_sub_item_input input-checkout"
                                                    />

                                                    <span className="input-group-prepend">
                                                      <button
                                                        type="button"
                                                        className="btn quantity-right-plus btn-checkout-plus"
                                                        onClick={() =>
                                                          this.incrementQty(
                                                            item
                                                          )
                                                        }
                                                        data-type="plus"
                                                        data-field=""
                                                      >
                                                        <i className="fa fa-plus"></i>
                                                      </button>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              
                                            </div>
                                          </div>
                                        </div>
                                        //)
                                      }
                                    </div>
                                  );
                                  
                                })}
                              </ul>
                            </div>
                          </div>
                          
                          <div className="checkout-details ref-checkout-details ver-delivery">
                            <div className="order-box checkout-order-box chk-ref-total">
                              <ul className="">
                                <li>
                                  <label>Costo de envío:</label>{' '}
                                  <span className="">
                                    {symbol}
                                    {parseFloat(
                                      this.state.cost_delivery
                                    ).toFixed(0)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="checkout-details ref-checkout-details ref-checkout-total">
                            <div className="order-box checkout-order-box chk-ref-total">
                              <ul className="total">
                                <li>
                                  <label>Total:</label>{' '}
                                  <span className="count user-orders-total">
                                    {symbol}
                                    {parseFloat(this.state.total_order).toFixed(
                                      2
                                    )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="row chk-cart-buttons">
                            {this.state.cash > 0 ? (
                              <div
                                className="col-12 cart-btn-checkout cart-btn-checkout btn-chk-order"
                                style={{ textAlign: 'center !important' }}
                              >
                                <div className="qty-box quanty-categ-prod categ-prod-buttons">
                                  <div
                                    className="row check-out row-check-date "
                                    style={{
                                      textAlign: 'center',
                                      margin: '0 auto',
                                    }}
                                  >
                                    <div className="form-group col-md-6 col-sm-6 col-xs-6 profile-buttons apply-credit-container">
                                      <span
                                        href="#"
                                        className="ver-btn-solid btn btn-apply-credit btn-primary"
                                        onClick={() => this.show_input()}
                                      >
                                        APLICAR CRÉDITO
                                        <div className="chk-credit">
                                          <span>Crédito actual: </span>
                                          <span>
                                            {symbol} {this.state.cash}
                                          </span>
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            {this.state.show_input_cash ? (
                              <div
                                className="col-12 div-input-credit"
                                style={{ textAlign: 'center !important' }}
                              >
                                <div className="qty-box quanty-categ-prod categ-prod-buttons">
                                  <div
                                    className="row check-out row-check-date "
                                    style={{
                                      textAlign: 'center',
                                      margin: '0 auto',
                                    }}
                                  >
                                    <div className="form-group apply-credit-container">
                                      <input
                                        type="text"
                                        name="cash"
                                        value={
                                          this.state.cash_amount > 0
                                            ? this.state.cash_amount
                                            : ''
                                        }
                                        onChange={this.onChangeCash}
                                        placeholder="Ingresa el crédito a utilizar"
                                      />
                                      {this.state.errors.cash == true ? (
                                        <div
                                          className="alert alert-danger ver-alert"
                                          role="alert"
                                        >
                                          Su crédito disponible es {symbol}{' '}
                                          {this.state.cash}
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            <div className="col-12 cart-btn-checkout">
                              <button
                                type="button"
                                className="btn btn-solid btn-referred-checkout"
                                disabled={this.state.loading}
                                onClick={() => this.handlePurchase()}
                              >
                                SIGUIENTE
                              </button>
                            </div>
                            <div className="col-12 cart-btn-keep-shopping">
                              <Link
                                to={`${process.env.PUBLIC_URL}/welcome`}
                                className=""
                              >
                                Seguir comprando
                              </Link>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    {/*<div className="row chk-cart-buttons">
                                        <div className="col-12 cart-btn-checkout">
                                            <button type="button" className="btn btn-solid btn-referred-checkout"
                                            disabled={this.state.loading}
                                            onClick={() => this.handlePurchase()}
                                            >CHECKOUT</button>
                                        </div>
                                    </div>*/}
                  </form>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <VerThanks cash={this.state.cash} cash_win={this.state.cash_win} />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  cartItems: state.cartList.cart,
  symbol: (
    <img
      src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`}
      alt=""
      className="img-fluid"
      style={{ width: 'auto', height: 'auto', bottom: '2px' }}
    />
  ), //state.data.symbol,
  total: getCartTotal(state.cartList.cart),
});

export default connect(mapStateToProps, {
  removeToCart,
  removeFromCart,
  incrementQty,
  decrementQty,
})(VercartComponent);
