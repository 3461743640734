import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import {Link, Redirect } from 'react-router-dom'
import PaypalExpressBtn from 'react-paypal-express-checkout';
import SimpleReactValidator from 'simple-react-validator';

import Breadcrumb from "../common/breadcrumb";
import {removeFromWishlist} from '../../actions'
import {getCartTotal} from "../../services";
import Select from 'react-select';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Swal from 'sweetalert2';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger ver-alert" role="alert">
          Este campo es obligatorio!
        </div>
      );
    }
  };

class RegisterReferred extends Component {

    constructor (props) {
        super (props)
        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeCellPhone = this.onChangeCellPhone.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeNroDoc = this.onChangeNroDoc.bind(this);
        this.state = {
            name: "",
            email: "",
            cell_phonenumber: "",
            password: "",
            confirm_password: "",
            nro_doc: "",
            errors: {},
            error_password: {},
            message: "",
            status: "DESACTIVADO",
            selectedTypeDocOption: null,
            dataTipo: [
                //{value: 'ADMIN',label: 'Administrador'},
                {value: 'DNI',label: 'DNI'},
                {value: 'PASAPORTE',label: 'Pasaporte'}
            ],

        }
        this.validator = new SimpleReactValidator();
    }

    componentWillMount() {
        var user = AuthService.getCurrentUser();
        //console.log('User',user);
        /*if(AuthService.getCurrentUser().role!='EMBAJADOR'){
            this.props.history.push("/welcome")
        }*/
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
      }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
      }

    onChangeCellPhone(e) {
        //console.log('cambio numero', e.target.value);
        let val = e.target.value
        let max = 9
        let vale_lenght = val.toString().length
        
        //console.log('val.toString().length', val.toString().length);
        //console.log('vale_lenght', vale_lenght, max);
        //console.log('this.state.cell_phonenumber', this.state.cell_phonenumber);
        if(vale_lenght <= max){
            this.setState({
                cell_phonenumber: e.target.value
            });
        }else{
            this.setState({
                cell_phonenumber: this.state.cell_phonenumber
            });
        }

        /*this.setState({
            cell_phonenumber: e.target.value
        });*/
      }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
      }

    onChangeConfirmPassword(e) {
        this.setState({
            confirm_password: e.target.value
        });
      }
    
    onChangeNroDoc(e) {
        this.setState({
            nro_doc: e.target.value
        });
      }

    handleRegister(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
          });
    
        this.form.validateAll();

        if (this.state.password != this.state.confirm_password) {
            this.setState({
                error_password: {confirm_password : "La contraseña no coincide"}
            }) 
        }else{
            this.setState({
                error_password: {confirm_password : ""}
            }) 
        }

        if(!this.state.selectedTypeDocOption){
            this.setState({
                errors: {type_document : "Error"}
            }) 
        }else{
            this.setState({
                errors: {type_document : ""}
            }) 
        }
    
        if (this.checkBtn.context._errors.length === 0 && this.state.password == this.state.confirm_password) {
            var user = AuthService.getCurrentUser();
            let data_user = {
                'name': this.state.name,
                'email': this.state.email,
                'cell_phonenumber': this.state.cell_phonenumber,
                'password': this.state.password,
                'id_ambassador': user.id,
                'nro_document': this.state.nro_doc,
                'type_document': this.state.selectedTypeDocOption.value,
                'role': 'REFERIDO',
                'status': 'DESACTIVADO',
            }

            ApiService.postUser(user.token,data_user).then(
            (res) => {
                if(res){
                    if(res && res.status == 'success'){
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', Swal.stopTimer)
                              toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                          })
                          
                          Toast.fire({
                            icon: 'success',
                            title: '<span style="color:#F7F7F7">Referido Registrado!<span>',
                          })
                        this.props.history.push("/welcome");
                    }else{
                    }
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );
        } else {
          this.setState({
            loading: false
          });
        }
      }

    handleTypeDocChange = selectedTypeDocOption => {
        this.setState(
          { selectedTypeDocOption },
          () => console.log(`Option selected:`, this.state.selectedTypeDocOption)
        );
      };

    render (){
        const {cartItems, symbol, total} = this.props;
        document.body.classList.add('dark');
        const { selectedTypeDocOption } = this.state;
        const customStyles = {
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                // const color = chroma(data.color);
                //console.log({ data, isDisabled, isFocused, isSelected });
                return {
                  ...styles,
                  backgroundColor: isFocused ? "#999999" : null,
                  color: "#333333"
                };
              },
            control: (base, state) => ({
              ...base,
              background: "#212121",
              opacity: 0.5,
              border: "2px solid #F8EEE1",
              //color: "#cfd4da",
              borderRadius: 30,
              backgroundColor: state.isFocused ? "#212121" : null,
              // match with the menu
              // Overwrittes the different states of border
              //borderColor: state.isFocused ? "yellow" : "green",
              // Removes weird border around container
              /*boxShadow: state.isFocused ? null : null,
              "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "red" : "blue"
              }*/
            }),
            singleValue: (base, state) => ({
                ...base,
                color: '#F8EEE1'
            }),
          };
        return (

            <div className="cart-container" style={{backgroundColor: '#161616 !important'}}>
                <div className="breadcrumb-section title-cart">
                <div className="container">
                    <div className="row">
                            <div className="col-md-12">
                                <div className="page-title page-tittle2">
                                    {/*<h1>{title}</h1>*/}
                                    <label className="text-confirm-order">REGISTRAR REFERIDO</label>
                                </div>
                            </div>
                    </div>
                </div>
                </div>

                <section className="section-b-space" style={{paddingTop: '15px'}}>
                    <div className="container padding-cls">
                        <div className="checkout-page">
                            <div className="checkout-form register-ref-form">
                                    <div className="checkout row container-data-ref" style={{margin: 'auto'}}>
                                        <div className="col-lg-12 col-sm-12 col-xs-12">
                                            <div>
                                            <Form
                                                onSubmit={this.handleRegister} 
                                                //onSubmit={this.props.action=='create'?this.handleRegister:this.handleUpdate}
                                                autoComplete="off"
                                                encType="multipart/form-data" 
                                                method="POST"
                                                ref={c => {this.form = c;
                                            }}>
                                                <div className="referred-checkout-title">
                                                    <label>Datos del referido</label>
                                                </div>
                                                <div className="row check-out row-check-date" style={{marginBottom: '1rem'}}>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">NOMBRES Y APELLIDOS</div>
                                                        <Input type="text" name="name" value={this.state.name} onChange={this.onChangeName} validations={[required]}/>
                                                    </div>
                                                </div>
                                                <div className="row check-out row-check-date" style={{marginBottom: '1rem'}}>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">CORREO</div>
                                                        <Input type="text" name="email" value={this.state.email} onChange={this.onChangeEmail} validations={[required]}/>
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">CELULAR</div>
                                                        <Input type="number" name="cell_phonenumber" value={this.state.cell_phonenumber} onChange={ (e) => this.onChangeCellphone(e) } validations={[required]}/>
                                                    </div>
                                                </div>
                                                <div className="row check-out row-check-date" style={{marginBottom: '1rem'}}>
                                                    <div className="form-select col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">TIPO DE DOCUMENTO</div>
                                                        <Select
                                                            value={selectedTypeDocOption}
                                                            onChange={this.handleTypeDocChange}
                                                            options={this.state.dataTipo}
                                                            styles={customStyles}
                                                        />
                                                        {
                                                        this.state.errors.type_document && !this.state.selectedTypeDocOption?
                                                        <div className="alert alert-danger ver-alert" role="alert" >Este campo es obligatorio!</div>
                                                        :''}
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">NRO. DOCUMENTO</div>
                                                        <Input type="text" name="nro_doc" value={this.state.nro_doc} onChange={this.onChangeNroDoc} validations={[required]}/>
                                                    </div>
                                                </div>
                                                <div className="row check-out row-check-date" style={{marginBottom: '1rem'}}>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">CONTRASEÑA</div>
                                                        <Input type="password" name="password" value={this.state.password} onChange={this.onChangePassword} validations={[required]}/>
                                                        <div className="alert alert-danger ver-alert" role="alert">{this.state.error_password.confirm_password}</div>
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">CONFIRMAR CONTRASEÑA</div>
                                                        <Input type="password" name="confirm_password" value={this.state.confirm_password} onChange={this.onChangeConfirmPassword} validations={[required]}/>
                                                    </div>
                                                </div>
                                                <div className="row check-out row-check-date" style={{textAlign: 'center',marginTop: '1rem',marginBottom: '1.5rem'}}>
                                                    <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                    <button className="btn-solid btn btn-create-referred btn-primary" >
                                                    {this.state.loading}
                                                    {this.state.loading && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                        GUARDAR</button>
                                                    </div>
                                                </div>
                                                {this.state.message && (
                                                    <div className="form-group">
                                                        <div className="alert alert-danger" role="alert">
                                                            {this.state.message}
                                                        </div>
                                                    </div>
                                                )}
                                                <CheckButton
                                                    style={{ display: "none" }}
                                                    ref={c => {
                                                        this.checkBtn = c;
                                                    }}
                                                />
                                            </Form>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    {removeFromWishlist}
)(RegisterReferred)