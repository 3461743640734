import React, {Component} from 'react';
//import Breadcrumb from "../common/breadcrumb";
import OtpInput from 'react-otp-input';
import {Link} from 'react-router-dom'
import PinInput from "react-pin-input";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";

class Unauthorized extends Component {

    constructor (props) {
        super (props);

        this.state = {
            login_message: '',
        };
    }

    componentWillMount() {
        this.setState({login_message: localStorage.getItem('login-message')});
    }

    render (){

        document.body.classList.remove('dark');

        return (
            <div className="sup-content-login">
                    <div className="container container-error-login">
                        <div className="logo-error-login">
                            <img src="/assets/images/boton_error_login_1.png" className="img-logo-error-login"></img>
                        </div>
                        <div className="containger-msg-error-login">
                            <div className="msg-error-login-1" style={{fontSize: '22px', fontWeight: 'bold', marginBottom: '1rem'}}>
                            Intentaste entrar de forma incorrecta 
                            </div>
                            <div className="msg-error-login-2" style={{paddingLeft: '3rem', paddingRight: '3rem'}}>
                            {this.state.login_message?this.state.login_message+'. ':''} Se enviará un mensaje al administrador para poder habilitar tu ingreso
                            </div>
                        </div>
                            <div className="btn-return-login" style={{width: '100%', textAlign: 'center'}}>
                                <Link to={`${process.env.PUBLIC_URL}/login`}
                                ><img src="/assets/images/boton_error_login_2.png"></img></Link>
                            </div>                     
                    </div>
                    <div className="image-right">
                            <img src="/assets/images/plant_login.png"></img>
                    </div>
            </div>
        )
    }
}

export default Unauthorized