import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import CartPage from '../components/common/headers/common/cart-header';
import { removeFromCart } from '../actions';
import { getCartTotal } from '../services';
import store from '../store';
import AuthService from '../components/services/auth.service';
import ApiService from '../components/services/api.service';

//console.log('CartContainer AuthService.getCurrentUser()', AuthService.getCurrentUser());

const removeFromCartItem = (item) => {
  //console.log(item);
  //store.dispatch(removeFromCart(item))

  var user = AuthService.getCurrentUser();
  if (item.id > 0) {
    ApiService.deleteCartUser(user.token, item.id).then(
      (res) => {
        if (res && res.status == 'success') {
          store.dispatch(removeFromCart(item));
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }
};

const CartContainer = ({
  cartList,
  total,
  symbol,
  removeFromCart,
  logo,
  checkCart,
}) => (
  //console.log('CartContainer AuthService.getCurrentUser()', AuthService.getCurrentUser())

  <li className="onhover-div ">
    <div className="cart-qty-cls">{cartList.length}</div>
    <div>
      <Link
        to="#"
        onClick={() => (cartList.length > 0 ? checkCart(1) : checkCart(0))}
      >
        {logo == 'ver_logo.png' ? (
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_cart_1.png`}
            className="img-fluid"
            alt=""
          />
        ) : (
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_cart_2.png`}
            className="img-fluid"
            alt=""
          />
        )}
        {/*<i className="fa fa-shopping-cart"></i>*/}
      </Link>
    </div>
    <ul className="show-div shopping-cart">
      {cartList.map((item, index) => (
        <CartPage
          key={index}
          item={item}
          total={total}
          symbol={
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`}
              alt=""
              className="img-fluid"
              style={{ width: 'auto', height: 'auto', bottom: '2px' }}
            />
          }
          removeFromCart={() => removeFromCartItem(item)}
        />
      ))}
      {cartList.length > 0 ? (
        <div>
          <li>
            <div className="total">
              <h5>
                subtotal :{' '}
                <span>
                  {
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`}
                      alt=""
                      className="img-fluid"
                      style={{ position: 'relative', bottom: '2px' }}
                    />
                  }{' '}
                  {parseFloat(total).toFixed(0)}
                </span>
              </h5>
              {/*//console.log('cartList' ,cartList, total, symbol)*/}
            </div>
          </li>
          <li>
            <div className="buttons">
              {/*<Link to={`${process.env.PUBLIC_URL}/`+(AuthService.getCurrentUser() && AuthService.getCurrentUser().role ? (AuthService.getCurrentUser().role == 'REFERIDO' ? 'cart' : 'user-checkout') : 'cart')} className="view-cart">Ver carrito</Link>*/}
              <Link
                to="#"
                onClick={() =>
                  cartList.length > 0 ? checkCart(1) : checkCart(0)
                }
                className="view-cart"
              >
                Ver carrito
              </Link>
              {/*<Link to={`${process.env.PUBLIC_URL}/checkout`} className="checkout">checkout</Link>*/}
            </div>
          </li>
        </div>
      ) : (
        <li>
          <h5>Su carrito está vacío actualmente.</h5>
        </li>
      )}
    </ul>
  </li>
);

function mapStateToProps(state) {
  //console.log('mapStateToProps CartContainer', state);
  return {
    cartList: state.cartList.cart,
    total: getCartTotal(state.cartList.cart),
    symbol: state.data.symbol,
  };
}

export default connect(mapStateToProps, { removeFromCart })(CartContainer);
//export default CartContainer;
