import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import CartPage from '../components/common/headers/common/cart-header'
import {removeFromCart} from '../actions'
import {getCartTotal} from '../services'
import AuthService from "../components/services/auth.service";

const UserContainer = ({cartList, total, symbol, removeFromCart,handlerLogout}) => (
     <li  className="onhover-div mobile-user">
            <div><img src={`${process.env.PUBLIC_URL}/assets/images/icon/user.png`} className="img-fluid" alt=""/>
            </div>

        {
            AuthService.getCurrentUser() && AuthService.getCurrentUser().role=='EMBAJADOR'?
            <ul className="show-div shopping-cart">
                <li>
                <Link to={`${process.env.PUBLIC_URL}/profile`} data-lng="en">Perfil</Link>
                </li>
                <li>
                <Link to={`${process.env.PUBLIC_URL}/register-referred`} data-lng="en">Registrar referidos</Link>
                </li>
                <li>
                <Link to={`${process.env.PUBLIC_URL}/register-address`} data-lng="en">Registrar dirección</Link>
                </li>
                <li>
                <Link to={`${process.env.PUBLIC_URL}/login`} onClick={() => handlerLogout()}  data-lng="en">Logout</Link>
                </li>
            </ul>
            :
            <ul className="show-div shopping-cart">
                <li>
                <Link to={`${process.env.PUBLIC_URL}/login`} onClick={() => handlerLogout()}  data-lng="en">Logout</Link>
                </li>
            </ul>
        }

        {/*<ul className="show-div shopping-cart">
           <li>
                <Link to={`${process.env.PUBLIC_URL}/login`} data-lng="en">Login</Link>
            </li>
            <li>
                <Link to={`${process.env.PUBLIC_URL}/pages/register`} data-lng="en">Register</Link>
            </li>
        </ul>*/}

    </li>
)


function mapStateToProps(state) {
    return {
        /*cartList: state.cartList.cart,
        total: getCartTotal(state.cartList.cart),
        symbol: state.data.symbol,*/
    }
}

export default connect(mapStateToProps, {removeFromCart})(UserContainer);
