import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import AuthService from "../../services/auth.service";

class AccesoriesList extends Component {

    constructor(props){
        super(props);
        this.state = {
            open: false,
            stock: 'InStock',
            quantity: 1,
            image: '',
            total: this.props.product.price,
        }
    }

    onClickHandle(img) {
        this.setState({ image : img} );
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    minusQty = () => {
        if((this.state.quantity - 1) > 0){
            this.setState({quantity: this.state.quantity - 1})
            let total = this.props.product.price * (this.state.quantity-1);
            this.setState({total: total});
        }
    }

    plusQty = () => {
        this.setState({quantity: this.state.quantity+1})
        let total = this.props.product.price * (this.state.quantity+1);
        this.setState({total: total});
    }
    changeQty = (e) => {
        if(e.target.value != '')
        {
            this.setState({ quantity: parseInt(e.target.value) })
            let total = this.props.product.price * e.target.value;
            this.setState({total: total});
        }
    }

    handleImageLoaded() {
        //console.log('loaded!')
        document.getElementsByClassName("page-loader")[0].style.display = "none";
        //this.setState({ imageStatus: "loaded" });
      }

    stopLoading() {
        //console.log('loaded!')
        document.getElementsByClassName("page-loader")[0].style.display = "none";
        //this.setState({ imageStatus: "loaded" });
      }

    render() {
        const {product, qty, symbol, onAddToCartClicked, BuynowClicked, onAddToWishlistClicked, onAddToCompareClicked, categoria, indice,cant_categ} = this.props;
        //console.log('categoria list product', categoria);
        var colorBoton = categoria.color;
        var textColor = categoria.text_color?categoria.text_color:'#161616';
        var background_image = '';
        if(categoria.back_image)
        {
            background_image = `${process.env.REACT_APP_URL_FILES}images/categories/` + categoria.back_image;
        }
        let RatingStars = []

        const customStyles = {
            backgroundColor: colorBoton,
            color: textColor,
            border: "2px solid "+colorBoton,
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                // const color = chroma(data.color);
                //console.log({ data, isDisabled, isFocused, isSelected });
                return {
                  ...styles,
                  backgroundColor: colorBoton,
                  color: colorBoton
                };
              },
            control: (base, state) => ({
              ...base,
              background: "#212121",
              opacity: 0.5,
              border: "2px solid "+colorBoton,
              //color: "#cfd4da",
              borderRadius: 30,
              backgroundColor: colorBoton,
            }),
            singleValue: (base, state) => ({
                ...base,
                color: colorBoton
            }),
          };

        for(var i = 0; i < product.rating; i++) {
            RatingStars.push(<i className="fa fa-star" key={i}></i>)
        }
        var handleAddCart  =   this.props.handlerAddCart;
        
        //var ruta = AuthService.getCurrentUser() && AuthService.getCurrentUser().role ? (AuthService.getCurrentUser().role == 'REFERIDO' ? 'cart' : 'user-checkout') : 'cart';
        let url_image = `${process.env.REACT_APP_URL_FILES}images/products/`;
        return (
                <div>
                    <div className="home home-list-products">
                        <div className="container">
                            <div>
                                <div className="row_product row_categ_product row_prods">
                                    <div className="list-product list-categ-product background-img-prod" style={{backgroundImage: `url(${background_image})`, backgroundRepeat: 'no-repeat'}}>
                                        {product && product.url ? <img src={url_image + product.url} className="img-fluid" onLoad={this.handleImageLoaded.bind(this)}/> :this.stopLoading()}
                                    </div>
                                    <div className="slider-contain side-watch2 side-prods">
                                        <div className="items-product categ-items-prod">
                                            <label className="categ-product-name">{product.name}</label>
                                            {/*<h1>CLEAR</h1>*/}
                                            <div className="product-description border-product">
                                                    {/*{product.size?
                                                    <div className="size-box">
                                                        <ul>
                                                            {product.size.map((size, i) => {
                                                                return <li key={i}><a href="#">{size}</a></li>
                                                            })}
                                                        </ul>
                                                    </div>:''}*/}
                                                    <div className="accesirie-description">
                                                        {product.description}
                                                    </div>
                                                    <div className="qty-box quanty-categ-prod">
                                                        <div>
                                                            <h6>CANTIDAD</h6>
                                                            <div className="input-group input-categ-prod">
                                                                <span className="input-group-prepend input-group-categ-prd">
                                                                    <button type="button" className="btn quantity-left-minus categ-btn-left-number" onClick={this.minusQty} data-type="minus" data-field="">
                                                                    <i className="fa fa-minus"></i>
                                                                    </button>
                                                                </span>
                                                                <input type="text" name="quantity" value={this.state.quantity}  onChange={this.changeQty} className="form-control input-number categ-input-number" />
                                                                <span className="input-group-prepend input-group-categ-prd">
                                                                <button type="button" className="btn quantity-right-plus categ-btn-right-number" onClick={this.plusQty} data-type="plus" data-field="">
                                                                <i className="fa fa-plus"></i>
                                                                </button>
                                                               </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="detail-total">
                                                        <div style={{display: 'flex'}}>
                                                        TOTAL:
                                                        <label style={{display: 'flex', marginLeft: '10px'}}><img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{position: 'relative', bottom: '2px', marginRight: '5px'}}/> {(parseFloat(product.price * this.state.quantity)).toFixed(0)} </label>
                                                        </div>
                                                    </div>
                                                    <div className="qty-box quanty-categ-prod categ-prod-buttons">
                                                        <div className="row check-out row-check-date " style={{textAlign: 'center',marginLeft: '-1.5rem'}}>
                                                            <div className="form-group col-md-6 col-sm-6 col-xs-6 profile-buttons">
                                                                {/*<Link to={`${process.env.PUBLIC_URL}/products`} className="ver-btn-solid btn btn-order-history btn-primary"
                                                                style={{
                                                                    backgroundColor: colorBoton,
                                                                    border: "2px solid "+colorBoton,color: colorBoton }} >
                                                                    {this.state.loading && (
                                                                        <span className="spinner-border spinner-border-sm"></span>
                                                                    )}
                                                                    AGREGAR AL CARRITO</Link>*/}

                                                                    <span className="ver-btn-solid btn btn-order-history btn-primary btn-add-to-cart"
                                                                     style={{
                                                                        backgroundColor: colorBoton,
                                                                        border: "2px solid "+colorBoton,color: colorBoton }} 
                                                                    onClick={() => handleAddCart(product, this.state.quantity, null )} >AGREGAR AL CARRITO</span> 
                                                            </div>
                                                        </div>
                                                        <div className="buttons-categ-prod btn-prod-categ">
                                                            <span style={customStyles} 
                                                            className="btn ver-btn-solid categ-btn-purchase bnt-buy"
                                                            onClick={() => handleAddCart(product, this.state.quantity, 1 )}
                                                            >
                                                                    {this.state.loading && (
                                                                        <span className="spinner-border spinner-border-sm"></span>
                                                                    )}
                                                                    PAGAR</span>

                                                        </div>
                                                    </div>
                                                    
                                                    {/*<div className="detail-total">
                                                        <label >TOTAL</label>
                                                        <h3>{symbol}{product.price} </h3>
                                                    </div>*/}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="categ-product-index">{indice} / {cant_categ}</div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="product-index">01/02</div>*/}
                    {/*<div className="img-wrapper">
                        <div className="lable-block">
                            {(product.new == true)? <span className="lable3">new</span> : ''}
                            {(product.sale == true)? <span className="lable4">on sale</span> : ''}

                        </div>
                        <div className="front">
                            <Link to={`${process.env.PUBLIC_URL}/left-sidebar/product/${product.id}`} ><img
                                src={`${
                                    product.variants?
                                        this.state.image?this.state.image:product.variants[0].images
                                        :product.pictures[0]
                                    }`}
                                className="img-fluid"
                                alt="" /></Link>
                        </div>
                        <div className="cart-info cart-wrap">
                            <button title="Add to cart" onClick={onAddToCartClicked}>
                                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                            </button>
                            <a href="javascript:void(0)" title="Add to Wishlist" onClick={onAddToWishlistClicked}>
                                <i className="fa fa-heart" aria-hidden="true"></i>
                            </a>
                            <a href="javascript:void(0)" data-toggle="modal"
                               data-target="#quick-view"
                               title="Quick View"
                               onClick={this.onOpenModal}><i className="fa fa-search" aria-hidden="true"></i></a>
                            <Link to={`${process.env.PUBLIC_URL}/compare`} title="Compare" onClick={onAddToCompareClicked}>
                                <i className="fa fa-refresh" aria-hidden="true"></i></Link>
                        </div>
                        {product.variants?
                        <ul className="product-thumb-list">
                            { product.variants.map((vari, i) =>
                                <li className={`grid_thumb_img ${(vari.images === this.state.image)?'active':''}`} key={i}>
                                    <a href="javascript:void(0)" title="Add to Wishlist">
                                        <img src={`${vari.images}`} onClick={() => this.onClickHandle(vari.images)} />
                                    </a>
                                </li>)}
                        </ul>:''}

                            </div>*/}
                    {/*<div className="product-detail">
                        <div>
                            <div className="rating">
                                {RatingStars}
                            </div>
                            <Link to={`${process.env.PUBLIC_URL}/left-sidebar/product/${product.id}`}>
                                <h6>{product.name}</h6>
                            </Link>
                            <h4>{symbol}{product.price-(product.price*product.discount/100)}
                                <del><span className="money">{symbol}{product.price}</span></del>
                            </h4>
                            {product.variants?
                            <ul className="color-variant">
                                {product.variants.map((vari, i) => {
                                    return (
                                        <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}></li>)
                                })}
                            </ul>:''}
                        </div>
                    </div>*/}
                    <Modal open={this.state.open} onClose={this.onCloseModal} center>
                        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div className="modal-content quick-view-modal modal-product">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-lg-6  col-xs-12">
                                            <div className="quick-view-img">
                                                {/*<img src={`${
                                                    product.variants?
                                                        this.state.image?this.state.image:product.variants[0].images
                                                        :product.pictures[0]
                                                    }`} alt="" className="img-fluid" />*/}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 rtl-text">
                                            <div className="product-right">
                                                <h2> {product.name} </h2>
                                                {/*<h3>{symbol}{product.price}</h3>*/}
                                                {/*{product.variants?
                                                <ul className="color-variant">
                                                    {product.variants.map((vari, i) =>
                                                        <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}></li>)
                                                    }
                                                </ul>:''}*/}
                                                {/*<div className="border-product">
                                                    <h6 className="product-title">product details</h6>
                                                    <p>{product.shortDetails}</p>
                                                </div>*/}
                                                <div className="product-description border-product">
                                                    {/*{product.size?
                                                    <div className="size-box">
                                                        <ul>
                                                            {product.size.map((size, i) => {
                                                                return <li key={i}><a href="#">{size}</a></li>
                                                            })}
                                                        </ul>
                                                    </div>:''}*/}
                                                    <div className="details-prop">
                                                        <div className="props">
                                                            <div className="product-info">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/aroma.png`} className="img-fluid" alt="" />
                                                                <label >AROMA</label>
                                                            </div>
                                                            <div>
                                                                Herbal con notas dulces.
                                                            </div>
                                                        </div>
                                                        <div className="props">
                                                            <div className="product-info">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/brain.png`} className="img-fluid" alt="" />
                                                                <label >PUESTA</label>
                                                            </div>
                                                            <div>
                                                                Reír con gusto, recordar los buenos momentos y ser creativo.
                                                            </div>
                                                        </div>
                                                        <div className="props">
                                                            <div className="product-info">
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/light.png`} className="img-fluid" alt="" />
                                                                <label >DATO</label>
                                                            </div>
                                                            <div>
                                                                Deliciosos tricomas pegajosos.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h6 className="product-title">CANTIDAD</h6>
                                                    <div className="qty-box">
                                                        <div className="input-group">
                                                              <span className="input-group-prepend">
                                                                <button type="button" className="btn quantity-left-minus modal-btn-number" onClick={this.minusQty} data-type="minus" data-field="">
                                                                 <i className="fa fa-angle-left"></i>
                                                                </button>
                                                              </span>
                                                            <input type="text" name="quantity" value={this.state.quantity}  onChange={this.changeQty} className="form-control input-number" />
                                                                <span className="input-group-prepend">
                                                                <button type="button" className="btn quantity-right-plus modal-btn-number" onClick={this.plusQty} data-type="plus" data-field="">
                                                                <i className="fa fa-angle-right"></i>
                                                                </button>
                                                               </span>
                                                        </div>
                                                    </div>
                                                    <div className="detail-total">
                                                        <label >TOTAL</label>
                                                        <h3>{symbol}{product.price} </h3>
                                                    </div>
                                                </div>
                                                <div className="product-buttons">
                                                    {/*<button  className="btn btn-solid btn-purchase" onClick={() => onAddToCartClicked(product, this.state.quantity)} >AÑADIR AL CARRITO</button>*/}
                                                    <Link to={`#`} className="btn btn-solid btn-purchase bnt-buy" onClick={() => BuynowClicked(product, this.state.quantity)} >Comprar</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
        )
    }
}

export default AccesoriesList;