import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import PurchaseReferedHeader from '../components/common/headers/common/purchase-refered-header'
import {removeFromPurchaseRefered} from '../actions'
import {getCartTotal} from '../services'
import store from '../store';
import AuthService from "../components/services/auth.service";
import ApiService from "../components/services/api.service";

const removeFromPurchaseReferedItem = item=>{
    //console.log('removeFromPurchaseReferedItem item',item);
    //store.dispatch(removeFromPurchaseRefered(item))

    let body = {
        "idPurchase": item.id,
        "id_user": item.id_user,
    }
    var user = AuthService.getCurrentUser();
    if(item.id > 0){
        ApiService.postCancelPurchase(user.token, body).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('removeFromPurchaseReferedItem store', res);
                    store.dispatch(removeFromPurchaseRefered(item))
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }
    
        
}

const PurchaseReferedContainer = ({purchaseReferedList, symbol, removeFromPurchaseRefered, logo}) => (
     <li  className="onhover-div "><div className="cart-qty-cls cart-refer-qty">{purchaseReferedList.length}</div>
            <div><Link to={`${process.env.PUBLIC_URL}/referred-orders`} >
                {logo=='ver_logo.png'?
                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_referred_1.png`} className="img-fluid" alt=""/>
                    :<img src={`${process.env.PUBLIC_URL}/assets/images/icon/ver_referred_2.png`} className="img-fluid" alt=""/>
                }
            {/*<i className="fa fa-shopping-cart"></i>*/}</Link></div>
        <ul className="show-div shopping-cart shopping-cart-ref" id="shopping-cart-ref">
            { purchaseReferedList.map((item,index) => (
                <PurchaseReferedHeader key={index} item={item} symbol={<img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{width: 'auto',height: 'auto', bottom: '2px'}}/>} removeFromPurchaseRefered={() => removeFromPurchaseReferedItem(item)}  />
            ))}
            {(purchaseReferedList.length > 0) ?
                <div>
            
            <li>
                <div className="buttons">
                    <Link to={`${process.env.PUBLIC_URL}/referred-orders`} className="view-cart">Ver compras de usuarios</Link>
                    
                </div>
            </li></div>
                    :
            <li><h5>No hay compras de usuarios actualmente.</h5></li>}
        </ul>

    </li>
)


function mapStateToProps(state) {
    //console.log('mapStateToProps PurchaseReferedContainer', state);
    return {
        purchaseReferedList: state.purchaseReferedList.purchaseRefered,
        total: 0,//getCartTotal(state.purchaseReferedList.purchaseRefered),
        symbol: <img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{width: 'auto',height: 'auto', bottom: '2px'}}/>,//'S/ ',
    }
}

export default connect(mapStateToProps, {removeFromPurchaseRefered})(PurchaseReferedContainer);
//export default PurchaseReferedContainer;
