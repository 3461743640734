import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import {getCartTotal} from '../../../../services'

const PurchaseReferedHeader  = ({item, symbol, removeFromPurchaseRefered}) => (
            <li >
                <div className="media">
                    {/*//console.log('item' ,item)*/}
                    {/*<Link to={`${process.env.PUBLIC_URL}/product/${item.id}`}><img alt="" className="mr-3" src={`${item.pictures[0]}`} /></Link>*/}
                    <div className="media-body">
                        <Link to={`#`}><h4>{item.User.name} <span>{symbol} {(parseFloat(item.total)).toFixed(0)}</span></h4></Link>
                        {/*<h4><span>{item.qty} x {symbol} {(item.price*item.discount/100)}</span></h4>*/}
                    </div>
                </div>
                {/*<span>{cart}</span>*/}
                <div className="close-circle">
                    <a href={null} onClick={removeFromPurchaseRefered}><i className="fa fa-times" aria-hidden="true"></i></a>
                </div>
            </li>
        )

export default PurchaseReferedHeader;
