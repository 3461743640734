import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'


import Breadcrumb2 from "../common/breadcrumb2";
import {getCartTotal} from "../../services";
import {removeFromCart, incrementQty, decrementQty, getFromPurchaseRefered, getFromCartUser} from '../../actions'
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import {removeToCart} from '../../actions'
import store from '../../store';

class cartComponent extends Component {

    constructor (props) {
        super (props);
        this.handlePurchase = this.handlePurchase.bind(this);
        this.state = {
            results: [],
            total: 0,
        };
    }

    componentWillMount() {
        this.getDataCart();
        this.getRefOrders();
    }

    componentDidUpdate(prevProps, prevState){
		/*//console.log('prevProps',prevProps)
        //console.log('prevState',prevState)
        //console.log('this.props',this.props)
        //console.log('this.state',this.state)*/
        if(this.props.cartItems != prevProps.cartItems){
            //console.log('this.removeFromCart')
            
            /*let dataFilter = prevProps.cartItems.filter(data1=>{
                let res = this.props.cartItems.find((data2)=>{
                 return data2.id == data1.id;
                 });
              return res == undefined;
              });

            //console.log('dataFilter', dataFilter)
            if(dataFilter.length>0){
                this.removeFromCartUpdate(dataFilter[0]);
            }*/
            this.getDataCart();
        }
        //
    }


    handlePurchase(e) {
        var user = AuthService.getCurrentUser();
         let data_purchase = {
            'token': user.token_order,
            'id_user': user.id,
        }

        ApiService.postPurchase(user.token,data_purchase).then(
             (res) => {
                 if(res && res.status == 'success'){
                     store.dispatch(removeToCart())

                     user.token_order = res.token_order;
                     localStorage.setItem("user", JSON.stringify(user));
                     this.props.history.push("/thanks");
                 }
             },
             error => {
               const resMessage =
                 (error.response &&
                   error.response.data &&
                   error.response.data.message) ||
                 error.message ||
                 error.toString();
             }
           );
      }

    

    decrementQty = (item) => {

        var user = AuthService.getCurrentUser();
         var cart_quantity = item.quantity - 1;
         if(cart_quantity == 0){
             return true;
         }
         let data_cart = {
            'id_cart': item.id,
            'token': user.token_order,
            'id_user': user.id,
            'id_product': item.id_product,
            'quantity': cart_quantity,
            'price': item.price,
        }

        ApiService.updateCartUser(user.token,data_cart).then(
             (res) => {
                 if(res && res.status == 'success'){
                    //console.log('updateCartUser',data_cart);
                    store.dispatch(decrementQty(data_cart.id_cart))
                    var new_data = this.state.results.map(function(row) {
                        return { 
                          id: row.id, 
                          id_product: row.id_product, 
                          id_user: row.id_user, 
                          price: row.price, 
                          quantity: row.id === item.id && row.quantity > 1? row.quantity - 1 : row.quantity, 
                          token: row.token, 
                          url: row.url,
                          name: row.name,
                        }
                     })
                    
                     this.setState({ results: new_data });
            
                     var sum_total = 0;
                        for (let i = 0; i < new_data.length; i++){
                            sum_total = sum_total + Number(new_data[i]['price'] * new_data[i]['quantity']);
                        }
                     this.setState({ total:sum_total });
                 }
             },
             error => {
               const resMessage =
                 (error.response &&
                   error.response.data &&
                   error.response.data.message) ||
                 error.message ||
                 error.toString();
             }
           );
    }

    incrementQty = (item) => {

         var user = AuthService.getCurrentUser();
         var cart_quantity = item.quantity + 1;
         let data_cart = {
            'id_cart': item.id,
            'token': user.token_order,
            'id_user': user.id,
            'id_product': item.id_product,
            'quantity': cart_quantity,
            'price': item.price,
        }

        ApiService.updateCartUser(user.token,data_cart).then(
             (res) => {
                 if(res && res.status == 'success'){
                    //console.log('updateCartUser',data_cart);
                    store.dispatch(incrementQty(data_cart.id_cart))
                    var new_data = this.state.results.map(function(row) {
                        return { 
                          id: row.id, 
                          id_product: row.id_product, 
                          id_user: row.id_user, 
                          price: row.price, 
                          quantity: row.id === item.id ? row.quantity + 1 : row.quantity, 
                          token: row.token, 
                          url: row.url,
                          name: row.name,
                        }
                     })
                    
                     this.setState({ results: new_data });
            
                     var sum_total = 0;
                        for (let i = 0; i < new_data.length; i++){
                            sum_total = sum_total + Number(new_data[i]['price'] * new_data[i]['quantity']);
                        }
                     this.setState({ total:sum_total });
                 }
             },
             error => {
               const resMessage =
                 (error.response &&
                   error.response.data &&
                   error.response.data.message) ||
                 error.message ||
                 error.toString();
             }
           );
    }

    removeFromCartUpdate = (item) => {
        var user = AuthService.getCurrentUser();
        ApiService.deleteCartUser(user.token,item.id).then(
            (res) => {
                if(res && res.status == 'success'){
                    this.getDataCart();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        
    }

    removeFromCart = (item) => {
        var user = AuthService.getCurrentUser();
        ApiService.deleteCartUser(user.token,item.id).then(
            (res) => {
                if(res && res.status == 'success'){
                    store.dispatch(removeFromCart(item))
                    this.getDataCart();
                }
                /*const items = this.showStatus(res.data).map(function(row) {
                    return { Imagen: row.background_url, id: row.id, Nombre : row.name, Color : row.color, Estado : row.estado, status : row.status }
                 })*/
                /*const items = res.data.map(function(row) {
                    return { id: row.id, Nombre : row.name, Color : row.color, Estado : row.status }
                 })*/
                //this.setState({ results_categ:res });
                //this.renderResults();
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        
    }
    

    getDataCart(){
        var user = AuthService.getCurrentUser();
        ApiService.getCartUser(user.token,user.id).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('data',res.data);
                    const items = res.data.map(function(row) {
                        return { 
                            id: row.id, 
                            id_product: row.id_product, 
                            id_user: row.id_user, 
                            price: row.price, 
                            quantity: row.quantity, 
                            token: row.token, 
                            url: row.Product.url,
                            name: row.Product.name,
                        }
                     })
                    this.setState({ results:items });
                    var sum_total = 0;
                    for (let i = 0; i < this.state.results.length; i++){
                        sum_total = sum_total + Number(this.state.results[i]['price'] * this.state.results[i]['quantity']);
                    }
                    this.setState({ total:sum_total });
                }
                /*const items = this.showStatus(res.data).map(function(row) {
                    return { Imagen: row.background_url, id: row.id, Nombre : row.name, Color : row.color, Estado : row.estado, status : row.status }
                 })*/
                /*const items = res.data.map(function(row) {
                    return { id: row.id, Nombre : row.name, Color : row.color, Estado : row.status }
                 })*/
                //this.setState({ results_categ:res });
                //this.renderResults();
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    getRefOrders(){
        var user = AuthService.getCurrentUser();
        ApiService.getReferredOrders(user.token,user.token_order,user.id).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('getRefOrders cart data',res);

                    store.dispatch(getFromPurchaseRefered(res.data))
                    store.dispatch(getFromCartUser(res.carts_data))

                    this.setState({ order_results:res.data });
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    render (){

        const {cartItems, symbol, total} = this.props;
        document.body.classList.add('dark');

        return (
            <div className="cart-container" style={{backgroundColor: '#161616 !important'}}>
                {/*SEO Support*/}
                <Helmet>
                    <title>MultiKart | Cart List Page</title>
                    <meta name="description" content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses." />
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb2 title={'Cart Page'}/>

                {this.state.results.length>0 ?
                <section className="cart-section section-b-space section-details">
                    <div className="container">
                        <div className="row">
                            <label className="title-products">PRODUCTOS</label>
                            <div className="col-sm-12 cart-container">
                                <table className="table cart-table table-responsive-xs table-items">
                                    <thead>
                                    {/*<tr className="table-head">
                                        <th scope="col">image</th>
                                        <th scope="col">product name</th>
                                        <th scope="col">price</th>
                                        <th scope="col">quantity</th>
                                        <th scope="col">action</th>
                                        <th scope="col">total</th>
                                    </tr>*/}
                                    </thead>
                                    {/*console.log(this.state.results)*/}
                                    {this.state.results.map((item, index) => {
                                        return (
                                        <tbody key={index}>
                                            <tr>
                                                <td>
                                                    <Link to={'#'}>
                                                        <img src={`${process.env.REACT_APP_URL_FILES}images/products/` + item.url} />
                                                    </Link>
                                                </td>
                                                <td>{item.name}
                                                    <div className="mobile-cart-content row">
                                                        <div className="col-xs-3">
                                                            <h2 className="td-color">{symbol}{item.price}</h2>
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <h2 className="td-color">
                                                                <a href="#" className="icon" onClick={() => this.removeFromCart(item)}>
                                                                    <i className="icon-close"></i>
                                                                </a>
                                                            </h2>
                                                        </div>
                                                        <div className="col-xs-3 col-quantity">
                                                                {/*<div className="input-group">
                                                                    <input type="text" name="quantity"
                                                                           className="form-control input-number" defaultValue={item.qty} />
                                                                </div>*/}
                                                                <div className="qty-box box-quantity">
                                                                    <div className="input-group">
                                                                        <span className="input-group-prepend">
                                                                            <button type="button" className="btn quantity-left-minus" onClick={() => this.decrementQty(item)} data-type="minus" data-field="">
                                                                            <i className="fa fa-angle-left"></i>
                                                                            </button>
                                                                        </span>
                                                                        <input type="text" name="quantity" value={item.quantity} readOnly={true} className="form-control input-number" />

                                                                        <span className="input-group-prepend">
                                                                        <button className="btn quantity-right-plus" onClick={() => this.incrementQty(item)}  data-type="plus" disabled={(item.quantity >= item.stock)? true : false}>
                                                                        <i className="fa fa-angle-right"></i>
                                                                        </button>
                                                                        </span>
                                                                    </div>
                                                                </div>{(item.qty >= item.stock)? 'out of Stock' : ''}
                                                        </div>
                                                        <div className="col-xs-3">
                                                            <h2 className="td-color">TOTAL: {symbol}{(item.quantity * item.price).toFixed(2)}</h2>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><h2>{symbol}{item.price}</h2></td>
                                                <td>
                                                    <div className="qty-box">
                                                        <div className="input-group">
                                                            <span className="input-group-prepend">
                                                                {/*<button type="button" className="btn quantity-left-minus" onClick={() => this.props.decrementQty(item.id)} data-type="minus" data-field="">
                                                                 <i className="fa fa-angle-left"></i>
                                                                </button>*/}
                                                                <button type="button" className="btn quantity-left-minus" onClick={() => this.decrementQty(item)} data-type="minus" data-field="">
                                                                 <i className="fa fa-angle-left"></i>
                                                                </button>
                                                            </span>
                                                            <input type="text" name="quantity" value={item.quantity} readOnly={true} className="form-control input-number" />

                                                            <span className="input-group-prepend">
                                                            <button className="btn quantity-right-plus" onClick={() => this.incrementQty(item)}  data-type="plus" disabled={(item.quantity >= item.stock)? true : false}>
                                                            <i className="fa fa-angle-right"></i>
                                                            </button>
                                                           </span>
                                                        </div>
                                                    </div>{(item.qty >= item.stock)? 'out of Stock' : ''}
                                                </td>
                                                <td>
                                                    <a className="icon" onClick={() => this.removeFromCart(item)}>
                                                        <i className="fa fa-times"></i>
                                                    </a>
                                                </td>
                                                <td><h2 className="td-color">{symbol}{(item.quantity * item.price).toFixed(2)}</h2></td>
                                            </tr>
                                        </tbody> )
                                    })}
                                </table>
                                <div className="row-total">
                                    <div className="total-text">
                                        TOTAL
                                    </div>
                                    <div className="total-price">
                                        {symbol} {(this.state.total).toFixed(2)} 
                                    </div>
                                </div>
                                {/*<table className="table cart-table table-responsive-md table-total">
                                    <tfoot>
                                    <tr>
                                        <td className="total-text">TOTAL :</td>
                                        <td className="total-price"><h2>{symbol} {total} </h2></td>
                                    </tr>
                                    </tfoot>
                                </table>*/}
                            </div>
                        </div>
                        <div className="row cart-buttons">
                            {/*<div className="col-6">
                                <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} className="btn btn-solid">continue shopping</Link>
                                </div>*/}
                            <div className="col-12 cart-btn-checkout">
                                <label className="btn btn-solid btn-checkout"
                                onClick={() => this.handlePurchase()}
                                >ORDENAR</label>
                            </div>
                        </div>
                    </div>
                </section>
                :
                <section className="cart-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div >
                                    <div className="col-sm-12 empty-cart-cls text-center">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                                        <h3>
                                            <strong>Tu carrito está vacío</strong>
                                        </h3>
                                        {/*<h4>Explore more shortlist some items.</h4>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: <img src={`${process.env.PUBLIC_URL}/assets/images/icon/credit.png`} alt="" className="img-fluid" style={{width: 'auto',height: 'auto', bottom: '2px'}}/>,//state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    {removeToCart,removeFromCart, incrementQty, decrementQty}
)(cartComponent)