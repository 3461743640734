import React, { Component } from 'react';
import { Link, NavLink} from 'react-router-dom';

// Import custom components
import { withRouter } from 'react-router-dom';

class PageLoader extends Component {

    constructor(props) {
        super(props);
		this.state = {
		}

    }
    /*=====================
         Pre loader
         ==========================*/
    componentDidMount() {
	}

	render() {
		
		return (
			<div className="page-loader position-fixed w-100 h-100 justify-content-center align-items-center" style={{'zIndex': 999999,'display': 'none', 'backgroundColor': 'rgb(130 130 130 / 53%)'}} id="pageLoader">
                <div className="spinner-border" style={{'color': '#00ff08'}}></div>
            </div>
			)
	}
}

export default withRouter(PageLoader)